<!-- Container-fluid starts-->
<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>

  <div class="card">
    <div class="card-header">
      <h5>Add Shop Details</h5>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-xl-4 col-sm-3">
        <form class="needs-validation" [formGroup]="selectShopForm">
          <select
            class="form-select"
            aria-label="Default select example"
            formControlName="shopId"
            (change)="onSelectShop()"
          >
            <option [ngValue]="0">Select Shop</option>
            <option *ngFor="let shop of shopList" [value]="shop.shopId">
              {{ shop.shopName }}
            </option>
          </select>
        </form>
        <div
          *ngIf="shopId && !shopId.value && (shopId.dirty || shopId.touched)"
          class="text text-danger"
        >
          Please Select a Shop.
        </div>
      </div>
    </div>
    <div class="card-body vendor-table text-center">
      <div id="batchDelete" class="custom-datatable vendor-list">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Days</th>
                <th scope="col">Opening Time</th>
                <th scope="col">Closing Time</th>
                <th scope="col">If Close</th>
                <th scope="col" *ngIf="allDaysOriginal?.length">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let day of allDays; let i = index">
                <td scope="row">{{ day?.day }}</td>
                <td scope="row">
                  <input
                    *ngIf="day.isEditable"
                    [(ngModel)]="day.openingTime"
                    class="form-control"
                    [id]="day.day + 'openingTime'"
                    type="time"
                    placeholder="Enter Shop Open Time Here"
                  />
                  <span *ngIf="!day.isEditable">{{
                    day.openingTime || ""
                  }}</span>
                </td>
                <td scope="row">
                  <input
                    *ngIf="day.isEditable"
                    class="form-control"
                    [(ngModel)]="day.closingTime"
                    [id]="day.day + 'closingTime'"
                    type="time"
                    placeholder="Enter closing Time Here"
                  />
                  <span *ngIf="!day.isEditable">{{
                    day.closingTime || ""
                  }}</span>
                </td>
                <td scope="row">
                  <label
                    *ngIf="day.isEditable"
                    class="switch"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="{{ !day?.isClosed ? 'Open' : 'Closed' }}"
                  >
                    <input
                      type="checkbox"
                      [id]="day.day + 'isClosed'"
                      [(ngModel)]="day.isClosed"
                    />
                    <span class="slider round"></span>
                  </label>
                  <span *ngIf="!day.isEditable">{{
                    !day?.isClosed ? "Open" : "Closed"
                  }}</span>
                </td>
                <td *ngIf="allDaysOriginal?.length">
                  <a
                    *ngIf="!day.isEditable"
                    class="hand"
                    (click)="day.isEditable = true"
                  >
                    <i class="fa fa-edit f-12"></i>
                  </a>
                  <span *ngIf="day.isEditable">
                    <button
                      type="button"
                      class="btn btn-danger me-2"
                      (click)="day.isEditable = false"
                    >
                      Discard
                    </button>
                    <!-- <button
                      type="button"
                      class="btn btn-success"
                      [disabled]="!day.isClosed"
                    >
                      Save
                    </button> -->
                  </span>
                </td>
              </tr>
            </tbody>
            <!-- <tbody>
              <tr>
                <td scope="row">MonDay</td>
                <td scope="row">
                  <input
                    class="form-control"
                    id="validationCustom02"
                    type="time"
                    placeholder="Enter Shop Open Time Here"
                  />
                </td>
                <td scope="row">
                  <input
                    class="form-control"
                    id="validationCustom02"
                    type="time"
                    placeholder="Enter closing Time Here"
                    formControlName="closingTime"
                  />
                </td>
                <td scope="row">ThursDay</td>
              </tr>
              <tr>
                <td scope="row">TuesDay</td>
                <td scope="row">WednesDay</td>
                <td scope="row">ThursDay</td>
                <td scope="row">Friday</td>
              </tr>
              <tr>
                <td scope="row">WednesDay</td>
                <td scope="row">ThursDay</td>
                <td scope="row">Friday</td>
                <td scope="row">SatureDay</td>
              </tr>
              <tr>
                <td scope="row">ThursDay</td>
                <td scope="row">Friday</td>
                <td scope="row">SatureDay</td>
                <td scope="row">Sunday</td>
              </tr>
              <tr>
                <td scope="row">Friday</td>
                <td scope="row">SatureDay</td>
                <td scope="row">Sunday</td>
                <td>hi</td>
              </tr>
              <tr>
                <td scope="row">SatureDay</td>
                <td scope="row">Sunday</td>
                <td>hi</td>
                <td>hi</td>
              </tr>
              <tr>
                <td scope="row">Sunday</td>
                <td>hi</td>
                <td>hi</td>
                <td>hi</td>
              </tr>
            </tbody> -->
          </table>
          <!-- <div class="d-flex justify-content-center p-2">
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="shopListLength"
              (pageChange)="getAllShops()"
            >
            </ngb-pagination>
          </div> -->
        </div>
        <div class="form-group row mb-0">
          <div class="col-sm-8 offset-xl-3 offset-sm-4">
            <div class="product-buttons">
              <button
                type="submit"
                value="submit"
                class="btn btn-primary me-1"
                (click)="onSubmit()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
