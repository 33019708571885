import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { ProductService } from "src/app/_services/product/product.service";

@Component({
  selector: "app-category-img-upload",
  templateUrl: "./category-img-upload.component.html",
  styleUrls: ["./category-img-upload.component.scss"],
})
export class CategoryImgUploadComponent implements OnInit {
  uploadOfferFrom: any;
  categories: any;
  pictureUrl: any;
  pictureSrc: any;
  public closeResult: string;
  isupload: boolean = false;
  offerSections: any;
  selectedOfferId: any;
  offerCategories: any;
  categorySection: any;
  selectedSectionId: any;
  constructor(
    private productService: ProductService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toastr: ToastrService,
    private categoryService: CategoryServiceService
  ) {
    this.uploadOfferFrom = this.formBuilder.group({
      catId: ["", [Validators.required]],
      offerId: ["", [Validators.required]],
    });
    this.uploadOfferFrom.controls["catId"].setValue(0);
    this.uploadOfferFrom.controls["offerId"].setValue(0);
  }

  ngOnInit(): void {
    this.getAllSectionData();
    this.getAllCategories();
    // this.getAllOfferSections();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  getAllCategories() {
    this.categoryService.getAllCategory().subscribe((res: any) => {
      console.log("res", res);
      this.categories = res?.categoryList;
    });
  }

  // getAllOfferSections() {
  //   this.productService.fetchAllOfferSections().subscribe((res: any) => {
  //     console.log("offer", res);
  //     this.offerSections = res;
  //   });
  // }

  getAllSectionData() {
    this.productService.fetchSectionsData().subscribe((res: any) => {
      console.log("section", res.homePageResponseList);
      // this.offerSections = res;
      this.categorySection = res?.homePageResponseList?.filter((obj: any) => {
        return obj.isCategory == true;
      });
    });
  }

  onSelectOffer() {
    this.selectedSectionId = this.uploadOfferFrom.value["offerId"];
    this.productService
      .fetchCategoryByOfferId(this.selectedSectionId)
      .subscribe((res: any) => {
        console.log("offerCat", res);
        this.offerCategories = res;
      });
  }

  onPictureUpload(picture: any) {
    console.log("picture", picture.target.files);

    if (picture.target.files && picture.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pictureUrl = e.target.result;
      };
      reader.readAsDataURL(picture.target.files[0]);
      this.pictureSrc = picture.target.files;
    }
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onSave() {
    if (
      this.uploadOfferFrom.invalid ||
      this.uploadOfferFrom.value["catId"] == 0 ||
      this.uploadOfferFrom.value["index"] == 0
    ) {
      this.toastr.error("Please fill up the fields");
      // this.formSubmitted = false;
      return;
    }
    this.isupload = true;
    this.showSpinner();
    let data: any = this.uploadOfferFrom.value;
    const save_inputs = new FormData();
    save_inputs.append("catId", this.uploadOfferFrom.value["catId"]);
    save_inputs.append("offerId", this.uploadOfferFrom.value["offerId"]);
    save_inputs.append("optimiseFlag", "");
    save_inputs.append("productId", "");
    // save_inputs.append("optimiseFlag", this.checkedAgree.toString());
    // save_inputs.append("file", this.downLoadfile);
    console.log("save_inputs", save_inputs);

    // Object.keys(this.downLoadfile).forEach((key) => {
    //   save_inputs.append("file", this.downLoadfile[key]);
    // });

    Object.keys(this.pictureSrc).forEach((key) => {
      save_inputs.append("file", this.pictureSrc[key]);
    });
    this.productService
      .uploadCategoryImage(save_inputs)
      .subscribe((res: any) => {
        console.log("res", res);
        this.isupload = false;
        if (res?.status == 0) {
          this.showSpinner();
          this.toastr.success("Offer Image Uploaded Successfully");

          this.router.navigate(["/home-page/section-list"]);
        } else {
          this.toastr.error("Something Went Wrong ! Try Again.");
        }
      });
  }
}
