import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { VendorsRoutingModule } from "./vendors-routing.module";
import { ListVendorsComponent } from "./list-vendors/list-vendors.component";
import { CreateVendorsComponent } from "./create-vendors/create-vendors.component";

import { ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ShopListComponent } from "./shop-list/shop-list.component";
import { SharedModule } from "src/app/shared/shared.module";
// import { Ng2SmartTableModule } from 'ng2-smart-table';

@NgModule({
  declarations: [
    ListVendorsComponent,
    CreateVendorsComponent,
    ShopListComponent,
  ],
  imports: [
    CommonModule,
    VendorsRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    // Ng2SmartTableModule
  ],
})
export class VendorsModule {}
