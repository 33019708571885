import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HomePageRoutingModule } from "./home-page-routing.module";
import { AddSectionsComponent } from "./add-sections/add-sections.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ToastrModule } from "ngx-toastr";
import { OfferImageUploadComponent } from "./offer-image-upload/offer-image-upload.component";
import { SectionListComponent } from "./section-list/section-list.component";
import { CategoryImgUploadComponent } from "./category-img-upload/category-img-upload.component";
import { EditSectionComponent } from "./edit-section/edit-section.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSortModule } from "@angular/material/sort";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ImageCropperModule } from "ngx-image-cropper";
import { ShopOfferUploadComponent } from './shop-offer-upload/shop-offer-upload.component';
@NgModule({
  declarations: [
    AddSectionsComponent,
    OfferImageUploadComponent,
    SectionListComponent,
    CategoryImgUploadComponent,
    EditSectionComponent,
    ShopOfferUploadComponent,
  ],
  imports: [
    CommonModule,
    HomePageRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    MatChipsModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    DragDropModule,
    ImageCropperModule,
    MatSortModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
  ],
})
export class HomePageModule {}
