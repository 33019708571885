<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Stripe Report</h5>
        </div>
        <div class="card-body">
          <!-- <p *ngIf="!orderList?.length">No order has been made yet.</p> -->
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive">
              <table class="table table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">StripeId</th>
                    <th scope="col">Status</th>
                    <!-- <th scope="col">AddressLine1</th>
                    <th scope="col">AddressLine2</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                    <th scope="col">Country</th>
                    <th scope="col">ZipCode</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">StorePickupTime</th>
                    <th scope="col">Note</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>12334</td>
                    <td>Done</td>
                    <!-- <td>{{ orders?.addressLine1 }}</td> -->
                    <!-- <td>
                      {{ orders?.addressLine2 ? orders?.addressLine2 : "N/A" }}
                    </td>
                    <td>{{ orders?.city }}</td>
                    <td>{{ orders?.state }}</td>
                    <td>{{ orders?.country }}</td>
                    <td>{{ orders?.zipcode }}</td>
                    <td>
                      {{ orders?.createdDate.split("[")[0] | date : "medium" }}
                    </td>
                    <td>{{ orders?.storePickupTime | date : "medium" }}</td>
                    <td>{{ orders?.note ? orders?.note : "N/A" }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
