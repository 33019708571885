import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Observable, catchError, tap, throwError } from "rxjs";
import { AppService } from "src/app/_services/appService/app.service";
import { ProductService } from "src/app/_services/product/product.service";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import Swal from "sweetalert2";
import { HttpClient } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-stripe-onboard",
  templateUrl: "./stripe-onboard.component.html",
  styleUrls: ["./stripe-onboard.component.scss"],
})
export class StripeOnboardComponent implements OnInit {
  orderList: any;

  addnewClicked: boolean = false;
  stripeOnboardForm: FormGroup;
  checkedAgree: boolean = false;
  userInfo: any;
  newUser: any;
  signedInUser: any;
  userId: any;
  ipAddress = "";
  // @ViewChild("onboard") onboard!: ElementRef;
  URl: any;
  accDone: boolean = false;
  routingUrl: any;
  accNum: string;
  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private sessionManager: SessionManagerService,
    private toastr: ToastrService,
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) {
    this.stripeOnboardForm = this.formBuilder.group({
      accountNumber: ["", [Validators.required]],
      email: ["", [Validators.required]],
      routingNumber: ["", [Validators.required]],
      // country: ["", [Validators.required]],
      // currency: ["", [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    // console.log("newUser", this.newUser);
    this.signedInUser = this.newUser.groups[0];
    this.userId = this.newUser.userId;
    console.log("signedInUser", this.signedInUser);
    console.log("userId", this.userId);
    this.showSpinner();
    this.stripeStatus();
    this.getIPAddress();
    this.http.get<any>("https://geolocation-db.com/json/").pipe(
      catchError((err) => {
        return throwError(err);
      }),
      tap((response) => {
        console.log(response.IPv4);
      })
    );
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  onChecked() {
    this.checkedAgree = !this.checkedAgree;
    console.log("checkedAgree", this.checkedAgree);
  }

  getIPAddress() {
    this.http
      .get("https://api.ipify.org/?format=json")
      .subscribe((res: any) => {
        this.ipAddress = res.ip;
        console.log("ip", this.ipAddress);
      });
  }

  onBoard() {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          accountNumber: this.stripeOnboardForm.value["accountNumber"],
          email: this.stripeOnboardForm.value["email"],
          routingNumber: this.stripeOnboardForm.value["routingNumber"],
          country: "US",
          currency: "USD",
          sellerId: this.userId,
          ipAddress: this.ipAddress,
        };
        console.log("fd", fd);
        this.showSpinner();
        this.appService.onboardExpressAccount(fd).subscribe(
          (response: any) => {
            console.log("onboard", response);
            if (response?.statusResponse?.status == "0") {
              this.URl = response.url;
              Swal.fire("Stripe Onboarded Succesfully!", "", "success");
              this.onCancel();
              this.checkedAgree = false;
              this.toastr.success("Stripe Onboarded Succesfully");
              window.open(this.URl, "_black");
            } else {
              this.URl = response.url;
              window.open(this.URl, "_black");
              this.toastr.error(response?.statusResponse?.statusDesc);
            }
          },
          (error) => {
            console.log("error", error);
            this.toastr.error(
              "Unsuccessful in boarding Seller,Please Try Again"
            );
          }
        );
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onCancel() {
    this.stripeOnboardForm.reset();
    // this.onboard.nativeElement.click();
    this.checkedAgree = false;
  }

  stripeStatus() {
    let fd = {
      sellerId: this.userId,
    };
    this.appService.retrieveStripeAccount(fd).subscribe((response: any) => {
      console.log("res", response);
      if (response.accountActivated == "true") {
        this.accDone = true;
        this.accNum = "xxxxxxx" + response.last4;
        this.toastr.info("You have already onboarded to stripe ! Thank You");
      } else if (response.accountActivated == "false") {
        this.accDone = false;
        this.routingUrl = response.url;
        this.stripeOnboardForm.patchValue({
          accountNumber: "xxxxxxx" + response.last4,
          email: response?.email,
          routingNumber: response?.routingNumber,
        });
      } else {
        this.accDone = false;
        this.routingUrl = "";
        this.toastr.info("Please Onboard To stripe");
      }
    });
  }
  onContinue(routingUrl: any) {
    console.log("routingUrl", routingUrl);
    window.open(routingUrl, "_black");
  }
  get accountNumber() {
    return this.stripeOnboardForm.get("accountNumber");
  }
  get email() {
    return this.stripeOnboardForm.get("email");
  }
  get routingNumber() {
    return this.stripeOnboardForm.get("routingNumber");
  }
}
