import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry, throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppService {
  api_url = environment.apiUrl;
  constructor(private http: HttpClient) {}

  //   GET
  // /v1/userList
  getAllUserList() {
    return this.http
      .get(this.api_url + "/v1/userList")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/getAllSellers

  getAllSellers() {
    return this.http
      .get(this.api_url + "/v1/getAllSellers")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/getSellerDashboardDetails/{sellerId}

  getSellerDashboardDetailsBySellerId(sellerId: any) {
    return this.http.get(
      this.api_url + "/v1/getSellerDashboardDetails/" + sellerId
    );
  }
  // POST
  // /v1/createCrm

  createCrm(crmDetails: any) {
    return this.http
      .post(`${this.api_url}/v1/createCrm`, crmDetails)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/sendRepeatEmail

  sendRepeatEmail(sellerDetails: any) {
    return this.http
      .post(`${this.api_url}/v1/sendRepeatEmail`, sellerDetails)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/getAllCrm

  getAllCrm() {
    return this.http
      .get(this.api_url + "/v1/getAllCrm")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/onboardExpressAccount/{sellerId}

  onboardExpressAccount(data: any) {
    // let sellerId = 2;
    return this.http
      .post(`${this.api_url}/v1/onboardExpressAccount`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/retrieveStripeAccount
  retrieveStripeAccount(data: any) {
    return this.http
      .post(`${this.api_url}/v1/retrieveStripeAccount`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // PUT
  // /v1/softDeleteSeller
  // /v1/softDeleteSeller?sellerId=555
  getSoftDeleteSeller(sellerId: any) {
    let data = {};
    return this.http
      .put(`${this.api_url}/v1/softDeleteSeller?sellerId=${sellerId}`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/getAllTransactionBySellerId/{sellerId}

  getAllTransactionBySellerId(sellerId: any) {
    return this.http.get(
      this.api_url + "/v1/getAllTransactionBySellerId/" + sellerId
    );
  }
  // GET
  // /v1/getSellerWalletBalance
  getSellerWalletBalance() {
    return this.http.get(this.api_url + "/v1/getSellerWalletBalance");
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
