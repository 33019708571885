import { DecimalPipe } from "@angular/common";
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Observable } from "rxjs";
import { CATEGORY, Category } from "src/app/shared/tables/category";
import { TableService } from "../../../shared/service/table.service";
import { SortEvent } from "../../../shared/directives/shorting.directive";
import { NgbdSortableHeader } from "../../../shared/directives/NgbdSortableHeader";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { SubCategoryList } from "src/app/_models/subCategory/subCategory";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-sub-category",
  templateUrl: "./sub-category.component.html",
  styleUrls: ["./sub-category.component.scss"],
  providers: [TableService, DecimalPipe],
})
export class SubCategoryComponent implements OnInit {
  createSubCategoryForm: FormGroup;
  public closeResult: string;
  searchText;
  categories: any;
  tableItem$: Observable<Category[]>;
  total$: Observable<number>;
  sub_category: any[] = [];
  selectedsub_category: string;
  addOnBlur = true;
  categoryId: string;
  is_checked: any;
  page: number = 1;
  subCategoriesLength: any;
  pageSize: number = 25;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild("addSubCategory") addSubCategory!: ElementRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  subCategories: any;
  subCatagoriesList: any = [];
  changedValue: any = "";
  subCategoryId: any;

  constructor(
    public service: TableService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private categoryService: CategoryServiceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(CATEGORY);
    this.createSubCategoryForm = this.formBuilder.group({
      categoryId: [""],
    });
    this.createSubCategoryForm.controls["categoryId"].setValue(0);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.getAllCategories();
    this.showSpinner();
    // this.getAllSubCategories();
    this.allSubCategories();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  //Adding Value in MatChip

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    let payload = { name: value, status: "active" };
    // Add our cat
    if (value) {
      this.sub_category.push(payload);
    }

    // Clear the input value
    event.chipInput!.clear();
    console.log(this.sub_category);
  }

  //Removing Value From MatChip

  remove(sub_cat: any): void {
    const index = this.sub_category.indexOf(sub_cat);

    if (index >= 0) {
      this.sub_category.splice(index, 1);
    }
    console.log(this.sub_category);
  }

  getAllCategories() {
    this.categoryService.getAllCategory().subscribe((res: any) => {
      this.categories = res.categoryList;
      console.log("this.categories", this.categories);
    });
  }
  onSelectCategory() {
    this.categoryId = this.createSubCategoryForm.value["categoryId"].toString();
    console.log("categoryId", this.categoryId);
  }
  allSubCategories() {
    this.categoryService.getAllSubCategory().subscribe((res: any) => {
      this.subCategoriesLength = res.length;
      this.subCategories = res
        .map((result: any, index: any) => {
          return {
            ...result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
      console.log("sub", res);
    });
  }

  // getAllSubCategories() {
  //   this.categoryService.getAllSubCategory().subscribe((res: any) => {
  //     this.subCategories = res;
  //     console.log("res", res);

  //     res.forEach((r: any, index) => {
  //       r.subcategoryList.map((re) => this.subCatagoriesList.push(re));
  //       console.log("aaa", this.subCatagoriesList);
  //       if (res.length - 1 === index) {
  //         console.log("a>>");
  //         this.subCategoriesLength = this.subCatagoriesList?.length;

  //         // this.extractSubCatagories();
  //       }
  //     });
  //   });
  // }
  // extractSubCatagories() {
  //   this.subCatagoriesList
  //     ?.map((result: any, index: any) => {
  //       return {
  //         ...result,
  //         sl_no: index + 1,
  //       };
  //     })
  //     .slice(
  //       (this.page - 1) * this.pageSize,
  //       (this.page - 1) * this.pageSize + this.pageSize
  //     );
  // }
  onSubCategoriesSave() {
    if (!this.subCategoryId) {
      let SubCategoryList: SubCategoryList = {
        categoryId: this.categoryId,
        subCategoryRequest: this.sub_category,
      };
      this.categoryService
        .createSubCategory(SubCategoryList)
        .subscribe((response: any) => {
          console.log("response", response);
          if (response.status == 0) {
            this.showSpinner();
            this.toastr.success("SubCategory Created Successfully");
            this.allSubCategories();
          }
        });
    } else {
      let upateData = {
        name: this.changedValue,
        status: "active",
      };
      this.categoryService
        .updateSubCategory(this.subCategoryId, upateData)
        .subscribe((response: any) => {
          console.log("response", response);
          this.subCategoryId = 0;
          this.changedValue = "";
          this.subCatagoriesList = [];
          this.allSubCategories();
          if (response.status == 0) {
            this.showSpinner();
            this.toastr.success("Sub-Category Updated Successfully");
          }
        });
    }
    return this.onDismiss();
  }
  onSubCatagorieChange(value) {
    this.changedValue = value;
    console.log("value", value);
  }
  onSubCategoryEditClick(subCategory: any) {
    console.log("subEdit", subCategory);
    this.changedValue = subCategory.name;
    this.subCategoryId = subCategory.id;
    this.addSubCategory.nativeElement.click();
  }
  onSubCategoryDeleteClick(subCategory: any) {
    console.log("subDelete", subCategory.id);
    let id = subCategory.id;
    this.categoryService.deleteSubCategory(id).subscribe((res: any) => {
      console.log("res", res);
      if (res.status == 0) {
        this.showSpinner();
        this.allSubCategories();
        this.toastr.success("Sub-Category Delete Successfully");
      }
    });
  }

  onActiveClick(event: any, id: any, active: any) {
    console.log(id);
    this.is_checked = active;
    console.log("is_Active", active);
    console.log("checked", this.is_checked);

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `SAVE`,
      denyButtonText: `CANCEL`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        if (event.target.checked) {
          this.is_checked = 1;
          console.log("is", this.is_checked);
        } else {
          this.is_checked = 0;
          // console.log('ddd', this.is_checked);
        }
        const save_inputs = {
          is_active: this.is_checked,
        };
        console.log(save_inputs);

        if (id !== 0) {
          //add
          // save_inputs.append('id', this.driverId);
          this.categoryService
            .softDeleteSubcategory(id)
            .subscribe((response: any) => {
              console.log("tttt", response);
              Swal.fire("Saved!", "", "success");
              this.showSpinner();
              this.allSubCategories();
            });
        }
      } else if (result.isDenied) {
        this.allSubCategories();
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onDismiss() {
    this.createSubCategoryForm.controls["categoryId"].setValue(0);
    return (this.sub_category = []);
  }
}
