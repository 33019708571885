import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CreateSale } from "src/app/_models/sale/createSale";
import { ProductService } from "src/app/_services/product/product.service";
import Swal from "sweetalert2";
import { AbstractControl, ValidationErrors } from "@angular/forms";

@Component({
  selector: "app-sale",
  templateUrl: "./sale.component.html",
  styleUrls: ["./sale.component.scss"],
})
export class SaleComponent implements OnInit {
  productList: any[] = [];
  componentList: any[] = [];
  createSaleForm: FormGroup;
  selected: any;
  selectedItems = [];
  savedData: any = [];
  loaded = false;
  productid: any;
  formSubmitted: boolean = false;
  isSaleDone: boolean = false;
  dropdownSettings = {
    singleSelection: false,
    idField: "componentId",
    textField: "componentName",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    public activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.createSaleForm = this.formBuilder.group({
      productId: ["", [Validators.required]],
      // componentId: [""],
      stock: ["", [Validators.required]],
      // until: [""],
      price: ["", [Validators.required]],
      discount: [""],
      // offer: [""],
    });
    this.createSaleForm.controls["productId"].setValue(0);
    // this.createSaleForm.controls["componentId"].setValue(0);
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((param: any) => {
      let producId = param.get("id") || 0;
      this.getAllProduct(producId);
      this.onSelectProduct(producId);
    });
    // this.getAllProduct();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  onItemSelect(item: any) {
    this.savedData.push(item.componentId);
    console.log("saved", this.savedData);
  }

  onItemDeSelect(item: any) {
    console.log("deselctitem", item);

    let filteredData = this.savedData.filter(
      (data) => item.componentId != data
    );
    this.savedData = filteredData;
    console.log("deselct", this.savedData);
  }

  getAllProduct(productId) {
    this.productService.getAllProducts().subscribe((response: any) => {
      this.productList = response;
      this.createSaleForm.get("productId").setValue(productId);

      console.log("product_list", this.productList);
    });
  }

  // getAllProduct() {
  //   this.activeRoute.params.subscribe((param) => {
  //     this.loaded = false;
  //     this.productService
  //       .getAllProducts(param, "/v1/getAllProduct", 5)
  //       .subscribe((response: any) => {
  //         this.productList = response;
  //         console.log("productList", this.productList);
  //       });
  //   });
  // }
  onSelectProduct(value) {
    console.log(value);
    this.productid = value;
    if (this.productid > 0) {
      // console.log("pasuchi", this.productid);

      this.productService
        .getComponentByProductId(value)
        .subscribe((response: any) => {
          this.componentList = response;
          console.log("componentList", this.componentList);
        });
    }
  }
  createSale() {
    this.formSubmitted = true;
    if (
      this.createSaleForm.invalid ||
      this.createSaleForm.value["productId"] == 0
    ) {
      this.toastr.error("Please fill up the mandatory fields (marked in *)");
      // this.formSubmitted = false;
      return;
    }
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd: CreateSale = {
          productId: this.productid,
          componentList: this.savedData,
          stock: this.createSaleForm.value["stock"],
          // until: this.createSaleForm.value["until"],
          price: this.createSaleForm.value["price"],
          discount: this.createSaleForm.value["discount"] || 0,
          // offer: this.createSaleForm.value["offer"] || "N/A",
          status: "active",
          top: "false",
          featured: "false",
          new: "false",
          isActive: "true",

          // productId: "string",
          // componentId: "string",
          // stock: "string",
          // top: "string",
          // featured: "string",
          // until: "string",
          // salePrice: "string",
          // price: "string",
          // discount: "string",
          // offer: "string",
          // createdDate: "2022-11-22",
          // updatedDate: "2022-11-22",
          // status: "string",
          // isActive: true,
        };
        console.log("fd", fd);
        this.productService.createSale(fd).subscribe((response: any) => {
          console.log("Sale", response);
          this.showSpinner();
          this.formSubmitted = false;
          Swal.fire("Sale Created Succesfully!", "", "success");
          this.onDiscard();
          this.isSaleDone = true;
          this.router.navigate(["/products/product-list"]);
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onDiscard() {
    this.createSaleForm.reset();
    this.selectedItems = [];
    this.createSaleForm.controls["productId"].setValue(0);
    // this.createSaleForm.controls["componentId"].setValue(0);
  }

  get f() {
    return this.createSaleForm.controls;
  }

  get productId() {
    return this.createSaleForm.get("productId");
  }
  get componentId() {
    return this.createSaleForm.get("componentId");
  }
  get stock() {
    return this.createSaleForm.get("stock");
  }
  // get until() {
  //   return this.createSaleForm.get("until");
  // }
  get sale_price() {
    return this.createSaleForm.get("sale_price");
  }
  get price() {
    return this.createSaleForm.get("price");
  }
  get discount() {
    return this.createSaleForm.get("discount");
  }
  // get offer() {
  //   return this.createSaleForm.get("offer");
  // }
}
