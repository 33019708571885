<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
    <div class="card-header">
      <h5>Section Details</h5>
    </div>
    <div class="card-body">
      <!-- <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Create User</a>
            </div> -->
      <div id="batchDelete" class="category-table custom-datatable user-image">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr class="text-left">
                <!-- <th scope="col">Actions</th> -->
                <th scope="col">#</th>
                <th scope="col">Section Name</th>
                <th scope="col">Section Type</th>
                <!-- <th scope="col" sortable="lName">Last Name</th> -->
                <th scope="col">Index</th>
                <th scope="col">Action</th>

                <!-- <th scope="col">Section Contains</th> -->
                <!-- <th scope="col" sortable="role">Role</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let section of sectionList" class="text-left">
                <!-- <td>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-edit f-12"></i></a
                  >&nbsp;
                  <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                </td> -->
                <td scope="row">
                  <!-- <img
                    [src]="item.avatar"
                    class="imgTable"
                    style="width: 20px"
                  /> -->
                  {{ section?.sl_no }}
                </td>
                <td>
                  {{ section?.sectionName }}
                </td>
                <td *ngIf="section?.isCategory">
                  {{ section?.isCategory ? "Category" : "N/A" }}
                </td>
                <td *ngIf="section?.isOffer">
                  {{ section?.isOffer ? "Offer" : "N/A" }}
                </td>
                <td *ngIf="section?.isProduct">
                  {{ section?.isProduct ? "Product" : "N/A" }}
                </td>
                <td *ngIf="section?.isStoreList">
                  {{ section?.isStoreList ? "Store" : "N/A" }}
                </td>
                <!-- <td>{{ item.lName }}</td> -->
                <td>{{ section?.index }}</td>

                <!-- <td>{{ section?.createdDate.split("[")[0] | date : "medium" }}</td> -->
                <!-- <td>{{ item.role }}</td> -->
                <td>
                  <a
                    href="javascript:void(0)"
                    (click)="onEditClick(section?.sectionId)"
                    ><i class="fa fa-edit f-12"></i></a
                  >&nbsp;
                  <a
                    href="javascript:void(0)"
                    (click)="onDeleteClick(section.sectionId)"
                    ><i class="fa fa-trash-o"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center p-2">
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="sectionListLength"
              (pageChange)="getAllSections()"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
