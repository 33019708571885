import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-delivery-by-admin",
  templateUrl: "./delivery-by-admin.component.html",
  styleUrls: ["./delivery-by-admin.component.scss"],
})
export class DeliveryByAdminComponent implements OnInit {
  addAdminDeliveryForm!: FormGroup;
  feeTypeList = [
    {
      id: 1,
      name: "Flat",
    },
    {
      id: 2,
      name: "Percentage",
    },
  ];
  deliveryInfo: any;
  constructor(
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private categoryService: CategoryServiceService,
    private spinner: NgxSpinnerService
  ) {
    this.addAdminDeliveryForm = this.formBuilder.group({
      deliveryFeeType: ["", [Validators.required]],
      deliveryFeeValue: ["", [Validators.required]],
    });
    this.addAdminDeliveryForm.controls["deliveryFeeType"].setValue(0);
  }

  ngOnInit(): void {
    this.getAdminDeliveryInfo();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  getAdminDeliveryInfo() {
    this.categoryService.getAdminDeliveryInfo().subscribe((res: any) => {
      this.deliveryInfo = res;
      console.log("this.deliveryInfo ", this.deliveryInfo);
    });
  }
  onAddDelivery() {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          deliveryFeeType: this.addAdminDeliveryForm.value["deliveryFeeType"],
          deliveryFeeValue: this.addAdminDeliveryForm.value["deliveryFeeValue"],
        };
        this.categoryService
          .addAdminDeliveryDetails(fd)
          .subscribe((response: any) => {
            console.log("Res", response);
            this.onDiscard();
            this.getAdminDeliveryInfo();
            this.showSpinner();
            Swal.fire("Delivery Created Succesfully!", "", "success");
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onDiscard() {
    this.addAdminDeliveryForm.reset();
    this.addAdminDeliveryForm.controls["deliveryFeeType"].setValue(0);
  }

  get deliveryFeeType() {
    return this.addAdminDeliveryForm.get("deliveryFeeType");
  }

  get deliveryFeeValue() {
    return this.addAdminDeliveryForm.get("deliveryFeeValue");
  }
}
