import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListMenuComponent } from "./list-menu/list-menu.component";
import { CreateMenuComponent } from "./create-menu/create-menu.component";
import { OrderListComponent } from "./order-list/order-list.component";
import { WithdrawalReportComponent } from "./withdrawal-report/withdrawal-report.component";
import { StripeOnboardComponent } from "./stripe-onboard/stripe-onboard.component";
import { OrderDetailsComponent } from "./order-details/order-details.component";
import { AdminOrderDetailsComponent } from "./admin-order-details/admin-order-details.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "list-menu",
        component: ListMenuComponent,
        data: {
          title: "Menu Lists",
          breadcrumb: "Menu Lists",
        },
      },
      {
        path: "create-menu",
        component: CreateMenuComponent,
        data: {
          title: "Create Menu",
          breadcrumb: "Create Menu",
        },
      },
      {
        path: "order-list",
        component: OrderListComponent,
        data: {
          title: "Order List",
          breadcrumb: "Order List",
        },
      },
      {
        path: "withdrawal-report",
        component: WithdrawalReportComponent,
        data: {
          title: "Withdrawal Report",
          breadcrumb: "Withdrawal Report",
        },
      },
      {
        path: "stripe-onboard",
        component: StripeOnboardComponent,
        data: {
          title: "Stripe Onboard",
          breadcrumb: "Stripe Onboard",
        },
      },
      {
        path: "order-detail/:id",
        component: OrderDetailsComponent,
        data: {
          title: "Order Details",
          breadcrumb: "Order Details",
        },
      },
      {
        path: "admin-order-detail/:id",
        component: AdminOrderDetailsComponent,
        data: {
          title: "Admin Order Details",
          breadcrumb: "Admin Order Details",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MenusRoutingModule {}
