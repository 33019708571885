<!-- Container-fluid starts-->
<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <div class="card">
    <div class="card-header">
      <h5>Vendor Details</h5>
    </div>
    <div class="card-body vendor-table text-center">
      <p *ngIf="!vendors?.length">No Vendor has been registered yet.</p>
      <div id="batchDelete" class="custom-datatable vendor-list">
        <div class="table-responsive">
          <table *ngIf="vendors?.length > 0" class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Full Name</th>
                <th scope="col">Email</th>
                <th scope="col">Registered Date</th>
                <th scope="col">Active/Inactive</th>
                <th scope="col">Status</th>
                <!-- <th scope="col"  >Wallet Balance</th>
                                    <th scope="col" >Revenue</th>
                                    <th scope="col" >Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let vendor of vendors">
                <td scope="row">
                  <!-- <img [src]="item.vendor" class="imgTable" style="width: 20px">
                                       <span>{{item.name}}</span>  -->
                  {{ vendor?.sl_no }}
                </td>
                <td>
                  {{ vendor?.fullName }}
                </td>
                <td>{{ vendor?.email }}</td>
                <td>
                  {{ vendor?.createdDate.split("[")[0] | date : "medium" }}
                </td>
                <!-- <td>{{item.balance}}</td>
                                    <td>{{item.revenue}}</td> -->
                <!-- <td>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-edit f-12"></i></a
                  >&nbsp;
                  <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                </td> -->

                <td>
                  <label
                    class="switch"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="{{ vendor?.active == true ? 'Active' : 'Inactive' }}"
                  >
                    <input
                      type="checkbox"
                      [checked]="vendor?.active == true"
                      (click)="onActiveClick($event, vendor.id, vendor.active)"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td>
                  <span
                    [class]="
                      vendor?.active == true
                        ? 'badge bg-success text-dark'
                        : 'badge bg-secondary text-dark'
                    "
                  >
                    {{ vendor?.active == true ? "Active" : "Inactive" }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center p-2">
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="vendorListLength"
              (pageChange)="getAllSellers()"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
