import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProductService } from "src/app/_services/product/product.service";

@Component({
  selector: "app-section-list",
  templateUrl: "./section-list.component.html",
  styleUrls: ["./section-list.component.scss"],
})
export class SectionListComponent implements OnInit {
  sectionListLength: any;
  page: number = 1;
  pageSize: number = 5;
  sectionList: any;
  constructor(
    private productService: ProductService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.showSpinner();
    this.getAllSections();
  }

  getAllSections() {
    this.productService.fetchSectionsData().subscribe((response: any) => {
      console.log("log", response);

      this.sectionListLength = response?.homePageResponseList?.length;
      this.sectionList = response?.homePageResponseList
        .map((result: any, index: any) => {
          return {
            ...result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
      console.log("this.sectionList", this.sectionList);
    });
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  onEditClick(sectionId: any) {
    console.log("hi", sectionId);
    this.router.navigate(["/home-page/section-list/edit-section/" + sectionId]);
    // this.router.navigateByUrl(`/section-list/edit-section/${sectionId}`);
  }

  onDeleteClick(sectionId: any) {
    this.productService.deleteSection(sectionId).subscribe((res: any) => {
      console.log("delete", res);
      if (res.status == 0) {
        this.toastr.success("Section Deleted Successfully");
        this.showSpinner();
        this.getAllSections();
      }
    });
  }
}
