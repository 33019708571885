<!-- Container-fluid starts-->
<div class="container-fluid">
  <!-- <h1 *ngIf="signedInUser == 'Seller'" class="text-center text-success">
    Welcome To TE13
  </h1> -->
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <!-- <div class="row">
    <div class="col-xl-12">
      <div>
        <div class="btn-popup pull-right">
          <button
            class="btn btn-primary"
            type="button"
            #onboard
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Stripe Onboard
          </button>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Stripe Onboard</h4>
        </div>
        <div class="card-body">
          <div *ngIf="accDone">
            <p class="myClass">
              <b>You have a stripe account bearing account {{ accNum }}</b>
            </p>
          </div>
          <div *ngIf="!accDone" class="basic-form">
            <form [formGroup]="stripeOnboardForm" (ngSubmit)="onBoard()">
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-3">
                    <label for="validationCustom01" class="mb-0"
                      >Account No :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-8">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      placeholder="Please Enter Account Number"
                      formControlName="accountNumber"
                      [readOnly]="routingUrl"
                    />
                    <div
                      *ngIf="
                        accountNumber &&
                        accountNumber.invalid &&
                        (accountNumber.dirty || accountNumber.touched)
                      "
                      class="text text-danger"
                    >
                      Account Number is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Routing No :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-8">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      placeholder="Please Enter Routing Number"
                      formControlName="routingNumber"
                      [readOnly]="routingUrl"
                    />
                    <div
                      *ngIf="
                        routingNumber &&
                        routingNumber.invalid &&
                        (routingNumber.dirty || routingNumber.touched)
                      "
                      class="text text-danger"
                    >
                      Routing Number is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-8">
                    <label for="validationCustom01" class="mb-0"
                      >Country :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-8">
                    <input
                      class="form-control"
                      placeholder="US"
                      id="validationCustom01"
                      disabled
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-3">
                    <label for="validationCustom01" class="mb-0"
                      >Currency :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-8">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      placeholder="USD"
                      disabled
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-3">
                    <label for="validationCustom01" class="mb-0"
                      >Email-Id :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-8">
                    <input
                      type="email"
                      class="form-control"
                      id="validationCustom01"
                      placeholder="Please Enter Your Email-Id"
                      formControlName="email"
                      [readOnly]="routingUrl"
                    />
                    <div
                      *ngIf="
                        email && email.invalid && (email.dirty || email.touched)
                      "
                      class="text text-danger"
                    >
                      Email-Id is required.
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="routingUrl == ''"
                class="form-terms offset-xl-3 offset-sm-4 mb-2"
              >
                <div class="custom-control custom-checkbox d-flex">
                  <input
                    type="checkbox"
                    class="custom-control-input mt-1 me-2"
                    id="customControlAutosizing1"
                    (click)="onChecked()"
                  />
                  <label
                    class="custom-control-label"
                    for="customControlAutosizing1"
                    ><span
                      >I agree all statements in
                      <a
                        href="https://app.termly.io/document/terms-of-use-for-online-marketplace/e341efa9-dd43-45c1-b893-0d2c7c407a74"
                        target="_blank"
                        >Terms &amp; Conditions</a
                      ></span
                    ></label
                  >
                </div>
              </div>
              <div *ngIf="routingUrl == ''" class="form-group row mb-0">
                <div class="col-sm-8 offset-xl-3 offset-sm-4">
                  <div class="product-buttons">
                    <button
                      type="submit"
                      value="submit"
                      [disabled]="!checkedAgree || stripeOnboardForm.invalid"
                      class="btn btn-primary me-1"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      (click)="onCancel()"
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="routingUrl">
            <p class="myClass">
              <b>
                Please complete your stripe onboarding by click
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="onContinue(routingUrl)"
                >
                  Continue
                </button>
                on this.</b
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-xl-4 col-md-4 xl-50">
      <div class="card myheight shadow o-hidden widget-cards">
        <div class="bg-warning card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <app-feather-icons
                  [icon]="'shopping-bag'"
                  class="font-warning"
                ></app-feather-icons>
              </div>
            </div>
            <div class="media-body col-8">
              <span class="m-0">Wallet Balance</span>
              <h3 class="mb-0">
                $
                <span class="counter">0</span>
                
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-md-4 xl-50">
      <div class="card myheight shadow o-hidden widget-cards">
        <div class="bg-danger card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <app-feather-icons
                  [icon]="'navigation'"
                  class="font-secondary"
                ></app-feather-icons>
              </div>
            </div>
            <div class="media-body col-8">
              <span class="m-0">Seller stripe on boarding</span>
              <h3 class="mb-0">
                <span class="counter">0</span>
              
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-md-4 xl-50">
      <div class="card myheight shadow o-hidden widget-cards">
        <div class="bg-primary card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <app-feather-icons
                  [icon]="'dollar-sign'"
                  class="font-primary"
                ></app-feather-icons>
              </div>
            </div>
            <div class="media-body col-8">
              <span class="m-0">Wallet withdrawal</span>
              <h3 class="mb-0">
                $
                <span class="counter">0</span>
                
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-3 xl-50">
      <div class="card shadow o-hidden widget-cards">
        <div class="bg-danger card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <app-feather-icons
                  [icon]="'folder-plus'"
                  class="font-danger"
                ></app-feather-icons>
              </div>
            </div>
            <div class="media-body col-8">
              <span class="m-0">Total Balance</span>
              <h3 class="mb-0">
                $
                <span class="counter">45631</span>
                <small> This Month</small>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
