import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListPageComponent } from "./list-page/list-page.component";
import { CreatePageComponent } from "./create-page/create-page.component";
import { SuccessOnboardingComponent } from "./success-onboarding/success-onboarding.component";
import { UnsuccessOnboardingComponent } from "./unsuccess-onboarding/unsuccess-onboarding.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "list-page",
        component: ListPageComponent,
        data: {
          title: "List Page",
          breadcrumb: "List Page",
        },
      },
      {
        path: "create-page",
        component: CreatePageComponent,
        data: {
          title: "Create Page",
          breadcrumb: "Create Page",
        },
      },
      {
        path: "success-onboarding",
        component: SuccessOnboardingComponent,
        data: {
          title: "Success Onboarding",
          breadcrumb: "Success Onboarding",
        },
      },
      {
        path: "unsuccess-onboarding",
        component: UnsuccessOnboardingComponent,
        data: {
          title: "Unsuccess Onboarding",
          breadcrumb: "Unsuccess Onboarding",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
