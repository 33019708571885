<div class="container">
  <div class="container mb-2 myclass">
    <img
      class="myImg"
      src="../../../../assets/images/ok-1976099_960_720.png"
      alt=""
    />
  </div>
  <h2 class="text-center text-success">
    Your email has been verified successsfully !
  </h2>
  <!-- <h5 class="text-center">Your email has been verified successsfully !</h5>
  <marquee class="text-danger" behavior="" direction=""
    >This page will redirect to LOGIN in 10 sec</marquee
  > -->
</div>
