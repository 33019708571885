<!-- Container-fluid starts-->
<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <div class="card">
    <div class="card-header">
      <h5>Shops List</h5>
    </div>
    <div class="card-body vendor-table text-center">
      <p *ngIf="!shopList?.length">No Shops has been create yet.</p>
      <div id="batchDelete" class="custom-datatable vendor-list">
        <div class="table-responsive">
          <table *ngIf="shopList?.length > 0" class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Shop Name</th>
                <th scope="col">Seller Name</th>
                <th scope="col">Contact</th>
                <th scope="col">Address</th>
                <!-- <th scope="col">Status</th> -->
                <!-- <th scope="col"  >Wallet Balance</th>
                                    <th scope="col" >Revenue</th>
                                    <th scope="col" >Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let shops of shopList">
                <td scope="row">
                  <!-- <img [src]="item.vendor" class="imgTable" style="width: 20px">
                                       <span>{{item.name}}</span>  -->
                  {{ shops?.sl_no }}
                </td>
                <td>
                  {{ shops?.shopName }}
                </td>
                <td>
                  {{ shops?.sellerName }}
                </td>
                <td>{{ shops?.phoneNumber }}</td>
                <td>
                  {{ shops?.shopAddress }}
                </td>
                <!-- <td>{{item.balance}}</td>
                                    <td>{{item.revenue}}</td> -->
                <!-- <td>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-edit f-12"></i></a
                  >&nbsp;
                  <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                </td> -->
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center p-2">
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="shopListLength"
              (pageChange)="getAllShops()"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
