<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Add Shop</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <!-- <div class="col-xl-7"> -->
            <form
              class="needs-validation"
              [formGroup]="createShopForm"
              (ngSubmit)="createShopEntity()"
            >
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Shop Name :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      maxlength="50"
                      id="validationCustom01"
                      placeholder="Please Enter Shop Name"
                      formControlName="shopName"
                    />
                    <div
                      *ngIf="
                        shopName &&
                        shopName.invalid &&
                        (shopName.dirty || shopName.touched)
                      "
                      class="text text-danger"
                    >
                      Shop Name is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Contact Number :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      placeholder="Enter Contact Info Here"
                      formControlName="phoneNumber"
                    />
                    <div
                      *ngIf="
                        phoneNumber &&
                        phoneNumber.invalid &&
                        (phoneNumber.dirty || phoneNumber.touched)
                      "
                      class="text text-danger"
                    >
                      Contact Number is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Shop Address:</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      placeholder="Enter Shop Address Here"
                      formControlName="shopAddress"
                    />
                    <div
                      *ngIf="
                        shopAddress &&
                        shopAddress.invalid &&
                        (shopAddress.dirty || shopAddress.touched)
                      "
                      class="text text-danger"
                    >
                      Shop Address is required.
                    </div>
                    <div
                      *ngIf="
                        createShopForm
                          .get('shopAddress')
                          .hasError('specialChars')
                      "
                      class="text-success"
                    >
                      Special characters are not allowed.
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Shop Address Line2:</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      placeholder="Enter Shop Address Line 2 Here"
                      formControlName="shopAddress2"
                    />
                    <div
                      *ngIf="
                        shopAddress2 &&
                        shopAddress2.invalid &&
                        (shopAddress2.dirty || shopAddress2.touched)
                      "
                      class="text text-danger"
                    >
                      Shop Address is required.
                    </div>
                    <div
                      *ngIf="
                        createShopForm
                          .get('shopAddress2')
                          .hasError('specialChars')
                      "
                      class="text-success"
                    >
                      Special characters are not allowed.
                    </div>
                  </div>
                </div> -->
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Shop City :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      (onkeypress)="restrictComma($event)"
                      placeholder="Enter Shop City Here"
                      formControlName="shopCity"
                    />
                    <div
                      *ngIf="
                        shopCity &&
                        shopCity.invalid &&
                        (shopCity.dirty || shopCity.touched)
                      "
                      class="text text-danger"
                    >
                      Shop City is required.
                    </div>
                    <div
                      *ngIf="
                        createShopForm.get('shopCity').hasError('specialChars')
                      "
                      class="text-success"
                    >
                      Special characters are not allowed.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Shop State :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      placeholder="Enter Shop State Here"
                      formControlName="shopState"
                    />
                    <div
                      *ngIf="
                        shopState &&
                        shopState.invalid &&
                        (shopState.dirty || shopState.touched)
                      "
                      class="text text-danger"
                    >
                      Shop State is required.
                    </div>
                    <div
                      *ngIf="
                        createShopForm.get('shopState').hasError('specialChars')
                      "
                      class="text-success"
                    >
                      Special characters are not allowed.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Shop Country :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      placeholder="US"
                      disabled
                      formControlName="shopCountry"
                    />
                    <!-- <div
                      *ngIf="
                        shopCountry &&
                        shopCountry.invalid &&
                        (shopCountry.dirty || shopCountry.touched)
                      "
                      class="text text-danger"
                    >
                      Shop Country is required.
                    </div> -->
                    <!-- <div
                      *ngIf="
                        createShopForm
                          .get('shopCountry')
                          .hasError('specialChars')
                      "
                      class="text-success"
                    >
                      Special characters are not allowed.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Shop ZipCode :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      placeholder="Enter Shop Zipcode Here"
                      formControlName="shopZipCode"
                    />
                    <div
                      *ngIf="
                        shopZipCode &&
                        shopZipCode.invalid &&
                        (shopZipCode.dirty || shopZipCode.touched)
                      "
                      class="text text-danger"
                    >
                      Shop Zipcode is required.
                    </div>
                    <div
                      *ngIf="
                        createShopForm
                          .get('shopZipCode')
                          .hasError('specialChars')
                      "
                      class="text-success"
                    >
                      Special characters are not allowed.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Select TimeZone :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="timeZone"
                    >
                      <option [ngValue]="0">Select TimeZone</option>
                      <option
                        *ngFor="let timeZones of timeZoneList"
                        [value]="timeZones.name"
                      >
                        {{ timeZones.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        timeZone &&
                        timeZone.invalid &&
                        (timeZone.dirty || timeZone.touched || !timeZone.value)
                      "
                      class="text text-danger"
                    >
                      Shop TimeZone is required.
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Shop Opening Time :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="time"
                      placeholder="Enter Shop Open Time Here"
                      formControlName="openingTime"
                    />
                    <div
                      *ngIf="
                        openingTime &&
                        openingTime.invalid &&
                        (openingTime.dirty || openingTime.touched)
                      "
                      class="text text-danger"
                    >
                      Shop Opening Time is required.
                    </div>
                  </div>
                </div> -->
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Shop Closing Time :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="time"
                      placeholder="Enter closing Time Here"
                      formControlName="closingTime"
                    />
                    <div
                      *ngIf="
                        closingTime &&
                        closingTime.invalid &&
                        (closingTime.dirty || closingTime.touched)
                      "
                      class="text text-danger"
                    >
                      Shop closing Time is required.
                    </div>
                  </div>
                </div> -->
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Closing Days :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <ng-multiselect-dropdown
                      [ngModelOptions]="{ standalone: true }"
                      [placeholder]="'Days of Week'"
                      [settings]="dropdownSettings"
                      [data]="dropdownList"
                      [(ngModel)]="selectedItems"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div> -->
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Available PickupTime(From) :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="time"
                      placeholder="Enter Shop available pickup time Here"
                      formControlName="availablePickupTime"
                    />
                    <div
                      *ngIf="
                        availablePickupTime &&
                        availablePickupTime.invalid &&
                        (availablePickupTime.dirty ||
                          availablePickupTime.touched)
                      "
                      class="text text-danger"
                    >
                      Available PickupTime(from) is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Available PickupTime(To) :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="time"
                      placeholder="Enter Shop available pickup time Here"
                      formControlName="availablePickupTimeTo"
                    />
                    <div
                      *ngIf="
                        availablePickupTimeTo &&
                        availablePickupTimeTo.invalid &&
                        (availablePickupTimeTo.dirty ||
                          availablePickupTimeTo.touched)
                      "
                      class="text text-danger"
                    >
                      Available PickupTime(To) is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Want to Deliver :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="deliveryNeed"
                      (change)="onDeliveryChange()"
                    >
                      <option [ngValue]="0">Yes/No</option>
                      <option
                        *ngFor="let need of deliveryNeed"
                        [value]="need.name"
                      >
                        {{ need.name }}
                      </option>
                    </select>
                    <!-- <div
                      *ngIf="
                        deliveryFeeType &&
                        deliveryFeeType.invalid &&
                        (deliveryFeeType.dirty || deliveryFeeType.touched || !deliveryFeeType.value)
                      "
                      class="text text-danger"
                    >
                      Shop TimeZone is required.
                    </div> -->
                  </div>
                </div>
                <div *ngIf="needToDeliver" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Select deliveryFee Type :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="deliveryFeeType"
                    >
                      <option [ngValue]="0">Select DeliveryFee Type</option>
                      <option
                        *ngFor="let feeType of feeTypeList"
                        [value]="feeType.name"
                      >
                        {{ feeType.name }}
                      </option>
                    </select>
                    <!-- <div
                      *ngIf="
                        deliveryFeeType &&
                        deliveryFeeType.invalid &&
                        (deliveryFeeType.dirty || deliveryFeeType.touched || !deliveryFeeType.value)
                      "
                      class="text text-danger"
                    >
                      Shop TimeZone is required.
                    </div> -->
                  </div>
                </div>
                <div *ngIf="needToDeliver" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Delivery Fee :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="number"
                      placeholder="Enter DeliveryFee Value Here"
                      formControlName="deliveryFeeValue"
                    />
                    <!-- <div
                      *ngIf="
                        phoneNumber &&
                        phoneNumber.invalid &&
                        (phoneNumber.dirty || phoneNumber.touched)
                      "
                      class="text text-danger"
                    >
                      Contact Number is required.
                    </div> -->
                  </div>
                </div>
                <!-- <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom03" class="mb-0"
                        >Brand Name :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom03"
                        type="text"
                        placeholder="Enter Your Brand Name"
                        formControlName="brandname"
                      />
                      <div
                        *ngIf="
                          brandname &&
                          brandname.invalid &&
                          (brandname.dirty || brandname.touched)
                        "
                        class="text text-danger"
                      >
                        Brand Name is required.
                      </div>
                    </div>
                  </div> -->
              </div>
              <div class="form-group row mb-0">
                <div class="col-sm-8 offset-xl-3 offset-sm-4">
                  <div class="product-buttons">
                    <button
                      type="submit"
                      value="submit"
                      class="btn btn-primary me-1"
                      [disabled]="createShopForm.invalid || !timeZone.value"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      (click)="onDiscard()"
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
