<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header d-flex">
          <div>
            <h5>Create Sale</h5>
            <span class="text-dark"
              >(This is required to confirm a product/service price and initial
              stock)</span
            >
          </div>
          <div *ngIf="isSaleDone" class="col-xl-6 col-sm-6">
            <a
              [routerLink]="['/products/product-list']"
              type="button"
              class="btn btn-primary float-end"
            >
              View Product List
            </a>
          </div>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <!-- <div class="col-xl-5">
              <div class="add-product"></div>
            </div> -->
            <!-- <div class="col-xl-7"> -->
            <form
              class="needs-validation"
              [formGroup]="createSaleForm"
              (ngSubmit)="createSale()"
            >
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Select Product <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      #mySelect
                      (change)="onSelectProduct(mySelect.value)"
                      aria-label="Default select example"
                      formControlName="productId"
                    >
                      <option [value]="0">Select Product</option>
                      <option
                        *ngFor="let product of productList"
                        [value]="product.productId"
                      >
                        {{ product.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="f && formSubmitted && f.productId.value == 0"
                      class="text text-danger"
                    >
                      Please Select a Product.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Select Component <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <!-- <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="componentId"
                    >
                      <option [ngValue]="0">Select Component</option>
                      <option
                        *ngFor="let comp of componentList"
                        [value]="comp.componentId"
                      >
                        {{ comp.componentName }}
                      </option>
                    </select> -->

                    <ng-multiselect-dropdown
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Select Components"
                      [data]="componentList"
                      name="sizeList"
                      [(ngModel)]="selectedItems"
                      [settings]="dropdownSettings"
                      (onSelect)="onItemSelect($event)"
                      (onDeSelect)="onItemDeSelect($event)"
                    >
                    </ng-multiselect-dropdown>

                    <!-- <div
                      *ngIf="
                        componentId &&
                        !componentId.value &&
                        (componentId.dirty || componentId.touched)
                      "
                      class="text text-danger"
                    >
                      Please Select a Component.
                    </div> -->
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom04" class="mb-0"
                      >Stock <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom04"
                      type="number"
                      placeholder="0"
                      formControlName="stock"
                    />
                    <div
                      *ngIf="
                        stock && stock.invalid && (stock.dirty || stock.touched)
                      "
                      class="text text-danger"
                    >
                      Stock is required.
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom05" class="mb-0">Until :</label>
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom05"
                      type="date"
                      formControlName="until"
                    />
                    <div
                      *ngIf="
                        until && until.invalid && (until.dirty || until.touched)
                      "
                      class="text text-danger"
                    >
                      Until Date is required.
                    </div>
                  </div>
                </div> -->
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Price <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom06"
                      type="number"
                      placeholder="0"
                      formControlName="price"
                    />
                    <div
                      *ngIf="
                        price && price.invalid && (price.dirty || price.touched)
                      "
                      class="text text-danger"
                    >
                      Price is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Discount (Optional) :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom06"
                      type="number"
                      placeholder="%"
                      formControlName="discount"
                    />
                    <!-- <div
                      *ngIf="
                        discount &&
                        discount.invalid &&
                        (discount.dirty || discount.touched)
                      "
                      class="text text-danger"
                    >
                      Discount is required.
                    </div> -->
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0">Offer :</label>
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom06"
                      type="text"
                      placeholder="Please Enter Offer Here"
                      formControlName="offer"
                    />
                    <div
                      *ngIf="
                        offer && offer.invalid && (offer.dirty || offer.touched)
                      "
                      class="text text-danger"
                    >
                      Offer is required.
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="form-group row mb-0">
                <div class="col-sm-8 offset-xl-3 offset-sm-4">
                  <!-- class = "offset-xl-3 offset-sm-4" -->
                  <div class="product-buttons mb-1">
                    <button
                      type="submit"
                      [disabled]="createSaleForm.invalid"
                      class="btn btn-primary me-1"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      (click)="onDiscard()"
                    >
                      Discard
                    </button>
                  </div>
                </div>
                <!-- *ngIf="isSaleDone" -->
              </div>
            </form>
            <div *ngIf="isSaleDone" class="form-group row mb-0">
              <div class="col-sm-8 offset-xl-3 offset-sm-4">
                <!-- class = "offset-xl-3 offset-sm-4" -->
                <div class="product-buttons">
                  <button
                    [routerLink]="['/products/product-list']"
                    type="button"
                    class="btn btn-primary"
                  >
                    View Product List
                  </button>
                </div>
              </div>
              <!-- *ngIf="isSaleDone" -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
