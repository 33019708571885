<!-- Container-fluid starts-->
<div class="container-fluid bulk-cate">
    <div class="card ">
        <div class="card-header">
            <h5>Dropzone Media</h5>
        </div>
        <div class="card-body">
            <div class="dropzone-custom">
                <ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                            <i class="fa fa-cloud-upload"></i>
                            <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                        </div>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </div>  
    </div>

    <div class="card">
        <div class="card-header">
            <h5>Media File List</h5>
        </div>
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable media-datatable">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="action">Actions</th>
                                <th scope="col" sortable="img" (sort)="onSort($event)">Image</th>
                                <th scope="col" sortable="file_name" (sort)="onSort($event)">File Name</th>
                                <th scope="col" sortable="url" (sort)="onSort($event)">URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tableItem$ | async ">
                                <td>
                                    <a href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                                </td>
                                <td scope="row">
                                    <img [src]="item.img" class="imgTable" style="width: 20px">
                                </td>
                                <td>
                                    {{item.file_name}}
                                </td>
                                <td>{{item.url}}</td>
                            </tr>
                        </tbody>
                     
                    </table>
                    <div class="d-flex justify-content-center p-2 border-top-0">
                        <ngb-pagination
                          [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->