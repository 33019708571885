<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-8 col-md-6">
            <div class="card">
                <div class="card-header">
                    <h5>Sales Summary</h5>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="salesChartData" [labels]="salesChartLabels"
                        [options]="salesChartOptions"  [legend]="salesChartLegend"
                        [type]="salesChartType"></canvas>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6">
            <div class="card report-employee">
                <div class="card-header">
                    <h2>75%</h2>
                    <h6 class="mb-0">Employees Satisfied</h6>
                </div>
                <div class="card-body p-0 o-hidden">
                    <div class="ct-4 flot-chart-container report-full">
                            <x-chartist [type]="chart5.type" [data]="chart5.data" [options]="chart5.options"></x-chartist>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Transfer Report</h5>
                </div>
                <div class="card-body">
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sorted="name" (sort)="onSort($event)">Name</th>
                                        <th scope="col" sorted="id" (sort)="onSort($event)">Transaction Id</th>
                                        <th scope="col" sortable="date" (sort)="onSort($event)">Date</th>
                                        <th scope="col" sortable="total" (sort)="onSort($event)">Total</th>
                                        <th scope="col" >Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tableItem$ | async ">
                                        <td scope="row">
                                           <span>{{item.name}}</span> 
                                        </td>
                                        <td>
                                            {{item.id}}
                                        </td>
                                        <td>{{item.date}}</td>
                                        <td>{{item.total}}</td>
                                        <td>
                                            <a href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                            <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>   
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination
                                  [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-header">
                    <h5>Expenses</h5>
                </div>
                <div class="card-body expense-chart">
                    <div class="chart-overflow" id="area-chart1">
                        <google-chart [data]="areaChart1"></google-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-header">
                    <h5> Sales / Purchase</h5>
                </div>
                <div class="card-body">
                    <div class="sales-chart">
                        <google-chart [data]="columnChart1"></google-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5> Sales / Purchase Return</h5>
                </div>
                <div class="card-body sell-graph">
                    <google-chart [data]="lineChart"></google-chart>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->