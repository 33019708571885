import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateShopComponent } from "./create-shop/create-shop.component";
import { ShopCategoryComponent } from "./shop-category/shop-category.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { ShopRoutingModule } from "./shop-routing.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BrowserModule } from "@angular/platform-browser";
import { ShopListComponent } from "./shop-list/shop-list.component";
import { AddShopDetailsComponent } from "./add-shop-details/add-shop-details.component";

@NgModule({
  declarations: [
    CreateShopComponent,
    ShopCategoryComponent,
    ShopListComponent,
    AddShopDetailsComponent,
  ],
  imports: [
    CommonModule,
    ShopRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
})
export class ShopModule {}
