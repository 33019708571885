<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-body">
          <div class="profile-details text-center">
            <img
              src="assets/images/dashboard/avatar.jpeg"
              alt=""
              class="img-fluid img-90 rounded-circle blur-up lazyloaded"
            />
            <h5 class="f-w-600 mb-0">{{ SellerDetails?.fullName }}</h5>
            <span>{{ SellerDetails?.email }}</span>
            <!-- <div class="social">
              <div class="form-group btn-showcase">
                <button class="btn social-btn btn-fb d-inline-block">
                  <i class="fa fa-facebook"></i>
                </button>
                <button class="btn social-btn btn-twitter d-inline-block">
                  <i class="fa fa-google"></i>
                </button>
                <button class="btn social-btn btn-google d-inline-block me-0">
                  <i class="fa fa-twitter"></i>
                </button>
              </div>
            </div> -->
          </div>
          <hr />
          <!-- <div class="project-status">
                        <h5 class="f-w-600">Employee Status</h5>
                        <div class="media">
                            <div class="media-body">
                                <h6>Performance<span class="pull-right">80%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-primary" role="progressbar" style="width: 90%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Overtime <span class="pull-right">60%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-secondary" role="progressbar" style="width: 60%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Leaves taken<span class="pull-right">70%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 70%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
    <div class="col-xl-8">
      <div class="card tab2-card">
        <div class="card-body">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a #pro ngbNavLink
                ><i class="me-2" data-feather="user"></i>Profile</a
              >
              <ng-template ngbNavContent>
                <div
                  class="tab-pane fade show active"
                  id="top-profile"
                  role="tabpanel"
                  aria-labelledby="top-profile-tab"
                >
                  <h5 class="f-w-600">Profile</h5>
                  <div class="table-responsive profile-table">
                    <table class="table table-responsive">
                      <tbody>
                        <tr>
                          <td>First Name:</td>
                          <td>{{ this.selleFirstName }}</td>
                        </tr>
                        <tr>
                          <td>Last Name:</td>
                          <td>{{ this.selleLastName }}</td>
                        </tr>
                        <tr>
                          <td>Contact:</td>
                          <td>{{ SellerDetails?.mobileNumber || "N/A" }}</td>
                        </tr>
                        <tr>
                          <td>Email:</td>
                          <td>{{ SellerDetails?.email }}</td>
                        </tr>
                        <!-- <tr>
                          <td>Gender:</td>
                          <td>Male</td>
                        </tr>
                        <tr>
                          <td>Mobile Number:</td>
                          <td>2124821463</td>
                        </tr>
                        <tr>
                          <td>DOB:</td>
                          <td>Dec, 15 1993</td>
                        </tr> -->
                        <tr>
                          <td>Location:</td>
                          <td>USA</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-template>
            </li>
            <!-- <li class="d-none" [ngbNavItem]="2">
              <a ngbNavLink class="d-flex align-items-center"
                ><i data-feather="settings" class="me-2"></i>Contact</a
              >
              <ng-template ngbNavContent>
                <div class="account-setting">
                  <h5 class="f-w-600">Notifications</h5>
                  <div class="row">
                    <div class="col">
                      <label class="d-block mb-2" for="chk-ani">
                        <input
                          class="checkbox_animated"
                          id="chk-ani"
                          type="checkbox"
                        />
                        Allow Desktop Notifications
                      </label>
                      <label class="d-block mb-2" for="chk-ani1">
                        <input
                          class="checkbox_animated"
                          id="chk-ani1"
                          type="checkbox"
                        />
                        Enable Notifications
                      </label>
                      <label class="d-block mb-2" for="chk-ani2">
                        <input
                          class="checkbox_animated"
                          id="chk-ani2"
                          type="checkbox"
                        />
                        Get notification for my own activity
                      </label>
                      <label class="d-block mb-0" for="chk-ani3">
                        <input
                          class="checkbox_animated"
                          id="chk-ani3"
                          type="checkbox"
                          checked=""
                        />
                        DND
                      </label>
                    </div>
                  </div>
                </div>
                <div class="account-setting deactivate-account">
                  <h5 class="f-w-600">Deactivate Account</h5>
                  <div class="row">
                    <div class="col">
                      <label class="d-block mb-2" for="edo-ani">
                        <input
                          class="radio_animated"
                          id="edo-ani"
                          type="radio"
                          name="rdo-ani"
                          checked=""
                        />
                        I have a privacy concern
                      </label>
                      <label class="d-block mb-2" for="edo-ani1">
                        <input
                          class="radio_animated"
                          id="edo-ani1"
                          type="radio"
                          name="rdo-ani"
                        />
                        This is temporary
                      </label>
                      <label class="d-block mb-0" for="edo-ani2">
                        <input
                          class="radio_animated"
                          id="edo-ani2"
                          type="radio"
                          name="rdo-ani"
                          checked=""
                        />
                        Other
                      </label>
                    </div>
                  </div>
                  <button type="button" class="btn btn-primary">
                    Deactivate Account
                  </button>
                </div>
                <div class="account-setting deactivate-account">
                  <h5 class="f-w-600">Delete Account</h5>
                  <div class="row">
                    <div class="col">
                      <label class="d-block mb-2" for="edo-ani3">
                        <input
                          class="radio_animated"
                          id="edo-ani3"
                          type="radio"
                          name="rdo-ani1"
                          checked=""
                        />
                        No longer usable
                      </label>
                      <label class="d-block mb-2" for="edo-ani4">
                        <input
                          class="radio_animated"
                          id="edo-ani4"
                          type="radio"
                          name="rdo-ani1"
                        />
                        Want to switch on other account
                      </label>
                      <label class="d-block mb-0" for="edo-ani5">
                        <input
                          class="radio_animated"
                          id="edo-ani5"
                          type="radio"
                          name="rdo-ani1"
                          checked=""
                        />
                        Other
                      </label>
                    </div>
                  </div>
                  <button type="button" class="btn btn-primary">
                    Delete Account
                  </button>
                </div>
              </ng-template>
            </li> -->
            <li [ngbNavItem]="2">
              <a ngbNavLink class="d-flex align-items-center"
                ><i data-feather="settings" class="me-2"></i>Update Profile</a
              >
              <ng-template ngbNavContent>
                <div class="account-setting">
                  <h5 class="f-w-600">Profile Details</h5>
                  <form
                    class="needs-validation"
                    [formGroup]="updateProfileForm"
                    (ngSubmit)="updateProfile()"
                  >
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="validationCustom02" class="mb-0"
                          >Full Name :</label
                        >
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <input
                          class="form-control"
                          id="validationCustom02"
                          type="text"
                          placeholder="Enter Full Name Here"
                          formControlName="fullName"
                        />
                        <div
                          *ngIf="
                            fullName &&
                            fullName.invalid &&
                            (fullName.dirty || fullName.touched)
                          "
                          class="text text-danger"
                        >
                          Full Name is required.
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="validationCustom02" class="mb-0"
                          >Contact Number :</label
                        >
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <input
                          class="form-control"
                          id="validationCustom02"
                          type="text"
                          placeholder="Enter mobileNumber Here"
                          formControlName="mobileNumber"
                        />
                        <div
                          *ngIf="
                            mobileNumber &&
                            mobileNumber.invalid &&
                            (mobileNumber.dirty || mobileNumber.touched)
                          "
                          class="text text-danger"
                        >
                          Contact Number is required.
                        </div>
                      </div>
                    </div>
                    <div class="form-group row mb-0">
                      <div class="col-sm-8 offset-xl-3 offset-sm-4">
                        <div class="product-buttons">
                          <button
                            type="submit"
                            value="submit"
                            class="btn btn-primary me-1"
                            [disabled]="updateProfileForm.invalid"
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            class="btn btn-light"
                            (click)="onDiscard()"
                          >
                            Discard
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>

          <!-- <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Profile</ng-template>
                            <ng-template ngbTabContent>

                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i data-feather="settings"></i> Contact</ng-template>
                            <ng-template ngbTabContent>
                               
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
