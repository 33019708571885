<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Add Size</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-7">
              <form
                class="needs-validation"
                [formGroup]="createSizeForm"
                (ngSubmit)="onCreateSize()"
              >
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Choose Product :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="product_id"
                        (change)="onSelectProduct()"
                      >
                        <option [ngValue]="0">Select Product</option>
                        <option
                          *ngFor="let product of product_list"
                          [value]="product.productId"
                        >
                          {{ product.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom01" class="mb-0"
                        >Choose Variant :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="variant_id"
                      >
                        <option [ngValue]="0">Select Variant</option>
                        <option
                          *ngFor="let variant of Variant_list"
                          [value]="variant.colorName"
                        >
                          {{ variant.colorName }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          variant_id &&
                          variant_id.invalid &&
                          (variant_id.dirty || variant_id.touched)
                        "
                        class="text text-danger"
                      >
                        Variant is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Name :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom02"
                        type="text"
                        placeholder="Enter Name Here"
                        formControlName="name"
                      />
                      <div
                        *ngIf="
                          name && name.invalid && (name.dirty || name.touched)
                        "
                        class="text text-danger"
                      >
                        Color Name is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-sm-8 offset-xl-3 offset-sm-4">
                    <div class="product-buttons">
                      <button
                        type="submit"
                        value="submit"
                        class="btn btn-primary me-1"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        (click)="onDiscard()"
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
