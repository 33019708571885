import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProductService } from "src/app/_services/product/product.service";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
})
export class OrderListComponent implements OnInit {
  orderList: any;
  status: any;
  StatusEnum = StatusEnum;
  orderProductId: any;
  constructor(
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getOrderList();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  getOrderList() {
    this.showSpinner();
    this.productService.getOrderList().subscribe((response: any) => {
      this.orderList = response;
      console.log("response", this.orderList);
    });
  }
  onOrderClick(orderProductId: any) {
    this.orderProductId = orderProductId;
  }

  onStatusSave() {
    console.log("status", this.status);
    if (this.status == 1) {
      this.productService
        .orderReadyForPickupByorderProductId(this.orderProductId)
        .subscribe((res: any) => {
          console.log("res", res);
          if (res.status == 0) {
            this.showSpinner();
            this.getOrderList();
            this.toastr.success("Order Status Updated Successfully");
          } else {
            this.toastr.error("Something Went Wrong ! Please Try Again");
          }
        });
    } else if (this.status == 2) {
      this.productService
        .orderCompleteByorderProductId(this.orderProductId)
        .subscribe((res: any) => {
          console.log("res", res);
          if (res.status == 0) {
            this.showSpinner();
            this.getOrderList();
            this.toastr.success("Order Status Updated Successfully");
          } else {
            this.toastr.error("Something Went Wrong ! Please Try Again");
          }
        });
    } else {
      return;
    }
  }
}
export enum StatusEnum {
  "PAID" = 0,
  "READY_FOR_PICKUP" = 1,
  "COMPLETE" = 2,
}
