<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6 col-xl-6">
              <h5>Import Product</h5>
            </div>
            <div class="col-md-6 col-xl-6">
              <div class="btn-popup pull-right"></div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-7">
              <form class="needs-validation">
                <div class="add-product-form">
                  <div class="mb-2">
                    <a
                      href="../../../../../assets/Te13 Product Import Data new (1).csv"
                      download="Te13 Product Import Data new (1).csv"
                      >Download Product Import Template</a
                    >
                  </div>
                  <form class="needs-validation" [formGroup]="createImportForm">
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="validationCustom02" class="mb-0"
                          >Select Shop <sup class="star">*</sup> :</label
                        >
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          formControlName="shopId"
                          (change)="onSelectShop()"
                        >
                          <option [ngValue]="0">Select Shop</option>
                          <option
                            *ngFor="let shop of shopList"
                            [value]="shop.shopId"
                          >
                            {{ shop.shopName }}
                          </option>
                        </select>
                        <div
                          *ngIf="
                            shopId &&
                            !shopId.value &&
                            (shopId.dirty || shopId.touched)
                          "
                          class="text text-danger"
                        >
                          Please Select a Shop.
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="validationCustom06" class="mb-0"
                          >Import CSV <sup class="star">*</sup> :</label
                        >
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <input
                          #myCsv
                          class="form-control"
                          id="validationCustom06"
                          type="file"
                          (change)="onFileSelected($event)"
                          accept=".csv"
                        />
                        <!-- <div
                      *ngIf="
                        price && price.invalid && (price.dirty || price.touched)
                      "
                      class="text text-danger"
                    >
                      Price is required.
                    </div> -->
                      </div>
                    </div>
                  </form>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-sm-8 offset-xl-3 offset-sm-4">
                    <div class="product-buttons">
                      <!-- <button
                        type="submit"
                        class="btn btn-primary me-1"
                        (click)="testConnection()"
                      >
                        Test Connection
                      </button> -->
                      <button
                        type="submit"
                        (click)="onAddClick()"
                        class="btn btn-primary me-1"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        (click)="onDiscard()"
                        class="btn btn-light"
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
