import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { ProductService } from "src/app/_services/product/product.service";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { ShopService } from "src/app/_services/shop/shop.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-shopify-connect",
  templateUrl: "./shopify-connect.component.html",
  styleUrls: ["./shopify-connect.component.scss"],
})
export class ShopifyConnectComponent implements OnInit {
  createShopifyConnectForm: any;
  shopList: any;
  shop_id: any;
  userInfo: any;
  newUser: any;
  userId: any;
  allConnections: any;
  isLoading: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private categoryService: CategoryServiceService,
    private shopService: ShopService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private productService: ProductService,
    private sessionManager: SessionManagerService
  ) {
    this.createShopifyConnectForm = this.formBuilder.group({
      shopId: ["", [Validators.required]],
      url: ["", [Validators.required]],
      token: ["", [Validators.required]],
    });
    // this.createShopCategoryForm.controls["categoryId"].setValue(0);
    this.createShopifyConnectForm.controls["shopId"].setValue(0);
  }

  ngOnInit(): void {
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    console.log("newUser", this.newUser.userName);
    this.userId = this.newUser.userId;
    console.log("userId", this.userId);
    this.fetchConnections();
    this.showSpinner();
    this.getAllShops();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  fetchConnections() {
    let fd = {
      sellerId: this.userId,
    };
    this.productService.fetchShopifyCredentials(fd).subscribe((res: any) => {
      this.allConnections = res.map((item: any) => {
        return {
          ...item,
          isSyncing: false,
        };
      });
      console.log("this.allConnections", this.allConnections);
    });
  }

  testConnection() {
    if (
      this.createShopifyConnectForm.invalid ||
      this.createShopifyConnectForm.value["shopId"] == 0
    ) {
      this.toastr.error("Please fill up the mandatory fields (marked in *)");
      // this.formSubmitted = false;
      return;
    }

    Swal.fire({
      title: "Do you want to test the connection?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Test`,
      denyButtonText: `Don't Test`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          shopId: this.shop_id,
          url: this.createShopifyConnectForm.value["url"],
          accessToken: this.createShopifyConnectForm.value["token"],
          sellerId: this.userId,
        };
        console.log("fd", fd);
        this.productService
          .shopifyConnectionTest(fd)
          .subscribe((response: any) => {
            console.log("shopify", response);
            if (response?.status == "0") {
              this.autoAdd(fd);
              this.showSpinner();
              Swal.fire("Connection Tested Succesfully!", "", "success");
              this.onDiscard();
            } else {
              Swal.fire("Connection unsuccessful", "", "error");
            }
            // this.router.navigate(["/products/product-list"]);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  autoAdd(data: any) {
    if (data) {
      this.productService
        .saveShopifyCredentials(data)
        .subscribe((response: any) => {
          console.log("shopify", response);
          if (response?.status == "0") {
            this.showSpinner();
            this.fetchConnections();
            Swal.fire("Shopify Connect Succesfully!", "", "success");
            this.onDiscard();
          } else {
            this.fetchConnections();
            Swal.fire("Shopify Connect unsuccessful!,Try Again", "", "error");
          }

          // this.router.navigate(["/products/product-list"]);
        });
    }
  }

  createShopConnect() {
    if (
      this.createShopifyConnectForm.invalid ||
      this.createShopifyConnectForm.value["shopId"] == 0
    ) {
      this.toastr.error("Please fill up the mandatory fields (marked in *)");
      // this.formSubmitted = false;
      return;
    }

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          shopId: this.shop_id,
          url: this.createShopifyConnectForm.value["url"],
          accessToken: this.createShopifyConnectForm.value["token"],
          sellerId: this.userId,
        };
        console.log("fd", fd);
        this.productService
          .saveShopifyCredentials(fd)
          .subscribe((response: any) => {
            console.log("shopify", response);
            if (response?.status == "0") {
              this.showSpinner();
              this.fetchConnections();
              Swal.fire("Shopify Connect Succesfully!", "", "success");
              this.onDiscard();
            } else {
              this.fetchConnections();
              Swal.fire("Shopify Connect unsuccessful!,Try Again", "", "error");
            }

            // this.router.navigate(["/products/product-list"]);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  getAllShops(shopId?: any) {
    this.shopService.getAllShops().subscribe((res: any) => {
      this.shopList = res;
      // this.createShopifyConnectForm.get("shopId").setValue(shopId);
      console.log("this.shopList", this.shopList);
    });
  }

  productSync(connections: any) {
    console.log("connections", connections);
    Swal.fire({
      title: "It will take some time,want to continue?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Sync`,
      denyButtonText: `Don't sync`,
    }).then((result) => {
      if (result.isConfirmed) {
        connections.isSyncing = true;
        let fd = {
          shopId: connections?.shopId.toString(),
          url: connections?.shopifyUrl,
          accessToken: connections?.shopifyAccessToken,
          sellerId: connections?.sellerId.toString(),
          shopName: connections?.shopName,
        };
        console.log("fd", fd);
        this.productService
          .shopifyProductsSync(fd)
          .subscribe((response: any) => {
            console.log("shopify", response);
            if (response?.status == "0") {
              this.showSpinner();
              this.fetchConnections();
              connections.isSyncing = false;
              Swal.fire("Product Synced Succesfully!", "", "success");
              this.onDiscard();
            } else {
              this.fetchConnections();
              connections.isSyncing = false;
              Swal.fire("Product Synced unsuccessful!,Try Again", "", "error");
            }

            // this.router.navigate(["/products/product-list"]);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onSelectShop() {
    this.shop_id = this.createShopifyConnectForm.value["shopId"];
    console.log("shopId", this.shop_id);
  }

  onDiscard() {
    this.createShopifyConnectForm.reset();
    this.createShopifyConnectForm.controls["shopId"].setValue(0);
    // this.createShopCategoryForm.controls["categoryId"].setValue(0);
  }

  get shopId() {
    return this.createShopifyConnectForm.get("shopId");
  }
  get url() {
    return this.createShopifyConnectForm.get("url");
  }

  get token() {
    return this.createShopifyConnectForm.get("token");
  }
}
