<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>

  <div class="row align-items-center mb-3">
    <div class="col-lg-4 col-md-6 mb-2 mb-md-0">
      <select
        class="form-select form-select-sm"
        aria-label=".form-select-sm example"
        #selectRef
        (change)="selectProducts($event)"
      >
        <option value="0" selected>All Products</option>
        <option value="1">Published Products</option>
        <option value="2">Unpublished Products</option>
      </select>
    </div>
    <div class="col-lg-4 col-md-6"></div>
    <div class="col-lg-4 col-md-6 mb-2 mb-md-0 position-relative">
      <input
        (input)="onFilterClick('search', $any($event.target).value)"
        type="search"
        [(ngModel)]="searchTerm"
        class="form-control"
        placeholder="Search Products"
      />
      <img
        src="../../../../../assets/images/cancel.svg"
        class="cancelPos hand position-absolute"
        (click)="onClearText()"
        style="right: 10px; top: 50%; transform: translateY(-50%)"
      />
    </div>
  </div>

  <div class="d-flex justify-content-between mb-4">
    <button
      *ngIf="this.savedData.length > 0"
      type="button"
      class="btn btnColor"
      (click)="onSelectedUnPublish()"
    >
      UnPublish Selected
    </button>
    <button
      *ngIf="this.savedData.length > 0"
      type="button"
      class="btn btn-primary"
      (click)="onSelectedPublish()"
    >
      Publish Selected
    </button>
  </div>

  <div class="btn-popup">
    <div class="row products-admin ratio_asos">
      <div *ngIf="allProductList?.length <= 0" class="text-center w-100">
        <h2>
          <span class="text-danger"><b>Sorry! No Products Found</b></span>
        </h2>
      </div>
      <div
        class="col-sm-6 col-md-4 col-lg-3 mb-4"
        *ngFor="let product of product_list"
      >
        <input
          type="checkbox"
          (click)="onSelectActiveClick($event, product)"
        /><span class="checkmark"></span>
        <div class="card max">
          <div class="card-body product-box">
            <div *ngIf="product?.status == 'unpublished'" class="ribbon red">
              <span>Unpublished</span>
            </div>
            <div *ngIf="product?.status == 'published'" class="ribbon">
              <span>Published</span>
            </div>
            <div class="img-wrapper">
              <div class="lable-block">
                <span class="lable3" *ngIf="product?.productNew == 'true'"
                  >new</span
                >
                <span class="lable4" *ngIf="product?.sale == 'on sale'"
                  >on sale</span
                >
              </div>
              <div class="front front_height">
                <a href="javascript:void(0)"
                  ><img
                    [src]="[product?.smPictures[0]?.url]"
                    class="img-fluid blur-up lazyload bg-img"
                    alt=""
                /></a>
                <div class="product-hover">
                  <ul>
                    <li>
                      <button
                        class="btn"
                        type="button"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        (click)="onDeleteClick(product?.id)"
                      >
                        <i class="icon-trash"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="product-detail">
              <a [routerLink]="['/products/product-detail/', product?.id]">
                <h6>{{ product?.name }}</h6>
              </a>
              <h4>
                {{
                  product.saleprice > 0 ? product?.saleprice : product?.price
                }}
                <del *ngIf="product.saleprice">{{ product.price }}</del>
              </h4>
              <ul class="color-variant">
                <li
                  *ngFor="let variant of product?.variants"
                  [ngStyle]="{ 'background-color': variant?.color }"
                ></li>
              </ul>
            </div>
            <div class="front">
              <label
                class="switch"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="{{
                  product?.status == 'published' ? 'Published' : 'Unpublished'
                }}"
              >
                <input
                  type="checkbox"
                  [checked]="product?.status == 'published'"
                  (click)="onActiveClick($event, product?.id, product?.status)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!fillterActive" class="d-flex justify-content-center p-2">
      <ngb-pagination
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="totalCount"
        (pageChange)="fetchAllProducts()"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
