import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { NavService, Menu } from "../../service/nav.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  userInfo: any;
  newUser: any;
  signedInUser: any;

  constructor(
    private router: Router,
    public navServices: NavService,
    private sessionManager: SessionManagerService
  ) {}
  ngOnInit() {
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    console.log("newUser", this.newUser.userName);
    this.signedInUser = this.newUser.groups[0];
    console.log("signedInUser", this.signedInUser);
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems =
        this.signedInUser === "Admin"
          ? menuItems.filter(
              (item) =>
                item.title != "Products" &&
                item.title != "Shop" &&
                item.title != "Orders" &&
                item.title != "Transactions"
            )
          : menuItems.filter(
              (item) =>
                item.title != "Category & Features" &&
                item.title != "Users" &&
                item.title != "Vendors" &&
                item.title != "Product List" &&
                item.title != "Seller-CRM" &&
                item.title != "Home Page" &&
                item.title != "OrderList Admin"
            );

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter((items) => {
            if (items.path === event.url) this.setNavActive(items);
            if (!items.children) return false;
            items.children.filter((subItems) => {
              if (subItems.path === event.url) this.setNavActive(subItems);
              if (!subItems.children) return false;
              subItems.children.filter((subSubItems) => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems);
              });
            });
          });
        }
      });
    });
  }

  // getUserInfo() {
  //   let userInfo = this.sessionManager.getUserInfo();
  //   console.log(userInfo);
  // }
  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }
}
