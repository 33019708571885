import { DecimalPipe } from "@angular/common";
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Observable } from "rxjs";
import { AppService } from "src/app/_services/appService/app.service";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directives/NgbdSortableHeader";
// import { TableService } from "src/app/shared/service/table.service";
import { UserListDB, USERLISTDB } from "src/app/shared/tables/list-users";

@Component({
  selector: "app-list-user",
  templateUrl: "./list-user.component.html",
  styleUrls: ["./list-user.component.scss"],
  // providers: [TableService, DecimalPipe],
})
export class ListUserComponent implements OnInit {
  public user_list = [];
  userListLength: any;
  page: number = 1;
  pageSize: number = 25;
  // public tableItem$: Observable<UserListDB[]>;
  // public searchText;
  // total$: Observable<number>;

  constructor(private appSrvice: AppService) {}

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  // onSort({ column, direction }: SortEvent) {
  //   // resetting other headers
  //   this.headers.forEach((header) => {
  //     if (header.sortable !== column) {
  //       header.direction = "";
  //     }
  //   });

  //   this.service.sortColumn = column;
  //   this.service.sortDirection = direction;
  // }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers() {
    this.appSrvice.getAllUserList().subscribe((res: any) => {
      this.userListLength = res.length;
      this.user_list = res
        .map((result: any, index: any) => {
          return {
            ...result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );

      console.log("resUesr", this.user_list);
    });
  }
}
