import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Router } from "@angular/router";
import { CreateProduct } from "src/app/_models/product/createProduct";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { ProductService } from "src/app/_services/product/product.service";
import Swal from "sweetalert2";
import { ShopService } from "src/app/_services/shop/shop.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.scss"],
})
export class AddProductComponent implements OnInit {
  public addProductForm: FormGroup;
  public Editor = ClassicEditor;
  public counter: number = 1;
  public url = [
    {
      img: "assets/images/user.png",
    },
    {
      img: "assets/images/user.png",
    },
    {
      img: "assets/images/user.png",
    },
    {
      img: "assets/images/user.png",
    },
    {
      img: "assets/images/user.png",
    },
  ];
  categoryList: any[] = [];
  shopCategoryList: any[] = [];
  shopList: any[] = [];
  subCategoryList: any[] = [];
  catId: number;
  selectShopId: any;
  token: any;
  shopName: any;
  showError = false;
  formSubmitted: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private categoryService: CategoryServiceService,
    private router: Router,
    private shopService: ShopService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.addProductForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      // slug: ["", [Validators.required]],
      brand: [""],
      shopId: ["", Validators.required],
      // shopCategoryId: ["", [Validators.required]],
      categoryId: ["", [Validators.required]],
      subCategoryId: ["", [Validators.required]],
      shortDesc: ["", [Validators.required]],
      sku: [""],
      upc: [""],
      taxCode: ["", [Validators.required]],
      // stockTotal: ["", [Validators.required]],
      // price: ["", [Validators.required]],
      description: [""],
      additionalInfo: [""],
      shippingReturn: [""],
    });
    this.addProductForm.controls["shopId"].setValue(0);
    // this.addProductForm.controls["shopCategoryId"].setValue(0);
    this.addProductForm.controls["categoryId"].setValue(0);
    this.addProductForm.controls["subCategoryId"].setValue(0);
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  //FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    };
  }

  ngOnInit() {
    this.getAllCategories();
    // this.token = sessionStorage.getItem("user");
    // console.log("this.token", this.token);
    // if (this.token) {
    // }
    this.getAllShops();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  getAllCategories() {
    this.categoryService.getAllCategory().subscribe((res: any) => {
      this.categoryList = res.categoryList;
      console.log("this.categories", this.categoryList);
    });
  }
  getAllShops() {
    this.shopService.getAllShops().subscribe((response: any) => {
      this.shopList = response;
      console.log("shop", this.shopList);
    });
  }
  onSelectShop() {
    this.selectShopId = this.addProductForm.value["shopId"];
    // console.log("this.selectShopId", this.selectShopId);
    let shop = this.shopList.filter((res: any) => {
      // console.log("res", res);

      return res.shopId == this.selectShopId;
    });
    // console.log("shop", shop);
    this.shopName = shop[0].shopName;
    console.log("shop", this.shopName);
    //   this.shopService
    //     .getShopCategoryByShopId(this.selectShopId)
    //     .subscribe((response: any) => {
    //       this.shopCategoryList = response;
    //       console.log("shopCategoryList", response);
    //     });
  }
  onSelectCategory() {
    this.catId = this.addProductForm.value["categoryId"];
    this.categoryService
      .getSubCategoryByCategoryId(this.catId)
      .subscribe((res: any) => {
        this.subCategoryList = res;
        console.log("subCategoryList", this.subCategoryList);
      });
  }
  createProduct() {
    this.formSubmitted = true;
    if (
      this.addProductForm.invalid ||
      this.addProductForm.value["shopId"] == 0 ||
      this.addProductForm.value["categoryId"] == 0 ||
      this.addProductForm.value["subCategoryId"] == 0
    ) {
      this.toastr.error("Please fill up the mandatory fields (marked in *)");
      // this.formSubmitted = false;
      return;
    }
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd: CreateProduct = {
          name: this.addProductForm.value["name"],
          // slug: this.addProductForm.value["slug"],
          brand:
            this.addProductForm.value["brand"] == ""
              ? this.shopName
              : this.addProductForm.value["brand"],
          shopId: this.addProductForm.value["shopId"],
          shopCategoryId: 0,
          categoryId: this.addProductForm.value["categoryId"],
          subCategoryId: this.addProductForm.value["subCategoryId"],
          shortDesc: this.addProductForm.value["shortDesc"],
          stockTotal: 100,
          status: "unpublished",
          top: false,
          featured: false,
          newProduct: false,
          author: "Seller Name",
          soldout: false,
          sku: this.addProductForm.value["sku"] || "N/A",
          upc: this.addProductForm.value["upc"] || "N/A",
          taxCode: this.addProductForm.value["taxCode"],
          // sku: this.addProductForm.value["sku"],
          price: 0,
          description: this.addProductForm.value["description"] || "N/A",
          additionalInfo: this.addProductForm.value["additionalInfo"] || "N/A",
          shippingReturn: this.addProductForm.value["shippingReturn"] || "N/A",
          isActive: true,

          //       {
          // "id": "string",
          // "name": "string",
          // "brand": "string",
          // "shopId": "string",
          // "shopCategoryId": "string",
          // "categoryId": "string",
          // "subCategoryId": "string",
          // "shortDesc": "string",
          // "stockTotal": "string",
          // "status": "string",
          // "top": true,
          // "featured": true,
          // "newProduct": true,
          // "author": "string",
          // "soldout": true,
          // "description": "string",
          // "additionalInfo": "string",
          // "is_active": true,
          // "price": 0,
          // "_active": true
          // }
        };
        console.log("fd", fd);
        let tax_code = this.addProductForm.value["taxCode"];
        this.productService.taxCodeValidate(tax_code).subscribe((res: any) => {
          console.log("tax", res);
          if (res.status == 0) {
            this.productService.createProduct(fd).subscribe((response: any) => {
              console.log("product", response);
              let productId = response.productId;
              this.formSubmitted = false;
              this.showSpinner();
              Swal.fire("Product Created Succesfully!", "", "success");
              this.router.navigate(["/products/upload-pictures", productId]);
              // this.onDiscard();
            });
          } else {
            this.toastr.error(res.statusDesc);
          }
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onDiscard() {
    this.addProductForm.reset();
    this.addProductForm.controls["shopId"].setValue(0);
    // this.addProductForm.controls["shopCategoryId"].setValue(0);
    this.addProductForm.controls["categoryId"].setValue(0);
    this.addProductForm.controls["subCategoryId"].setValue(0);
  }

  // scrollToForm() {
  //   const formElement = document.querySelector(".add-product-form");
  //   if (formElement) {
  //     formElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // }

  toNavigate() {
    this.router.navigateByUrl(`/products/shopify-connect`);
  }

  get f() {
    return this.addProductForm.controls;
  }

  get name() {
    return this.addProductForm.get("name");
  }
  get slug() {
    return this.addProductForm.get("slug");
  }
  get brand() {
    return this.addProductForm.get("brand");
  }
  get shopId() {
    return this.addProductForm.get("shopId");
  }
  // get shopCategoryId() {
  //   return this.addProductForm.get("shopCategoryId");
  // }
  get categoryId() {
    return this.addProductForm.get("categoryId");
  }
  get subCategoryId() {
    return this.addProductForm.get("subCategoryId");
  }
  get stockTotal() {
    return this.addProductForm.get("stockTotal");
  }
  get shortDesc() {
    return this.addProductForm.get("shortDesc");
  }
  get sku() {
    return this.addProductForm.get("sku");
  }
  get price() {
    return this.addProductForm.get("price");
  }
  get taxCode() {
    return this.addProductForm.get("taxCode");
  }
}
