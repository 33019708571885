<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <div class="row products-admin ratio_asos">
    <div class="col-xl-3 col-sm-6 mb-2" *ngFor="let product of product_list">
      <div class="card body">
        <div class="card-body product-box">
          <div class="img-wrapper">
            <div class="lable-block">
              <span class="lable3" *ngIf="product.productNew == 'true'"
                >new</span
              >
              <span class="lable4" *ngIf="product.sale == 'on sale'"
                >on sale</span
              >
            </div>
            <div class="front">
              <a href="javascript:void(0)"
                ><img
                  [src]="[product?.smPictures[0].url]"
                  class="img-fluid blur-up lazyload bg-img"
                  alt=""
              /></a>
              <div class="product-hover">
                <ul>
                  <!-- <li>
                    <button
                      class="btn"
                      type="button"
                      data-original-title=""
                      title=""
                    >
                      <i class="icon-pencil-alt"></i>
                    </button>
                  </li> -->
                  <li>
                    <button
                      class="btn"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      data-original-title=""
                      title=""
                      (click)="onDeleteClick(product?.id)"
                    >
                      <i class="icon-trash"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="product-detail">
            <div class="rating">
              <i class="fa fa-star"></i> <i class="fa fa-star"></i>
              <i class="fa fa-star"></i> <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div>
            <a [routerLink]="['/products/product-detail/', product?.id]">
              <h6>{{ product?.name }}</h6>
            </a>
            <h4>
              {{ product.saleprice > 0 ? product.saleprice : product.price }}
              <del *ngIf="product.saleprice">{{ product.price }}</del>
            </h4>
            <ul class="color-variant">
              <li
                *ngFor="let variant of product.variants"
                [ngStyle]="{ 'background-color': variant.color }"
              ></li>
            </ul>
          </div>
        </div>
        <!-- <button type="button" class="btn btn-danger mt-2">Publish</button> -->
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center p-2">
    <ngb-pagination
      [(page)]="page"
      [pageSize]="pageSize"
      [collectionSize]="totalCount"
      (pageChange)="onPageChange($event)"
    >
    </ngb-pagination>
  </div>
</div>
