<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Add/Update Delivery Details</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-7">
              <form
                class="needs-validation"
                [formGroup]="addAdminDeliveryForm"
                (ngSubmit)="onAddDelivery()"
              >
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-4 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Select deliveryFee Type :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="deliveryFeeType"
                      >
                        <option [ngValue]="0">Select DeliveryFee Type</option>
                        <option
                          *ngFor="let feeType of feeTypeList"
                          [value]="feeType.name"
                        >
                          {{ feeType.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          deliveryFeeType &&
                          deliveryFeeType.invalid &&
                          (deliveryFeeType.dirty ||
                            deliveryFeeType.touched ||
                            !deliveryFeeType.value)
                        "
                        class="text text-danger"
                      >
                        DeliveryFee Type is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-4 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Delivery Fee :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom02"
                        type="text"
                        placeholder="Enter DeliveryFee Value Here"
                        formControlName="deliveryFeeValue"
                      />
                      <div
                        *ngIf="
                          deliveryFeeValue &&
                          deliveryFeeValue.invalid &&
                          (deliveryFeeValue.dirty || deliveryFeeValue.touched)
                        "
                        class="text text-danger"
                      >
                        Delivery Fee is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-sm-8 offset-xl-3 offset-sm-4">
                    <div class="product-buttons">
                      <button
                        type="submit"
                        value="submit"
                        [disabled]="addAdminDeliveryForm.invalid"
                        class="btn btn-primary me-1"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        (click)="onDiscard()"
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Delivery Details</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-7">
              <form class="needs-validation">
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-4 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >DeliveryFee Type :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      {{ deliveryInfo?.deliveryFeeType }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-4 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >DeliveryFee Value :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      {{ deliveryInfo?.deliveryFeeValue }}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
