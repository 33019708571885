import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ProductService } from "src/app/_services/product/product.service";
import { ShopService } from "src/app/_services/shop/shop.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-edit-section",
  templateUrl: "./edit-section.component.html",
  styleUrls: ["./edit-section.component.scss"],
})
export class EditSectionComponent implements OnInit {
  sectionId: any;
  editSectionForm: any;
  shopList: any;
  savedData: any = [];
  productLists: any;
  originalProductList: any;
  prodSavedData: any[] = [];
  productsSelected: any[] = [];
  dropdownSettings = {
    singleSelection: false,
    idField: "shopId",
    textField: "shopName",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  dropdownSettings3 = {
    singleSelection: false,
    idField: "productId",
    textField: "productName",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
    defaultOpen: true,
  };
  sectionData: any;
  editStore: boolean = false;
  editProduct: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private shopService: ShopService
  ) {
    this.editSectionForm = this.formBuilder.group({
      sectionName: [""],
      index: [""],
      storeList: [""],
      productList: [""],
      // sectionType: [""],
      //  isProduct: [""],
      //  isCategory: [""],
      //  isOffer: [""],
      //  isStoreList: [""],
      //  storeList: [""],
      //  categories: [""],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((param: any) => {
      this.sectionId = param.get("id");
      console.log("sectionId", this.sectionId);
      this.getSectionDeatilsById(this.sectionId);
    });
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  trackByFn(index: number, item: any): any {
    return item.id; // Use a unique identifier of the item
  }
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.productsSelected,
      event.previousIndex,
      event.currentIndex
    );
    console.log("sortedArray", this.productsSelected);

    // newList = Array.from(newList)
    // this.prodSavedData = newList;
    console.log("sortedArray2", this.prodSavedData);
  }

  getAllShops() {
    this.shopService.getAllShops().subscribe((response: any) => {
      // this.shopListLength = response.length;
      this.shopList = response;
      console.log("this.shopList", this.shopList);
    });
  }
  getAllProducts() {
    this.productService.fetchAllProductsForHomePage().subscribe((res: any) => {
      this.productLists = res.map((result: any) => {
        return {
          ...result,
          productName: result?.name,
        };
      });
      this.originalProductList = this.productLists;
      console.log("this.productLists", this.productLists);
    });
  }

  getSectionDeatilsById(id: any) {
    this.productService
      .fetchSectionsDataBySectionId(id)
      .subscribe((result: any) => {
        console.log("result", result);
        this.sectionData = result;

        if (result?.isStoreList) {
          this.getAllShops();
          this.editStore = true;
          result?.storeList.forEach((item: any) => {
            this.savedData.push(item?.shopId.toString());
          });
          this.editSectionForm.patchValue({
            sectionName: result?.sectionName || "",
            index: result?.index || "",
            storeList: result?.storeList,
            //  location: this.tastingRommAddress || "",
          });
        }
        if (result?.isProduct) {
          this.getAllProducts();
          this.editProduct = true;
          this.productsSelected = result?.productList;
          this.editSectionForm.patchValue({
            sectionName: result?.sectionName || "",
            index: result?.index || "",
            productList: result?.productList,
            // storeList: result?.storeList,
            //  location: this.tastingRommAddress || "",
          });
        } else {
          this.editSectionForm.patchValue({
            sectionName: result?.sectionName || "",
            index: result?.index || "",
            // storeList: result?.storeList,
            //  location: this.tastingRommAddress || "",
          });
        }
      });
  }

  onItemSelect(item: any) {
    this.savedData.push(item.shopId?.toString());
    this.savedData = Array.from(new Set(this.savedData));
    console.log("saved", this.savedData);
  }

  onItemDeSelect(item: any) {
    console.log("deselctitem", item);

    let filteredData = this.savedData.filter(
      (data) => item.shopId?.toString() != data
    );
    this.savedData = filteredData;
    console.log("deselct", this.savedData);
  }

  editSection() {
    // const d = new Date();
    // // let time = d.getTime();
    // this.time = d.getTime().toString();
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.sectionData.isStoreList) {
          let fd = {
            sectionName: this.editSectionForm.value["sectionName"],
            sectionType: "Store List",
            index: this.editSectionForm.value["index"],
            identifier: this.sectionData?.identifier,
            sectionId: this.sectionId?.toString(),
            isProduct: false,
            isCategory: this.sectionData?.isCategory,
            isOffer: this.sectionData?.isOffer,
            isStoreList: this.sectionData?.isStoreList,
            isStoreOffer: this.sectionData?.isStoreOffer,
            storeList: this.savedData,
            productList: [],
            categories: [],
          };
          console.log("fd", fd);
          this.productService.editsectionnew(fd).subscribe((response: any) => {
            console.log("section", response);
            this.showSpinner();
            this.onDiscard();
            // this.formSubmitted = false;
            Swal.fire("Section Updated Succesfully!", "", "success");
            this.router.navigate(["/home-page/add-sections"]);
            // this.onDiscard();
          });
        } else if (this.sectionData.isProduct) {
          let fd = {
            sectionName: this.editSectionForm.value["sectionName"],
            sectionType: "Product",
            index: this.editSectionForm.value["index"],
            identifier: this.sectionData?.identifier,
            sectionId: this.sectionId?.toString(),
            isProduct: this.sectionData?.isProduct,
            isCategory: this.sectionData?.isCategory,
            isOffer: this.sectionData?.isOffer,
            isStoreList: this.sectionData?.isStoreList,
            isStoreOffer: this.sectionData?.isStoreOffer,
            storeList: [],
            productList: this.productsSelected,
            categories: [],
          };
          console.log("fd", fd);
          this.productService.editsectionnew(fd).subscribe((response: any) => {
            console.log("section", response);
            this.showSpinner();
            this.onDiscard();
            // this.formSubmitted = false;
            Swal.fire("Section Updated Succesfully!", "", "success");
            this.router.navigate(["/home-page/add-sections"]);
            // this.onDiscard();
          });
        } else {
          let fd = {
            sectionName: this.editSectionForm.value["sectionName"],
            // sectionType: this.addSectionForm.value["sectionType"],
            index: this.editSectionForm.value["index"],
            sectionId: this.sectionId.toString(),
            // isProduct: this.isProduct,
            // isCategory: this.isCat,
            // isOffer: this.isOffer,
            // isStoreList: this.isStore,
            // storeList: this.savedData,
            // productList: this.prodSavedData,
            // categories: this.catSavedData,
          };
          console.log("fd", fd);
          // return;
          this.productService.editsection(fd).subscribe((response: any) => {
            console.log("section", response);
            this.showSpinner();
            this.onDiscard();
            // this.formSubmitted = false;
            Swal.fire("Section Updated Succesfully!", "", "success");
            this.router.navigate(["/home-page/add-sections"]);
            // this.onDiscard();
          });
        }
        // return;
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onDiscard() {
    this.editSectionForm.reset();
    this.router.navigate(["/home-page/add-sections"]);
  }

  onProdSelect(item: any) {
    this.prodSavedData.push(item.productId?.toString());
    this.productsSelected.push(item);
    this.prodSavedData = Array.from(new Set(this.prodSavedData));
    this.productsSelected = Array.from(new Set(this.productsSelected));
    console.log("saved", this.prodSavedData);
    console.log("productsSelected", this.productsSelected);
  }

  onProdDeSelect(item: any) {
    console.log("deselctitem", item.productId?.toString());

    let filteredData = this.prodSavedData.filter(
      (data) => item.productId != data
    );

    let filteredData2 = this.productsSelected.filter(
      (data) => item.productId != data.productId
    );
    this.prodSavedData = filteredData;
    this.productsSelected = filteredData2;
    console.log("deselct", this.prodSavedData);
  }

  onInput(event: any) {
    console.log("e", event.target.value);
    if (event.target.value?.length > 2) {
      this.productLists = this.originalProductList.filter((item: any) => {
        return JSON.stringify(event.target.value)
          .toLowerCase()
          .includes(item.productId);
      });
    } else this.productLists = this.originalProductList;
  }
}
