import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from "src/app/_services/appService/app.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-seller-crm",
  templateUrl: "./seller-crm.component.html",
  styleUrls: ["./seller-crm.component.scss"],
})
export class SellerCrmComponent implements OnInit {
  addSellerCrmForm: FormGroup;
  // isChecked: any;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private appService: AppService,
    private router: Router
  ) {
    this.addSellerCrmForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phone: [""],
      info: [""],
      address: [""],
    });
  }

  ngOnInit(): void {}

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  addCrm() {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          firstName: this.addSellerCrmForm.value["firstName"],
          lastName: this.addSellerCrmForm.value["lastName"],
          sellerEmail: this.addSellerCrmForm.value["email"],
          sellerMobile: this.addSellerCrmForm.value["phone"].toString(),
          sellerInformation: this.addSellerCrmForm.value["info"],
          sellerAddress: this.addSellerCrmForm.value["address"],
        };
        console.log("fd", fd);
        this.router.navigate(["/seller-crm/crm-list"]);
        // return;
        this.appService.createCrm(fd).subscribe((response: any) => {
          console.log("crm", response);
          this.showSpinner();
          Swal.fire("Seller CRM Created Succesfully!", "", "success");
          this.onDiscard();
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onDiscard() {
    this.addSellerCrmForm.reset();
  }

  get firstName() {
    return this.addSellerCrmForm.get("firstName");
  }

  get lastName() {
    return this.addSellerCrmForm.get("lastName");
  }

  get email() {
    return this.addSellerCrmForm.get("email");
  }
}
