import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { User } from "../../_models/auth/user";
import { SessionManagerService } from "../session/session-manager.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  api_url = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private sessionManager: SessionManagerService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    console.log(
      "Current user value in Login : " + this.currentUserSubject.value
    );
    return this.currentUserSubject.value;
  }

  login(userName: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/loginSeller`, { userName, password })
      .pipe(
        map(
          (user) => {
            console.log("uesr", user);

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('token', JSON.stringify(user.token));
            if (user.status == 0) {
              console.log(user.token);
              this.sessionManager.setToken(user.token);
              sessionStorage.setItem("user", user.token);
              let userInfo = this.sessionManager.getUserInfo();
              this.sessionManager.setUserInfo(userInfo);
              this.currentUserSubject.next(user);
            }
            return user;
          },
          (error) => {
            console.log("er", error);
          }
        )
      );
  }

  // POST
  // /v1/registerSeller

  register(
    userName: string,
    password: string,
    fullName: string,
    email: string
  ) {
    return this.http.post<any>(`${environment.apiUrl}/v1/registerSeller`, {
      userName,
      password,
      fullName,
      email,
    });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("token");
    sessionStorage.removeItem("user");
    localStorage.clear();
    this.currentUserSubject.next(null);
  }
  //   GET
  // /v1/verifySeller/{userId}
  verifySeller(id: any) {
    return this.http.get(this.api_url + "/v1/verifySeller/" + id);
  }
  //   GET
  // /v1/getSellerDetailsByToken
  getSellerDetailsByToken() {
    return this.http
      .get(this.api_url + "/v1/getSellerDetailsByToken")
      .pipe(retry(1), catchError(this.handleError));
  }

  // PUT
  // /v1/updateSellerProfile
  updateSellerProfile(data: any) {
    return this.http
      .put(this.api_url + "/v1/updateSellerProfile", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  forgetPassword(email: any) {
    return this.http
      .post(`${this.api_url}/v1/forgetPassword`, email)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/changePasswordSeller
  changePassword(data: any) {
    return this.http
      .post(`${this.api_url}/v1/changePasswordSeller`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
