import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListVendorsComponent } from "./list-vendors/list-vendors.component";
import { CreateVendorsComponent } from "./create-vendors/create-vendors.component";
import { ShopListComponent } from "./shop-list/shop-list.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "list-vendors",
        component: ListVendorsComponent,
        data: {
          title: "Vendor List",
          breadcrumb: "Vendor List",
        },
      },
      {
        path: "create-vendors",
        component: CreateVendorsComponent,
        data: {
          title: "Create Vendor",
          breadcrumb: "Create Vendor",
        },
      },
      {
        path: "list-shops",
        component: ShopListComponent,
        data: {
          title: "Shop List",
          breadcrumb: "Shop List",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VendorsRoutingModule {}
