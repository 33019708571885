<div class="container">
  <div class="container mb-2 myclass">
    <h2 class="text-danger">Something wrong !</h2>
    <img
      class="myImg"
      src="../../../../assets/images/d01747c4285afa4e7a6e8656c9cd60cb.png"
      alt=""
    />
  </div>
  <h2 class="text-center text-danger">Please Try Again Later.</h2>
  <!-- <marquee class="text-danger" behavior="" direction=""
    >This page will redirect to LOGIN in 10 sec</marquee
  > -->
</div>
