import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { flash } from "ng-animate";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LocationService } from "src/app/_services/location/location.service";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { ShopService } from "src/app/_services/shop/shop.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-add-shop-details",
  templateUrl: "./add-shop-details.component.html",
  styleUrls: ["./add-shop-details.component.scss"],
})
export class AddShopDetailsComponent implements OnInit {
  addShopDetailsForm: any;
  allDaysOriginal = [];
  allDays: any = [
    {
      day: "Monday",
      openingTime: "",
      closingTime: "",
      isClosed: false,

      isEditable: true,
    },
    {
      day: "Tuesday",
      openingTime: "",
      closingTime: "",
      isClosed: false,

      isEditable: true,
    },
    {
      day: "WednesDay",
      openingTime: "",
      closingTime: "",
      isClosed: false,

      isEditable: true,
    },
    {
      day: "Thursday",
      openingTime: "",
      closingTime: "",
      isClosed: false,

      isEditable: true,
    },
    {
      day: "Friday",
      openingTime: "",
      closingTime: "",
      isClosed: false,

      isEditable: true,
    },
    {
      day: "Saturday",
      openingTime: "",
      closingTime: "",
      isClosed: false,
      isEditable: true,
    },
    {
      day: "Sunday",
      openingTime: "",
      closingTime: "",
      isClosed: false,
      isEditable: true,
    },
  ];
  shopId: any;
  selectShopForm: any;
  shopList: any;
  path: string;
  constructor(
    private formBuilder: FormBuilder,
    private shopService: ShopService,
    private locationService: LocationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sessionManager: SessionManagerService,
    private toastr: ToastrService,
    public activeRoute: ActivatedRoute
  ) {
    this.selectShopForm = this.formBuilder.group({
      shopId: [""],
    });
    this.selectShopForm.controls["shopId"].setValue(0);
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((param: any) => {
      this.shopId = param.get("id") || 0;
      this.path = this.activeRoute.snapshot.paramMap.get("path");
      this.getAllShops(this.shopId);
      // this.selectShopForm.get("shopId").setValue(this.shopId);
    });
    // this.shopId = "43";
    if (this.shopId != 0) {
      this.getShopDayTime();
    }
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  getShopDayTime() {
    this.shopService.getShopDayTime(this.shopId).subscribe((result: any) => {
      this.allDaysOriginal = result;
      if (result?.length) {
        this.allDays = result.map((res: any) => {
          return {
            ...res,

            isEditable: false,
          };
        });
      }

      console.log("res", result);
    });
  }

  onSubmit() {
    let count = 0;
    this.allDays.forEach((ad) => {
      console.log("ad", ad, count);

      if ((ad.openingTime && ad.closingTime) || ad.isClosed) {
        count = count + 1;
      }
    });
    if (count !== this.allDays.length)
      return this.toastr.error("add valid data");

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd: any = [{}];

        console.log("fd", fd);
        this.shopService
          .postShopDayTime(this.shopId, this.allDays)
          .subscribe((response: any) => {
            console.log("shopRes", response);
            this.showSpinner();

            if (response.status == "0") {
              Swal.fire("Shop Time Updated Succesfully!", "", "success");
              if (this.path == "create") {
                this.router.navigate(["/shop/shop-category", this.shopId]);
              } else {
                this.router.navigate(["/shop/shop-list"]);
              }
            } else {
              this.toastr.error(response.response.statusDesc);
              // this.toastr.error("Something Went Wrong ! Please Try Again");
            }

            // this.onDiscard();
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  getAllShops(shopId?) {
    this.shopService.getAllShops().subscribe((res: any) => {
      this.shopList = res;
      this.selectShopForm.get("shopId").setValue(shopId);
      console.log("this.shopList", this.shopList);
    });
  }

  onSelectShop() {
    this.shopId = this.selectShopForm.value["shopId"];
    this.getShopDayTime();
  }
}
