import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ShopService } from "src/app/_services/shop/shop.service";

@Component({
  selector: "app-shop-list",
  templateUrl: "./shop-list.component.html",
  styleUrls: ["./shop-list.component.scss"],
})
export class ShopListComponent implements OnInit {
  shopListLength: any;
  constructor(
    private shopService: ShopService,
    private spinner: NgxSpinnerService
  ) {}
  shopList: any[] = [];
  page: number = 1;
  pageSize: number = 25;
  ngOnInit(): void {
    this.showSpinner();
    this.getAllShops();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  getAllShops() {
    this.shopService.getAllShops().subscribe((response: any) => {
      this.shopListLength = response.length;
      this.shopList = response
        .map((result: any, index: any) => {
          return {
            ...result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
      console.log("shop", this.shopList);
    });
  }
}
