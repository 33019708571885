import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AnyARecord } from "dns";
import { catchError, retry, throwError } from "rxjs";
import { CreateShop } from "src/app/_models/shop/createShop";
import { ShopCategory } from "src/app/_models/shop/shopCategory";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ShopService {
  api_url = environment.apiUrl;
  constructor(private http: HttpClient) {}

  //   POST
  // /v1/createShop
  createShop(ShopEntity: CreateShop) {
    return this.http
      .post(`${this.api_url}/v1/createShop`, ShopEntity)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/getAllShops
  getAllShops() {
    return this.http
      .get(this.api_url + "/v1/getAllShops")
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/getShopSBySellerId/{sellerId}
  getShopSBySellerId(sellerId: any) {
    return this.http
      .get(this.api_url + "/v1/getShopSBySellerId/" + sellerId)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/mapShopCategory
  createShopCategoryEntity(CategoryEntity: ShopCategory) {
    return this.http
      .post(`${this.api_url}/v1/mapShopCategory`, CategoryEntity)
      .pipe(retry(1), catchError(this.handleError));
  }
  // GET
  // /v1/getShopCategoryByShopId/{shopId}

  getShopCategoryByShopId(shopid: any) {
    return this.http.get(
      this.api_url + "/v1/getShopCategoryByShopId/" + shopid
    );
  }

  // GET
  // /v1/shopDayTime/{shopId}
  getShopDayTime(shopId: any) {
    return this.http
      .get(this.api_url + "/v1/shopDayTime/" + shopId)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/shopDayTime/{shopId}

  postShopDayTime(shopId: any, payload: any) {
    return this.http
      .post(`${this.api_url}/v1/shopDayTime/${shopId}`, payload)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   PUT
  // /v1/editShop

  editShop(shopDetails: any) {
    return this.http
      .put(`${this.api_url}/v1/editShop`, shopDetails)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
