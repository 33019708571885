<!-- Container-fluid starts-->
<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <div class="row mb-3">
    <div class="col-md-12 collapse" id="collapseExample">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Edit Shop</h4>
        </div>
        <div class="card-body">
          <div class="basic-form">
            <form [formGroup]="editShopForm" (ngSubmit)="onSubmit()">
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-3">
                    <label for="validationCustom01" class="mb-0"
                      >Shop Name :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-8">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      formControlName="shopName"
                    />
                    <!-- <div
                      *ngIf="
                        accountNumber &&
                        accountNumber.invalid &&
                        (accountNumber.dirty || accountNumber.touched)
                      "
                      class="text text-danger"
                    >
                      Account Number is required.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Shop Contact :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-8">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      formControlName="phoneNumber"
                    />
                    <!-- <div
                      *ngIf="
                        routingNumber &&
                        routingNumber.invalid &&
                        (routingNumber.dirty || routingNumber.touched)
                      "
                      class="text text-danger"
                    >
                      Routing Number is required.
                    </div> -->
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-3 col-sm-3">
                    <label for="validationCustom01" class="mb-0"
                      >Shop Address:</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      id="validationCustom01"
                      formControlName="shopAddress"
                    />
                    <!-- <div
                      *ngIf="
                        email && email.invalid && (email.dirty || email.touched)
                      "
                      class="text text-danger"
                    >
                      Email-Id is required.
                    </div> -->
                    <div
                      *ngIf="
                        editShopForm.get('shopAddress').hasError('specialChars')
                      "
                      class="text-success"
                    >
                      Special characters are not allowed.
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Closing Days :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <ng-multiselect-dropdown
                      [ngModelOptions]="{ standalone: true }"
                      [placeholder]="'Days of Week'"
                      [settings]="dropdownSettings"
                      [data]="dropdownList"
                      [(ngModel)]="selectedItems"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div> -->
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Available PickupTime(From) :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="time"
                      placeholder="Enter Shop available pickup time Here"
                      formControlName="availablePickupTime"
                    />
                    <!-- <div
                      *ngIf="
                        availablePickupTime &&
                        availablePickupTime.invalid &&
                        (availablePickupTime.dirty ||
                          availablePickupTime.touched)
                      "
                      class="text text-danger"
                    >
                      Available PickupTime(from) is required.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Available PickupTime(To) :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="time"
                      placeholder="Enter Shop available pickup time Here"
                      formControlName="availablePickupTimeTo"
                    />
                    <!-- <div
                      *ngIf="
                        availablePickupTimeTo &&
                        availablePickupTimeTo.invalid &&
                        (availablePickupTimeTo.dirty ||
                          availablePickupTimeTo.touched)
                      "
                      class="text text-danger"
                    >
                      Available PickupTime(To) is required.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Select deliveryFee Type :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="deliveryFeeType"
                    >
                      <option [ngValue]="0">Select DeliveryFee Type</option>
                      <option
                        *ngFor="let feeType of feeTypeList"
                        [value]="feeType.name"
                      >
                        {{ feeType.name }}
                      </option>
                    </select>
                    <!-- <div
                      *ngIf="
                        deliveryFeeType &&
                        deliveryFeeType.invalid &&
                        (deliveryFeeType.dirty || deliveryFeeType.touched || !deliveryFeeType.value)
                      "
                      class="text text-danger"
                    >
                      Shop TimeZone is required.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Delivery Fee :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      placeholder="Enter DeliveryFee Value Here"
                      formControlName="deliveryFeeValue"
                    />
                    <!-- <div
                      *ngIf="
                        phoneNumber &&
                        phoneNumber.invalid &&
                        (phoneNumber.dirty || phoneNumber.touched)
                      "
                      class="text text-danger"
                    >
                      Contact Number is required.
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="col-sm-8 offset-xl-3 offset-sm-4">
                  <div class="product-buttons">
                    <button
                      *ngIf="!updateClick"
                      type="submit"
                      class="btn btn-primary me-1"
                    >
                      Update
                    </button>
                    <button
                      *ngIf="updateClick"
                      class="btn btn-primary me-1"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      updating...
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      (click)="onCancel()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h5>Shops List</h5>
    </div>
    <div class="card-body vendor-table text-center">
      <div id="batchDelete" class="custom-datatable vendor-list">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Shop Name</th>
                <!-- <th scope="col">Seller Name</th> -->
                <th scope="col">Contact</th>
                <th scope="col">Pickup Time</th>
                <th scope="col">DeliveryFee Type</th>
                <th scope="col">Delivery Fee</th>
                <th scope="col">Address</th>
                <th scope="col">Action</th>
                <!-- <th scope="col">Status</th> -->
                <!-- <th scope="col"  >Wallet Balance</th>
                                    <th scope="col" >Revenue</th>
                                    <th scope="col" >Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let shops of shopList; let i = index">
                <td scope="row">
                  <!-- <img [src]="item.vendor" class="imgTable" style="width: 20px">
                                       <span>{{item.name}}</span>  -->
                  {{ i + 1 }}
                </td>
                <td>
                  {{ shops?.shopName }}
                </td>
                <!-- <td>
                  {{ shops?.sellerName }}
                </td> -->
                <td>{{ shops?.phoneNumber ? shops?.phoneNumber : "N/A" }}</td>

                <td>
                  {{
                    shops?.availablePickupTime
                      ? shops?.availablePickupTime +
                        "-" +
                        shops?.availablePickupTimeTo
                      : "N/A"
                  }}
                </td>
                <td>
                  {{ shops?.deliveryFeeType || "N/A" }}
                </td>
                <td>
                  {{ shops?.deliveryFeeValue || "N/A" }}
                </td>

                <td>
                  {{ shops?.shopAddress }}
                </td>
                <!-- <td>{{item.balance}}</td>
                                    <td>{{item.revenue}}</td> -->
                <td>
                  <a
                    type="button"
                    (click)="onEditClick(shops)"
                    #onEdit
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-edit f-12"></i
                  ></a>
                  <!-- &nbsp;
                  <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a> -->
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="d-flex justify-content-center p-2">
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="shopListLength"
              (pageChange)="getAllShops()"
            >
            </ngb-pagination>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
