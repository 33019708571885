<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Add Homepage Section</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <form
              class="needs-validation"
              [formGroup]="editSectionForm"
              (ngSubmit)="editSection()"
            >
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Section Name :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      formControlName="sectionName"
                    />
                    <!--  -->
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Section identifier :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom06"
                      type="text"
                      placeholder="Please Enter Section Identifier here"
                      formControlName="identifier"
                    />
                    <div
                      *ngIf="
                        identifier &&
                        identifier.invalid &&
                        (identifier.dirty || identifier.touched)
                      "
                      class="text text-danger"
                    >
                      Identifier is required.
                    </div>
                  </div>
                </div> -->
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Section Index :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom06"
                      type="text"
                      formControlName="index"
                    />
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Section Type :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="sectionType"
                      (change)="onSelectSection()"
                    >
                      <option [ngValue]="0">Select Section Type</option>
                      <option
                        *ngFor="let section of sectionType"
                        [value]="section.value"
                      >
                        {{ section.value }}
                      </option>
                    </select>
                   
                  </div>
                </div> -->

                <div *ngIf="editStore" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom03" class="mb-0"
                      >Select Stores :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <ng-multiselect-dropdown
                      placeholder=""
                      [data]="shopList"
                      name="shopList"
                      formControlName="storeList"
                      [settings]="dropdownSettings"
                      (onSelect)="onItemSelect($event)"
                      (onDeSelect)="onItemDeSelect($event)"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div *ngIf="editProduct" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom05" class="mb-0"
                      >Select Product :</label
                    >
                  </div>
                  <div id="scrollMe" class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="search"
                      (input)="
                        onInput($event); dropdownSettings3.defaultOpen = true
                      "
                      placeholder="Please Enter search Link"
                    />
                    <ng-multiselect-dropdown
                      placeholder=""
                      [data]="productLists"
                      class="index"
                      name="productList"
                      formControlName="productList"
                      [settings]="dropdownSettings3"
                      (onSelect)="onProdSelect($event)"
                      (onDeSelect)="onProdDeSelect($event)"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>

                <div
                  *ngIf="productsSelected.length >= 2"
                  class="example-container"
                >
                  <h5>Drag & Drop to Sort</h5>

                  <div
                    cdkDropList
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                  >
                    <ng-container
                      *ngFor="let item of productsSelected; trackBy: trackByFn"
                    >
                      <div class="example-box backBg" cdkDrag>
                        {{ item.productName }}
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- <div [ngClass]="{ hidden: !isCat }" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom04" class="mb-0"
                      >Select Category :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <ng-multiselect-dropdown
                      placeholder=""
                      [data]="categories"
                      name="categories"
                      formControlName="categories"
                      [settings]="dropdownSettings2"
                      (onSelect)="onCatSelect($event)"
                      (onDeSelect)="onCatDeSelect($event)"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div> -->

                <!-- <div [ngClass]="{ hidden: !isOffer }" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom04" class="mb-0"
                      >Select Offer Category :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <ng-multiselect-dropdown
                      placeholder=""
                      [data]="categories"
                      name="categories"
                      formControlName="categories"
                      [settings]="dropdownSettings4"
                      (onSelect)="onCatSelect($event)"
                      (onDeSelect)="onCatDeSelect($event)"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div> -->

                <!-- <div [ngClass]="{ hidden: !isProduct }" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom05" class="mb-0"
                      >Select Product :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <ng-multiselect-dropdown
                      placeholder=""
                      [data]="productLists"
                      name="productList"
                      formControlName="productList"
                      [settings]="dropdownSettings3"
                      (onSelect)="onProdSelect($event)"
                      (onDeSelect)="onProdDeSelect($event)"
                    >
                    </ng-multiselect-dropdown>
                  
                  </div>
                </div> -->

                <!-- <label class="toggle-button">
                  <input type="checkbox" [(ngModel)]="isChecked" />
                  <span class="slider round"></span>
                </label> -->
              </div>
              <div class="form-group row mb-0">
                <div class="col-sm-8 offset-xl-3 offset-sm-4">
                  <div class="product-buttons">
                    <button
                      type="submit"
                      class="btn btn-primary me-1"
                      [disabled]="editSectionForm.invalid"
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      (click)="onDiscard()"
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
