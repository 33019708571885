<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>
            Ordered on
            <span>{{
              orderDetails?.order?.createdDate.split("[")[0] | date : "longDate"
            }}</span>
          </h5>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <b>Billing Details</b>
              <p>
                {{
                  orderDetails?.order?.firstName
                    ? orderDetails?.order?.firstName
                    : "N/A"
                }}
                {{
                  orderDetails?.order?.secondName
                    ? orderDetails?.order?.secondName
                    : " "
                }}
                <!-- <br />
                {{
                  orderDetails?.order?.addressLine1
                    ? orderDetails?.order?.addressLine1
                    : "N/A"
                }}<br />
                {{
                  orderDetails?.order?.addressLine2
                    ? orderDetails?.order?.addressLine2
                    : "N/A"
                }}<br />

                {{ orderDetails?.order?.city }}<br />

                {{
                  orderDetails?.order?.state +
                    " ," +
                    orderDetails?.order?.country
                }}
                <br />
                {{ orderDetails?.order?.zipCode }} -->
              </p>
            </div>

            <!-- <div class="col-lg-3 col-md-3">
              <b>Order product id</b>
              <p>
                {{ orderDetails?.orderProduct?.orderProductId }}
              </p>
            </div> -->
            <div class="col-lg-4 col-md-4">
              <b>Note By Customer</b>
              <p>
                {{
                  orderDetails?.order?.note ? orderDetails?.order?.note : "N/A"
                }}
              </p>
            </div>
            <!-- <div class="col-lg-3 col-md-3">
              <b>Order Summary</b>
              <p>
                Item(s) SubTotal :
                <span class="float-right">{{
                  orderDetails?.order?.price
                }}</span>
                <br />
                Shipping :
                <span class="float-right">{{ "0" }}</span>
              </p>
              <p>
                <b>Grand Total :</b>
                <span class="float-right">{{
                  orderDetails?.order?.price
                }}</span>
              </p>
            </div> -->
            <div class="col-lg-4 col-md-4">
              <b>Action/Status</b>
              <!-- class="float-right badge bg-primary" -->
              <p>
                <b>Order product id</b> -
                {{ orderDetails?.orderProduct?.orderProductId }}
              </p>
              <p>
                Status :
                <span
                  class="float-right badge text-light"
                  [ngClass]="{
                    'bg-success':
                      orderDetails?.orderProduct?.status === 'COMPLETE',
                    'bg-warning':
                      orderDetails?.orderProduct?.status === 'READY_FOR_PICKUP',
                    'bg-info':
                      orderDetails?.orderProduct?.status !== 'COMPLETE' &&
                      orderDetails?.orderProduct?.status !== 'READY_FOR_PICKUP'
                  }"
                  >{{ orderDetails?.orderProduct?.status }}</span
                >
                <br />
              </p>
              <div class="col-lg-6 col-md-6">
                <p>
                  <select
                    class="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                    (change)="orderStatusChange($event)"
                  >
                    <option value="0" selected>Change Order Status</option>
                    <!-- <option value="1">PAID</option> -->
                    <option value="1">Ready For Pickup</option>
                    <option value="2">Completed</option>
                  </select>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngFor="
          let orderDetail of orderDetails?.orderProductDetailsList;
          let i = index
        "
        class="card"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-2 col-lg-2">
              <!-- <molla-image
                              [src]="orderDetail.pictures[0].url"
                              alt="Product side"
                              class="product-image"
                            >
                            </molla-image> -->
              <img
                [src]="orderDetail.pictures[0].url"
                class="product-image"
                alt=""
              />
            </div>

            <div class="col-md-6 col-lg-6">
              <p>
                <span class="text-info">{{ orderDetail?.product?.name }}</span>
                <!-- <span class="text-dark pl-2"
                  >X {{ orderDetail?.orderProduct?.quantity }}</span
                > -->
                <br />
                <span>Sold By : {{ orderDetail?.shop?.shopName }}</span
                ><br />
                <span>${{ orderDetail?.orderProduct?.unitPrice }}</span>
              </p>
            </div>
            <div class="col-lg-3 col-md-3">
              <div class="marginTop">
                <p class="text-center"><b>Number of Item Ordered</b></p>
                <p class="text-center">
                  {{ orderDetail?.orderProduct?.quantity }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
