<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h5>User Details</h5>
    </div>
    <div class="card-body">
      <!-- <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Create User</a>
            </div> -->
      <div
        id="batchDelete"
        class="category-table custom-datatable transcation-datatable user-image"
      >
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <!-- <th scope="col">Actions</th> -->
                <th scope="col">#</th>
                <th scope="col">Full Name</th>
                <!-- <th scope="col" sortable="lName">Last Name</th> -->
                <th scope="col">Email</th>
                <th scope="col">Registered Date</th>
                <!-- <th scope="col" sortable="role">Role</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of user_list">
                <!-- <td>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-edit f-12"></i></a
                  >&nbsp;
                  <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                </td> -->
                <td scope="row">
                  <!-- <img
                    [src]="item.avatar"
                    class="imgTable"
                    style="width: 20px"
                  /> -->
                  {{ user?.sl_no }}
                </td>
                <td>
                  {{ user?.fullName }}
                </td>
                <!-- <td>{{ item.lName }}</td> -->
                <td>{{ user?.email }}</td>
                <td>{{ user?.createdDate.split("[")[0] | date : "medium" }}</td>
                <!-- <td>{{ item.role }}</td> -->
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center p-2">
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="userListLength"
              (pageChange)="getAllUsers()"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
