import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { productDB } from "src/app/shared/tables/product-list";
import { ProductService } from "src/app/_services/product/product.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Component({
  selector: "app-admin-product-list",
  templateUrl: "./admin-product-list.component.html",
  styleUrls: ["./admin-product-list.component.scss"],
})
export class AdminProductListComponent implements OnInit {
  public product_list: any;
  loaded: boolean = false;
  totalCount: any = 0;
  page: number = 1;
  pageSize: number = 12;
  perPage: any = 12;
  pageNo: any = 0;

  constructor(
    private productService: ProductService,
    private router: Router,
    public activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    // this.product_list = productDB.product;
  }

  ngOnInit() {
    this.showSpinner();
    this.fetchAllProducts();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  // fetchAllProducts() {
  //   this.productService.fetchAllProducts().subscribe((response: any) => {
  //     this.product_list = response.products;
  //     console.log("product_list", this.product_list);
  //   });
  // }

  // fetchAllProducts() {
  //   this.activeRoute.params.subscribe((param) => {
  //     this.loaded = false;
  //     console.log("param", param);

  //     this.productService
  //       .getStoreList(param, "/v1/storeList", 5)
  //       .subscribe((response: any) => {
  //         this.product_list = response.products;
  //         this.totalCount = response.totalCount;
  //         console.log("productList", response);
  //       });
  //   });
  // }

  fetchAllProducts() {
    this.activeRoute.params.subscribe((param) => {
      this.loaded = false;
      console.log("param", param);
      // storeList?page=0&perPage=12
      this.productService
        .getProductList(
          `/v1/storeList?page=${this.pageNo}&perPage=${this.perPage}`
        )
        .subscribe((response: any) => {
          this.product_list = response.products;
          this.totalCount = response.totalCount;
          console.log("productList", response);
        });
    });
  }

  onPageChange(event: any) {
    console.log("event", event);
    this.pageNo = +event - 1;
    console.log("this.pageNo", this.pageNo);
    this.showSpinner();
    this.fetchAllProducts();
  }
  onProductClick(productId: any) {
    console.log("productId", productId);
    this.router.navigate(["/products/physical/product-detail/:productId"]);
  }

  // onDeleteClick(id: any) {
  //   console.log("id", id);
  //   this.productService.deleteProductByProductId(id).subscribe((res: any) => {
  //     console.log("res", res);
  //     if (res.status == 0) {
  //       this.showSpinner();
  //       this.fetchAllProducts();
  //       this.toastr.success("Product Deleted Successfully");
  //     } else {
  //       this.toastr.error("Something Went Wrong,Try Again");
  //       this.fetchAllProducts();
  //     }
  //   });
  // }

  onDeleteClick(id: any) {
    Swal.fire({
      title: "Do you want to delete the product?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService
          .deleteProductByProductId(id)
          .subscribe((res: any) => {
            console.log("res", res);
            if (res.status == 0) {
              this.showSpinner();
              this.fetchAllProducts();
              this.toastr.success("Product Deleted Successfully");
            } else {
              this.toastr.error("Something Went Wrong,Try Again");
              this.fetchAllProducts();
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
}
