import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from "src/app/_services/appService/app.service";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { ProductService } from "src/app/_services/product/product.service";
import { ShopService } from "src/app/_services/shop/shop.service";
import Swal from "sweetalert2";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from "@angular/cdk/drag-drop";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-sections",
  templateUrl: "./add-sections.component.html",
  styleUrls: ["./add-sections.component.scss"],
})
export class AddSectionsComponent implements OnInit {
  addSectionForm: any;
  savedData: any = [];
  catSavedData: any = [];
  dropdownSettings = {
    singleSelection: false,
    idField: "shopId",
    textField: "shopName",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };

  dropdownSettings1 = {
    singleSelection: false,
    idField: "shopId",
    textField: "shopName",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
    limitSelection: 2,
  };

  dropdownSettings2 = {
    singleSelection: false,
    idField: "id",
    textField: "category",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
    limitSelection: 6,
  };
  dropdownSettings3 = {
    singleSelection: false,
    idField: "productId",
    textField: "name",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
    defaultOpen: true,
  };

  dropdownSettings4 = {
    singleSelection: false,
    idField: "id",
    textField: "category",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
    limitSelection: 2,
  };
  sectionType = [
    { id: 1, value: "Product" },
    { id: 2, value: "Category" },
    { id: 3, value: "Offer" },
    { id: 4, value: "Store List" },
    { id: 5, value: "Shop Offer" },
  ];
  shopList: any;
  categories: any;
  isProduct: boolean = false;
  isCat: boolean = false;
  isStore: boolean = false;
  isOffer: boolean = false;
  productLists: any;
  originalProductList: any;
  prodSavedData: any[] = [];
  time: any;
  sectionListLength: any;
  page: number = 1;
  @ViewChild("searched") searched: ElementRef;
  pageSize: number = 5;
  sectionList: any;
  productsSelected: any[] = [];
  offerActivate: boolean = false;
  isStoreOffer: boolean = false;
  // drop(event: CdkDragDrop<any[]>) {
  //   moveItemInArray(this.vegetables, event.previousIndex, event.currentIndex);
  // }
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private appService: AppService,
    private router: Router,
    private shopService: ShopService,
    private productService: ProductService,
    private categoryService: CategoryServiceService,
    private toastr: ToastrService
  ) {
    this.addSectionForm = this.formBuilder.group({
      sectionName: ["", [Validators.required]],
      sectionType: ["", [Validators.required]],
      index: ["", [Validators.required]],
      isProduct: [""],
      isCategory: [""],
      isOffer: [""],
      isStoreList: [""],
      storeList: [""],
      productList: [""],
      categories: [""],
    });
    this.addSectionForm.controls["sectionType"].setValue(0);
  }

  ngOnInit(): void {
    this.showSpinner();
    this.getAllSections();
    this.getAllCategories();
    this.getAllShops();
    this.getAllProducts();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  getAllShops() {
    this.shopService.getAllShops().subscribe((response: any) => {
      // this.shopListLength = response.length;
      this.shopList = response;
      console.log("this.shopList", this.shopList);
    });
  }

  getAllCategories() {
    this.categoryService.getAllCategory().subscribe((res: any) => {
      console.log("res", res);
      this.categories = res.categoryList;
    });
  }

  onItemSelect(item: any) {
    this.savedData.push(item.shopId?.toString());
    this.savedData = Array.from(new Set(this.savedData));
    console.log("saved", this.savedData);
  }

  onItemDeSelect(item: any) {
    console.log("deselctitem", item);

    let filteredData = this.savedData.filter(
      (data) => item.shopId?.toString() != data
    );
    this.savedData = filteredData;
    console.log("deselct", this.savedData);
  }

  onCatSelect(item: any) {
    this.catSavedData.push(item.id);
    this.catSavedData = Array.from(new Set(this.catSavedData));
    console.log("saved", this.catSavedData);
  }

  onCatDeSelect(item: any) {
    console.log("deselctitem", item);

    let filteredData = this.catSavedData.filter((data) => item.id != data);
    this.catSavedData = filteredData;
    console.log("deselct", this.catSavedData);
  }
  onProdSelect(item: any) {
    this.prodSavedData.push(item.productId?.toString());
    this.productsSelected.push(item);
    this.prodSavedData = Array.from(new Set(this.prodSavedData));
    this.productsSelected = Array.from(new Set(this.productsSelected));
    console.log("saved", this.prodSavedData);
    console.log("productsSelected", this.productsSelected);
  }

  onProdDeSelect(item: any) {
    console.log("deselctitem", item.productId?.toString());

    let filteredData = this.prodSavedData.filter(
      (data) => item.productId != data
    );

    let filteredData2 = this.productsSelected.filter(
      (data) => item.productId != data.productId
    );
    this.prodSavedData = filteredData;
    this.productsSelected = filteredData2;
    console.log("deselct", this.prodSavedData);
  }

  getAllProducts() {
    this.productService.fetchAllProductsForHomePage().subscribe((res: any) => {
      this.productLists = res;
      this.originalProductList = res;
      console.log("this.productLists", this.productLists);
    });
  }

  onDiscard() {
    this.getAllSections();
    this.addSectionForm.reset();
    this.addSectionForm.controls["sectionType"].setValue(0);
    this.catSavedData = [];
    this.prodSavedData = [];
    this.productsSelected = [];
    this.savedData = [];
    this.isStore = false;
    this.offerActivate = false;
    this.isOffer = false;
    this.isCat = false;
    this.isProduct = false;
    this.isStoreOffer = false;
  }
  onSelectSection() {
    let data: any = this.addSectionForm.value["sectionType"];
    console.log(">>>", this.addSectionForm.value["sectionType"]);
    if (data === "Product") {
      this.catSavedData = [];
      this.prodSavedData = [];
      this.productsSelected = [];
      this.savedData = [];
      this.isProduct = true;
      this.isCat = false;
      this.isOffer = false;
      this.offerActivate = false;
      this.isStore = false;
      this.isStoreOffer = false;
    } else if (data === "Category") {
      this.catSavedData = [];
      this.prodSavedData = [];
      this.productsSelected = [];
      this.savedData = [];
      this.isCat = true;
      this.isOffer = false;
      this.isStore = false;
      this.offerActivate = false;
      this.isProduct = false;
      this.isStoreOffer = false;
    } else if (data === "Offer") {
      this.catSavedData = [];
      this.prodSavedData = [];
      this.productsSelected = [];
      this.savedData = [];
      this.offerActivate = true;
      this.isOffer = true;
      this.isCat = false;
      this.isStore = false;
      this.isProduct = false;
      this.isStoreOffer = false;
    } else if (data === "Store List") {
      this.catSavedData = [];
      this.prodSavedData = [];
      this.productsSelected = [];
      this.savedData = [];
      this.isStore = true;
      this.isOffer = false;
      this.offerActivate = false;
      this.isCat = false;
      this.isProduct = false;
      this.isStoreOffer = false;
    } else if (data === "Shop Offer") {
      this.catSavedData = [];
      this.prodSavedData = [];
      this.productsSelected = [];
      this.savedData = [];
      this.isStore = false;
      this.isStoreOffer = true;
      this.isOffer = false;
      this.offerActivate = false;
      this.isCat = false;
      this.isProduct = false;
    }
  }
  addSection() {
    const d = new Date();
    // let time = d.getTime();
    this.time = d.getTime().toString();
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          sectionName: this.addSectionForm.value["sectionName"],
          sectionType: this.addSectionForm.value["sectionType"],
          identifier: this.addSectionForm.value["sectionName"] + this.time,
          index: this.addSectionForm.value["index"],
          isProduct: this.isProduct,
          isCategory: this.isCat,
          isOffer: this.isOffer,
          isStoreList: this.isStore,
          isStoreOffer: this.isStoreOffer,
          storeList: this.savedData,
          productList: this.productsSelected,
          categories: this.catSavedData,
        };
        console.log("fd", fd);
        this.productService.addHomeSection(fd).subscribe((response: any) => {
          console.log("section", response);
          this.showSpinner();
          this.onDiscard();
          // this.formSubmitted = false;
          Swal.fire("Section Created Succesfully!", "", "success");

          // this.onDiscard();
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex
  //     );
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex
  //     );
  //   }
  //   console.log("sortedArray", this.productsSelected);
  // }

  trackByFn(index: number, item: any): any {
    return item.id; // Use a unique identifier of the item
  }

  get sectionName() {
    return this.addSectionForm.get("sectionName");
  }

  get index() {
    return this.addSectionForm.get("index");
  }

  // get identifier() {
  //   return this.addSectionForm.get("identifier");
  // }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.productsSelected,
      event.previousIndex,
      event.currentIndex
    );
    console.log("sortedArray", this.productsSelected);

    // newList = Array.from(newList)
    // this.prodSavedData = newList;
    console.log("sortedArray2", this.prodSavedData);
  }

  getAllSections() {
    this.productService.fetchSectionsData().subscribe((response: any) => {
      console.log("log", response);

      this.sectionListLength = response?.homePageResponseList?.length;
      this.sectionList = response?.homePageResponseList
        .map((result: any, index: any) => {
          return {
            ...result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
      console.log("this.sectionList", this.sectionList);
    });
  }

  onEditClick(sectionId: any) {
    console.log("hi", sectionId);
    this.router.navigate(["/home-page/add-sections/edit-section/" + sectionId]);
    // this.router.navigateByUrl(`/section-list/edit-section/${sectionId}`);
  }

  onDeleteClick(sectionId: any) {
    this.productService.deleteSection(sectionId).subscribe((res: any) => {
      console.log("delete", res);
      if (res.status == 0) {
        this.toastr.success("Section Deleted Successfully");
        this.showSpinner();
        this.getAllSections();
      }
    });
  }

  onInput(event: any) {
    console.log("e", event.target.value);
    if (event.target.value?.length > 2) {
      this.productLists = this.originalProductList.filter((item: any) => {
        return JSON.stringify(event.target.value)
          .toLowerCase()
          .includes(item.productId);
      });
    } else this.productLists = this.originalProductList;
  }

  onClick() {
    window.scrollTo(500, 500);
  }
}
