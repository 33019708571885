import { Injectable, HostListener, Inject } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;

  constructor(@Inject(WINDOW) private window) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      path: "/dashboard/default",
      title: "Dashboard",
      icon: "home",
      type: "link",
      badgeType: "primary",
      active: false,
    },
    {
      title: "Category & Features",
      icon: "align-right",
      type: "sub",
      active: false,
      children: [
        {
          path: "/category/create-category",
          title: "Create Category",
          type: "link",
        },
        {
          path: "/category/create-sub-category",
          title: "Create sub-category",
          type: "link",
        },
        {
          path: "/category/create-size",
          title: "Create Size",
          type: "link",
        },
        {
          path: "/category/upload-banners",
          title: "Upload Banners",
          type: "link",
        },
        {
          path: "/category/geo_check",
          title: "Geo Check",
          type: "link",
        },
        {
          path: "/category/delivery",
          title: "Delivery",
          type: "link",
        },
      ],
    },
    {
      title: "Shop",
      icon: "home",
      type: "sub",
      active: false,
      children: [
        { path: "/shop/create-shop", title: "Create Shop", type: "link" },
        {
          path: "/shop/Add-Shop-Details",
          title: "Add-Shop-Details",
          type: "link",
        },
        { path: "/shop/shop-category", title: "Shop Category", type: "link" },
        { path: "/shop/shop-list", title: "Shop List", type: "link" },
      ],
    },
    {
      title: "Products",
      icon: "box",
      type: "sub",
      active: false,
      children: [
        {
          path: "/products/importByCSV",
          title: "Product Import",
          type: "link",
        },
        {
          path: "/products/product-list",
          title: "Product List",
          type: "link",
        },
        // {
        //   path: "/products/admin-product-list",
        //   title: "All Product List",
        //   type: "link",
        // },

        // {
        //   path: "/products//product-detail/:id",
        //   title: "Product Detail",
        //   type: "link",
        // },
        {
          path: "/products/add-product",
          title: "Add Product",
          type: "link",
        },
        {
          path: "/products/upload-pictures",
          title: "Upload Pictures",
          type: "link",
        },
        // {
        //   path: "/products/upload-banners",
        //   title: "Upload Banners",
        //   type: "link",
        // },
        {
          path: "/products/create-variant",
          title: "Create Variant",
          type: "link",
        },
        // {
        //   path: "/products/create-size",
        //   title: "Create Size",
        //   type: "link",
        // },
        {
          path: "/products/create-sale",
          title: "Create Sale",
          type: "link",
        },
        {
          path: "/products/shopify-connect",
          title: "Shopify Connect",
          type: "link",
        },
        {
          path: "/products/square-connect",
          title: "Square Connect",
          type: "link",
        },
      ],

      // {
      //   title: "digital",
      //   type: "sub",
      //   children: [
      //     {
      //       path: "/products/digital/digital-category",
      //       title: "Category",
      //       type: "link",
      //     },
      //     {
      //       path: "/products/digital/digital-sub-category",
      //       title: "Sub Category",
      //       type: "link",
      //     },
      //     {
      //       path: "/products/digital/digital-product-list",
      //       title: "Product List",
      //       type: "link",
      //     },
      //     {
      //       path: "/products/digital/digital-add-product",
      //       title: "Add Product",
      //       type: "link",
      //     },
      //   ],
      // },
    },

    {
      title: "Product List",
      icon: "tag",
      type: "sub",
      active: false,
      children: [
        // { path: "/coupons/list-coupons", title: "List Coupons", type: "link" },
        {
          path: "/products/admin-product-list",
          title: "All Product List",
          type: "link",
        },
      ],
    },

    {
      title: "OrderList Admin",
      icon: "tag",
      type: "sub",
      active: false,
      children: [
        // { path: "/coupons/list-coupons", title: "List Coupons", type: "link" },
        {
          path: "/orderListAdmin/orderlist-Admin",
          title: "Admin Order List",
          type: "link",
        },
      ],
    },

    // {
    // 	title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
    // 		{ path: '/sales/orders', title: 'Orders', type: 'link' },
    // 		{ path: '/sales/transactions', title: 'Transactions', type: 'link' },
    // 	]
    // },
    // {
    //   title: "Coupons",
    //   icon: "tag",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "/coupons/list-coupons", title: "List Coupons", type: "link" },
    //     {
    //       path: "/coupons/create-coupons",
    //       title: "Create Coupons",
    //       type: "link",
    //     },
    //   ],
    // },

    // {
    //   title: "Pages",
    //   icon: "clipboard",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     // { path: "/pages/list-page", title: "List Page", type: "link" },
    //     // { path: "/pages/create-page", title: "Create Page", type: "link" },
    //     {
    //       path: "/pages/success-onboarding",
    //       title: "Success Onboarding",
    //       type: "link",
    //     },
    //     {
    //       path: "/pages/unsuccess-onboarding",
    //       title: "Unsuccess Onboarding",
    //       type: "link",
    //     },
    //   ],
    // },
    // {
    //   title: "Media",
    //   path: "/media",
    //   icon: "camera",
    //   type: "link",
    //   active: false,
    // },

    {
      title: "Orders",
      icon: "align-left",
      type: "sub",
      active: false,
      children: [
        // { path: "/menus/list-menu", title: "Menu Lists", type: "link" },
        // { path: "/menus/create-menu", title: "Create Menu", type: "link" },
        { path: "/orders/order-list", title: "Order List", type: "link" },
      ],
    },
    {
      title: "Transactions",
      icon: "tag",
      type: "sub",
      active: false,
      children: [
        // { path: "/coupons/list-coupons", title: "List Coupons", type: "link" },
        {
          path: "/transactions/transactionlist-seller",
          title: "Seller Transaction",
          type: "link",
        },
      ],
    },
    {
      title: "Users",
      icon: "user-plus",
      type: "sub",
      active: false,
      children: [
        { path: "/users/list-user", title: "User List", type: "link" },
        // { path: "/users/create-user", title: "Create User", type: "link" },
      ],
    },
    {
      title: "Vendors",
      icon: "users",
      type: "sub",
      active: false,
      children: [
        { path: "/vendors/list-vendors", title: "Vendor List", type: "link" },
        { path: "/vendors/list-shops", title: "Shop List", type: "link" },

        // {
        //   path: "/vendors/create-vendors",
        //   title: "Create Vendor",
        //   type: "link",
        // },
      ],
    },
    {
      title: "Wallet",
      icon: "align-right",
      type: "sub",
      active: false,
      children: [
        // {
        //   path: "/wallet/withdrawal-report",
        //   title: "Withdrawal Report",
        //   type: "link",
        // },
        {
          path: "/wallet/stripe-onboard",
          title: "Stripe Onboard",
          type: "link",
        },
        // { path: "/orders/order-list", title: "Order List", type: "link" },
      ],
    },

    {
      title: "Home Page",
      icon: "box",
      type: "sub",
      active: false,
      children: [
        {
          path: "/home-page/add-sections",
          title: "Add Section",
          type: "link",
        },
        {
          path: "/home-page/offer-upload",
          title: "Offer Upload",
          type: "link",
        },
        {
          path: "/home-page/shop-offer",
          title: "Shop Offer",
          type: "link",
        },
        {
          path: "/home-page/categoryImg-upload",
          title: "Category Upload",
          type: "link",
        },
        // {
        //   path: "/home-page/section-list",
        //   title: "Section List",
        //   type: "link",
        // },
      ],
    },

    {
      title: "Seller-CRM",
      icon: "box",
      type: "sub",
      active: false,
      children: [
        {
          path: "/seller-crm/seller-crm",
          title: "Add Seller-CRM",
          type: "link",
        },
        {
          path: "/seller-crm/crm-list",
          title: "CRM List",
          type: "link",
        },
        // { path: "/orders/order-list", title: "Order List", type: "link" },
      ],
    },

    // {
    // 	title: 'Localization', icon: 'chrome', type: 'sub', children: [
    // 		{ path: '/localization/translations', title: 'Translations', type: 'link' },
    // 		{ path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
    // 		{ path: '/localization/taxes', title: 'Taxes', type: 'link' },
    // 	]
    // },
    // {
    // 	title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
    // },
    // {
    // 	title: 'Settings', icon: 'settings', type: 'sub', children: [
    // 		{ path: '/settings/profile', title: 'Profile', type: 'link' },
    // 	]
    // },
    // {
    // 	title: 'Invoice', path: '/invoice', icon: 'archive', type: 'link', active: false
    // },
    // {
    // 	title: 'Login',path: '/auth/login', icon: 'log-in', type: 'link', active: false
    // }
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
