<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Upload Banners</h5>
        </div>
        <div class="card-body" [formGroup]="bannerUploadForm">
          <div class="row product-adding mb-3">
            <div class="col-xl-7">
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Choose Category
                    </label>
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      #mySelect
                      aria-label="Default select example"
                      formControlName="categoryId"
                    >
                      <option [ngValue]="0">Select Category</option>
                      <option
                        *ngFor="let category of category_list"
                        [value]="category.id"
                      >
                        {{ category.category }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        categoryId &&
                        categoryId.invalid &&
                        (categoryId.dirty ||
                          categoryId.touched ||
                          !categoryId.value)
                      "
                      class="text text-danger"
                    >
                      Please Select a category.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Banner URL :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      formControlName="linkUrl"
                      placeholder="Please Enter URL"
                    />
                    <div
                      *ngIf="
                        linkUrl &&
                        linkUrl.invalid &&
                        (linkUrl.dirty || linkUrl.touched)
                      "
                      class="text text-danger"
                    >
                      URL is required.
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0">Color :</label>
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      formControlName="color"
                      placeholder="Please Enter Color"
                    />
                    <div
                      *ngIf="
                        color && color.invalid && (color.dirty || color.touched)
                      "
                      class="text text-danger"
                    >
                      Color is required.
                    </div>
                  </div>
                </div> -->

                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Text Color :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                   
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="colorName"
                      (change)="onSelectColor()"
                    >
                      <option [ngValue]="0">Select Color</option>
                      <option
                        *ngFor="let color of colorArray"
                        [value]="color.id"
                      >
                        {{ color.color_name }}
                      </option>
                    </select>
                    
                  </div>
                </div> -->
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Banner Title :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      formControlName="bannerTitle"
                      placeholder="Please Enter Banner Title"
                    />
                  
                  </div>
                </div> -->

                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Banner Text :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      formControlName="bannerText"
                      placeholder="Please Enter Banner Text"
                    />
                   
                  </div>
                </div> -->
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Banner Price :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      formControlName="bannerPrice"
                      placeholder="Please Enter Banner Price"
                    />
                   
                  </div>
                </div> -->
                <!-- <div *ngIf="!bannerEditClick" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Upload Type :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      formControlName="uploadType"
                      aria-label="Default select example"
                      (input)="onChangeBannerType($event)"
                    >
                      <option [ngValue]="0">Select Upload Type</option>
                      <option value="Banner">Banner</option>
                      <option value="ExtraLarge">Extra Large</option>
                      <option value="DoubleHight">Double Hight</option>
                      <option value="Small">Small</option>
                    </select>
                    <div
                      *ngIf="
                        uploadType &&
                        uploadType.invalid &&
                        (uploadType.dirty ||
                          uploadType.touched ||
                          !uploadType.value)
                      "
                      class="text text-danger"
                    >
                      Please Select a Upload Type.
                    </div>
                  </div>
                </div> -->
                <div *ngIf="!bannerEditClick" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom02" class="mb-0"
                      >Upload Image :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      type="file"
                      class="form-control"
                      formControlName="image"
                      accept=".jpg,.jpeg"
                      (change)="fileChangeEvent($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="true"
                [aspectRatio]="3.84 / 1"
                format="jpg"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                backgroundColor="rgb(255,255,255)"
              ></image-cropper>
            </div>
            <div class="col-md-4">
              <div
                *ngIf="!croppedImage && browseImg"
                id="container"
                class=".div"
              >
                <svg viewBox="0 0 100 100">
                  <defs>
                    <filter id="shadow">
                      <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="1.5"
                        flood-color="#fc6767"
                      />
                    </filter>
                  </defs>
                  <circle
                    id="spinner"
                    style="
                      fill: transparent;
                      stroke: #dd2476;
                      stroke-width: 7px;
                      stroke-linecap: round;
                      filter: url(#shadow);
                    "
                    cx="50"
                    cy="50"
                    r="45"
                  />
                </svg>
              </div>
              <img class="upload" [src]="croppedImage" />
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-sm-3" *ngFor="let picture of pictureList">
              <img class="responsive" src="{{ picture.url }}" />
            </div>
          </div> -->
          <div *ngIf="!bannerEditClick" class="row">
            <div class="product-buttons">
              <button
                *ngIf="!isupload"
                #modal
                class="btn btn-primary me-1"
                type="button"
                data-toggle="modal"
                data-original-title="test"
                #addCategory
                data-target="#exampleModal"
                (click)="onSave()"
                [disabled]="bannerUploadForm.invalid || !categoryId.value"
              >
                Upload
              </button>
              <button
                *ngIf="isupload"
                class="btn btn-primary me-1"
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Uploading...
              </button>
              <button
                *ngIf="!isupload"
                class="btn btn-secondary"
                type="button"
                data-dismiss="modal"
                (click)="onClose()"
              >
                Close
              </button>
              <P class="text-danger"
                ><b>{{ imageMessage }}</b></P
              >
              <ng-template #content let-modal>
                <div class="modal-header">
                  <h5 class="modal-title f-w-600" id="exampleModalLabel">
                    Choose Banner
                  </h5>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form class="needs-validation" [formGroup]="saveImageform">
                    <div class="form">
                      <div class="form-group">
                        <label for="validationCustom01" class="mb-1"
                          >Image :</label
                        >
                        <input
                          class="form-control"
                          id="validationCustom01"
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          formControlName="image"
                          (change)="onFileSelected($event)"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    [disabled]="saveImageform.invalid"
                    (click)="onSave(); modal.dismiss()"
                  >
                    Save
                  </button>

                  <button
                    class="btn btn-secondary"
                    type="button"
                    data-dismiss="modal"
                    (click)="onClose(); modal.dismiss('Cross click')"
                  >
                    Close
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
          <div *ngIf="bannerEditClick" class="form-group row mb-0">
            <div class="col-sm-8 offset-xl-3 offset-sm-4">
              <div class="product-buttons">
                <button
                  type="submit"
                  value="submit"
                  class="btn btn-primary me-1"
                  (click)="onUpdateClick()"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-light"
                  (click)="onDiscard()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="batchDelete" class="category-table custom-datatable">
        <div class="table-responsive">
          <!-- <form>
                                <div class="mb-3 row">
                                    <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
                                    <div class="col-xs-3 col-sm-auto">
                                      <input id="table-complete-search" type="text" class="form-control" name="searchTerm"  [(ngModel)]="searchText" autocomplete="off">
                                    </div>
                                </div>
                              </form> -->
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>

                <th scope="col" sortable="category">Image</th>
                <th scope="col" sortable="category">Category Name</th>
                <!-- <th scope="col" sortable="category">Banner Title</th>
                <th scope="col" sortable="category">Banner Text</th>
                <th scope="col" sortable="category">Banner Price</th>
                <th scope="col" sortable="category">Text Color</th> -->
                <th scope="col" sortable="category">Upload Type</th>
                <th scope="col" sortable="category">Link Url</th>
                <th scope="col" sortable="category">Delete</th>
                <th scope="col" sortable="population">Actions</th>
                <th scope="col" sortable="population">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let banner of banners; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td><img class="imgClass" [src]="banner?.url" alt="" /></td>
                <td>
                  {{ banner?.categoryName ? banner?.categoryName : "N/A" }}
                </td>
                <!-- <td>{{ banner?.bannerTitle ? banner?.bannerTitle : "N/A" }}</td>
                <td>{{ banner?.banner_text ? banner?.banner_text : "N/A" }}</td>
                <td>{{ banner?.bannerPrice ? banner?.bannerPrice : "N/A" }}</td>
                <td>{{ banner?.colorName ? banner?.colorName : "N/A" }}</td> -->
                <td>{{ banner?.uploadType }}</td>
                <td>{{ banner?.linkUrl ? banner?.linkUrl : "N/A" }}</td>
                <!-- <td>{{ banner?.colorCode ? banner?.colorCode : "N/A" }}</td> -->

                <td class="d-flex">
                  <a
                    href="javascript:void(0)"
                    (click)="onEditBannerClick(banner.bannerId)"
                    ><i class="fa fa-edit f-12"></i></a
                  >&nbsp;
                  <a
                    *ngIf="banner?.active !== true"
                    href="javascript:void(0)"
                    (click)="onDeleteBannerClick(banner.bannerId)"
                    ><i class="fa fa-trash-o"></i
                  ></a>
                </td>
                <td>
                  <label
                    class="switch"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="{{ banner?.active == true ? 'Active' : 'Inactive' }}"
                  >
                    <input
                      type="checkbox"
                      [checked]="banner?.active == true"
                      (click)="
                        onActionClick($event, banner?.bannerId, banner?.active)
                      "
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td>
                  <span
                    [class]="
                      banner?.active == true
                        ? 'badge bg-success text-dark'
                        : 'badge bg-secondary text-dark'
                    "
                  >
                    {{ banner?.active == true ? "Active" : "Inactive" }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="d-flex justify-content-center p-2">
                <ngb-pagination
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [collectionSize]="categoriesLength"
                  (pageChange)="getAllCategories()"
                >
                </ngb-pagination>
              </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
