<div class="container-fluid">
  <div class="card">
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>

    <div class="card collapse" id="collapseExample">
      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#fff"
        type="ball-scale-multiple"
      >
        <p style="font-size: 20px; color: white">Loading...</p>
      </ngx-spinner>
      <div class="card-header">
        <h5>Edit Product</h5>
      </div>
      <div class="card-body">
        <div class="row product-adding">
          <form
            class="needs-validation"
            [formGroup]="editProductForm"
            (ngSubmit)="updateProduct()"
          >
            <div class="add-product-form">
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom01" class="mb-0">Name :</label>
                </div>
                <div class="col-xl-8 col-sm-7">
                  <input
                    class="form-control"
                    id="validationCustom01"
                    type="text"
                    placeholder="Please Enter Product Name"
                    formControlName="name"
                  />
                </div>
              </div>

              <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Select Shop Category :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="shopCategoryId"
                    >
                      <option [ngValue]="0">Select Shop Category</option>
                      <option
                        *ngFor="let shopCat of shopCategoryList"
                        [value]="shopCat.id"
                      >
                        {{ shopCat.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        shopCategoryId &&
                        !shopCategoryId.value &&
                        (shopCategoryId.dirty || shopCategoryId.touched)
                      "
                      class="text text-danger"
                    >
                      Please Select a Shop Category.
                    </div>
                  </div>
                </div> -->

              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom04" class="mb-0"
                    >ShortDesc :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <input
                    class="form-control"
                    id="validationCustom04"
                    type="text"
                    placeholder="Add Short Description Here"
                    formControlName="shortDesc"
                  />
                </div>
              </div>
              <!-- <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom05" class="mb-0">Stock :</label>
                </div>
                <div class="col-xl-8 col-sm-7">
                  <input
                    class="form-control"
                    id="validationCustom05"
                    type="number"
                    placeholder="Please Enter Stock Here"
                    formControlName="stockTotal"
                  />
                </div>
              </div> -->
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom02" class="mb-0"
                    >Category :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    formControlName="categoryId"
                    (change)="onSelectCategory()"
                  >
                    <option [ngValue]="0">Select Category</option>
                    <option *ngFor="let cat of categoryList" [ngValue]="cat.id">
                      {{ cat.category }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom02" class="mb-0"
                    >SubCategory :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    formControlName="subCategoryId"
                  >
                    <option [ngValue]="0">Select SubCategory</option>
                    <option
                      *ngFor="let sub of subCategoryList"
                      [ngValue]="sub.id"
                    >
                      {{ sub.subcategory }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom06" class="mb-0">Price :</label>
                </div>
                <div class="col-xl-8 col-sm-7">
                  <input
                    class="form-control"
                    id="validationCustom06"
                    type="text"
                    placeholder="Please Enter Price Here"
                    formControlName="price"
                  />
                  <!-- <div
                    *ngIf="
                      price && price.invalid && (price.dirty || price.touched)
                    "
                    class="text text-danger"
                  >
                    Price is required.
                  </div> -->
                </div>
              </div>
              <!-- <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom01" class="mb-0"
                    >Color Code :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <input
                    class="form-control"
                    id="validationCustom01"
                    placeholder="Please Enter Color Code"
                    formControlName="colorCode"
                  />
                 
                </div>
              </div> -->
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom02" class="mb-0"
                    >Color Name :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    formControlName="colorName"
                    (change)="onSelectColor()"
                  >
                    <option [ngValue]="0">Select Color</option>
                    <option
                      *ngFor="let color of colorArray"
                      [ngValue]="color.color_name"
                    >
                      {{ color.color_name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom05" class="mb-0">Until :</label>
                </div>
                <div class="col-xl-8 col-sm-7">
                  <input
                    class="form-control"
                    id="validationCustom05"
                    type="date"
                    formControlName="until"
                  />
                </div>
              </div> -->
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom06" class="mb-0"
                    >Discount :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <input
                    class="form-control"
                    id="validationCustom06"
                    type="text"
                    placeholder="Please Enter Discount Here"
                    formControlName="discount"
                  />
                </div>
              </div>
              <!-- <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom06" class="mb-0">Offer :</label>
                </div>
                <div class="col-xl-8 col-sm-7">
                  <input
                    class="form-control"
                    id="validationCustom06"
                    type="text"
                    placeholder="Please Enter Offer Here"
                    formControlName="offer"
                  />
                </div>
              </div> -->
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom06" class="mb-0"
                    >Add Description :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <!-- <ckeditor
                      [editor]="Editor"
                      formControlName="description"
                    ></ckeditor> -->
                  <quill-editor formControlName="description"></quill-editor>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom06" class="mb-0"
                    >Additional Information :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <!-- <ckeditor
                      [editor]="Editor"
                      formControlName="additionalInfo"
                    ></ckeditor> -->
                  <quill-editor formControlName="additionalInfo"></quill-editor>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-3 col-sm-4">
                  <label for="validationCustom06" class="mb-0"
                    >Shipping & Returns :</label
                  >
                </div>
                <div class="col-xl-8 col-sm-7">
                  <!-- <ckeditor
                      [editor]="Editor"
                      formControlName="shippingReturn"
                    ></ckeditor> -->
                  <quill-editor formControlName="shippingReturn"></quill-editor>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0">
              <div class="col-sm-8 offset-xl-3 offset-sm-4">
                <div class="product-buttons">
                  <button
                    *ngIf="!updateClick"
                    type="submit"
                    class="btn btn-primary me-1"
                  >
                    Update
                  </button>
                  <button
                    *ngIf="updateClick"
                    class="btn btn-primary me-1"
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    updating...
                  </button>
                  <button
                    *ngIf="editProduct"
                    type="button"
                    class="btn btn-danger"
                    (click)="onDiscard()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="row product-page-main card-body">
      <div class="col-xl-4">
        <section *ngIf="imagesRect.length > 0">
          <ks-carousel [id]="101" [images]="imagesRect"> </ks-carousel>
        </section>
        <section *ngIf="imagesRect.length <= 0"></section>
        <div class="mt-3 text-center">
          <button
            type="button"
            class="btn btn-sm btn-success me-2"
            (click)="onClickAddImage()"
          >
            Edit/Add Images
          </button>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="product-page-details product-right mb-0">
          <h2>{{ productDetails?.product?.name }}</h2>
          <!-- <ngb-rating class="ratings-sec" [(rate)]="currentRate">
            <ng-template let-fill="fill" let-index="index">
              <span
                class="star"
                [class.filled]="fill === 100"
                [class.bad]="index < 3"
                ><i class="fa fa-star"></i>
              </span>
            </ng-template>
          </ngb-rating> -->
          <hr />
          <h6 class="product-title">product details</h6>
          <p class="mb-0">
            {{ productDetails?.product?.shortdesc }}
          </p>
          <div class="product-price digits mt-2">
            <h3>
              {{ salePrice }}
              <span *ngIf="salePrice !== price">
                <del>{{ price }}</del></span
              >
            </h3>
          </div>
          <ul
            *ngIf="!productDetails?.product?.shopifyProduct"
            class="color-variant"
          >
            <h6 class="product-title size-text">select color</h6>
            <li
              *ngFor="
                let variant of productDetails?.product?.variants;
                let i = index
              "
              [class]="i == selectedColorIndex ? 'activeCol' : ''"
              [ngStyle]="{ 'background-color': variant.color }"
              #col
              (click)="onColorClick(variant, i)"
            ></li>
          </ul>
          <hr />
          <h6 class="product-title size-text">
            select Variant
            <span class="pull-right">
              <a
                data-toggle="modal"
                (click)="open(content)"
                class="font-primary"
                >size chart</a
              >
            </span>
          </h6>
          <ng-template #content let-modal>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ productDetails?.product?.name }}
              </h5>
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <img
                src="assets/images/size-chart.jpg"
                alt=""
                class="img-fluid blur-up lazyloaded"
              />
            </div>
          </ng-template>
          <div class="size-box">
            <ul class="justify-space-between">
              <li *ngFor="let siz of size; let i = index" class="size">
                <a
                  [class]="
                    i == selectedSizeIndex
                      ? 'bg-dark text-white rounded-pill size'
                      : 'bg-white text-dark rounded-pill size'
                  "
                  #clickSize
                  (click)="onSizeClick(siz, i)"
                  >{{ siz.name }}</a
                >
              </li>

              <!-- <li><a href="javascript:void(0)">m</a></li>
              <li><a href="javascript:void(0)">l</a></li>
              <li><a href="javascript:void(0)">xl</a></li> -->
            </ul>
          </div>
          <!-- <div class="add-product-form">
            <h6 class="product-title">quantity</h6>
            <div class="qty-box1">
              <div class="input-group">
                <i class="fa fa-minus btnGtr1" (click)="decrement()"></i>
                <input
                  class="touchspin1 text-center"
                  name="counter"
                  [value]="counter"
                  type="text"
                />
                <i class="fa fa-plus btnLess1" (click)="increment()"></i>
              </div>
            </div>
          </div> -->
          <hr />
          <div class="d-flex mb-2">
            <div class="col-md-6">
              <h6 *ngIf="this.stock" class="product-title">Stock Avilable</h6>
            </div>
            <div *ngIf="this.stock" class="col-md-6">
              <button
                type="submit"
                class="btn btn-sm btn-secondary me-2"
                (click)="onEditStockClick()"
              >
                Edit Stock
              </button>
            </div>
          </div>

          <p *ngIf="this.stock" class="mb-2">
            <span class="text-danger"
              ><strong>{{ this.stock }}</strong></span
            >
          </p>
          <div *ngIf="this.stock">
            <hr />
          </div>

          <div *ngIf="isStockEdit">
            <form
              class="needs-validation"
              [formGroup]="editStockForm"
              (ngSubmit)="updateStock()"
            >
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >New Stock :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      placeholder="Enter New Stock"
                      formControlName="newStock"
                      (change)="onClickNewStock($event)"
                    />
                  </div>
                </div>
                <div>
                  <Span class="text-center">
                    <h5><b>OR</b></h5>
                  </Span>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Add Stock :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      placeholder="Enter To Add Stock"
                      formControlName="addStock"
                      (change)="onClickAddStock($event)"
                    />
                  </div>
                </div>
                <div>
                  <Span class="text-center">
                    <h5><b>OR</b></h5>
                  </Span>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Subtract Stock :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      placeholder="Enter To Subtract Stock"
                      formControlName="subStock"
                      (change)="onClickSubStock($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="col-sm-8 offset-xl-3 offset-sm-4">
                  <div class="product-buttons">
                    <button
                      *ngIf="!updateStockClick"
                      type="submit"
                      class="btn btn-success me-1"
                    >
                      Update
                    </button>
                    <button
                      *ngIf="updateStockClick"
                      class="btn btn-success me-1"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      updating...
                    </button>
                    <button
                      *ngIf="isStockEdit"
                      type="button"
                      class="btn btn-danger"
                      (click)="onCancel()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <hr />
          </div>

          <!-- <div class="timer">
            <p id="demo">
              <span
                >{{ selectedSize?.timeLeft?.day }}
                <span class="padding-l">:</span>
                <span class="timer-cal">Days</span> </span
              ><span
                >{{ selectedSize?.timeLeft?.hour }}
                <span class="padding-l">:</span>
                <span class="timer-cal">Hrs</span> </span
              ><span
                >{{ selectedSize?.timeLeft?.minute }}
                <span class="timer-cal">Min</span>
              </span>
            </p>
          </div> -->
          <!-- <span class="padding-l">:</span> -->
          <!-- <span>57 <span class="timer-cal">Sec</span></span> -->
          <div class="m-t-15">
            <!-- *ngIf="this.stock" -->
            <button
              [disabled]="!this.stock"
              class="btn btn-primary m-r-10"
              type="button"
              #onEditClick
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              (click)="onEditButtonClick()"
            >
              Edit Product
            </button>
            <!-- [disabled]="!sizeSelected || !colorSelected" -->
            <!-- <button class="btn btn-secondary" type="button">Buy Now</button> -->
          </div>
          <div>
            <p class="text-dark">
              (Please Select Size & Color To Edit Product)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
