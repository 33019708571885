import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { productDB } from "src/app/shared/tables/product-list";
import { ProductService } from "src/app/_services/product/product.service";
import { ToastrService } from "ngx-toastr";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent implements OnInit {
  public product_list: any;
  loaded: boolean = false;
  totalCount: any = 0;
  userInfo: any;
  newUser: any;
  signedInUser: any;
  userId: any;
  page: number = 1;
  pageSize: number = 52;
  is_checked: any;
  selectedMapper = new Map();
  @ViewChild("selectRef") selectRef: ElementRef;
  savedData: any[] = [];
  allProductList: any;
  searchTerm: string = "";
  filterList: any[] = [];
  fillterActive: boolean = false;
  // selectedValue: any;
  constructor(
    private productService: ProductService,
    private router: Router,
    public activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private sessionManager: SessionManagerService
  ) {
    // this.product_list = productDB.product;
  }

  ngOnInit() {
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    console.log("newUser", this.newUser.userName);
    this.userId = this.newUser.userId;
    console.log("userId", this.userId);
    // this.showSpinner();
    this.fetchAllProducts();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  // fetchAllProducts() {
  //   this.productService.fetchAllProducts().subscribe((response: any) => {
  //     this.product_list = response.products;
  //     console.log("product_list", this.product_list);
  //   });
  // }

  // fetchAllProducts() {
  //   this.activeRoute.params.subscribe((param) => {
  //     this.loaded = false;
  //     console.log("param", param);

  //     this.productService
  //       .getStoreList(param, "/v1/storeList", 5)
  //       .subscribe((response: any) => {
  //         this.product_list = response.products;
  //         this.totalCount = response.totalCount;
  //         console.log("productList", response);
  //       });
  //   });
  // }
  fetchAllProducts() {
    // this.showSpinner();
    this.spinner.show();
    this.fillterActive = false;
    this.productService
      .getProductsBySellerId(this.userId)
      .subscribe((res: any) => {
        this.allProductList = res;
        this.product_list = res.products.slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
        this.totalCount = res.totalCount;
        this.spinner.hide();
        console.log("this.product_list", this.allProductList);
      });
  }

  onProductClick(productId: any) {
    console.log("productId", productId);
    this.router.navigate(["/products/physical/product-detail/:productId"]);
  }

  // onDeleteClick(id: any) {
  //   console.log("id", id);
  //   this.productService.deleteProductByProductId(id).subscribe((res: any) => {
  //     console.log("res", res);
  //     if (res.status == 0) {
  //       this.showSpinner();
  //       this.fetchAllProducts();
  //       this.toastr.success("Product Deleted Successfully");
  //     } else {
  //       this.toastr.error("Something Went Wrong,Try Again");
  //       this.fetchAllProducts();
  //     }
  //   });
  // }

  onDeleteClick(id: any) {
    Swal.fire({
      title: "Do you want to delete the product?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService
          .deleteProductByProductId(id)
          .subscribe((res: any) => {
            console.log("res", res);
            if (res.status == 0) {
              this.showSpinner();
              this.fetchAllProducts();

              this.toastr.success("Product Deleted Successfully");
            } else {
              this.toastr.error("Something Went Wrong,Try Again");
              this.fetchAllProducts();
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onActiveClick(event: any, id: any, active: any) {
    console.log(id);
    this.is_checked = active;
    console.log("is_Active", active);
    console.log("checked", this.is_checked);

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `SAVE`,
      denyButtonText: `CANCEL`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        if (event.target.checked) {
          this.is_checked = 1;
          console.log("is", this.is_checked);
        } else {
          this.is_checked = 0;
          // console.log('ddd', this.is_checked);
        }
        const save_inputs = {
          is_active: this.is_checked,
        };
        console.log(save_inputs);

        if (id !== 0) {
          this.productService.publishProduct(id).subscribe((response: any) => {
            console.log("tttt", response);
            Swal.fire("Saved!", "", "success");
            this.showSpinner();
            this.fetchAllProducts();
            this.selectRef.nativeElement.value = 0;
          });
        }
      } else if (result.isDenied) {
        this.fetchAllProducts();
        this.selectRef.nativeElement.value = 0;
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onSelectActiveClick(event: any, product: any) {
    if (event.target.checked) {
      this.savedData.push(product.id);
      this.savedData = Array.from(new Set(this.savedData));
      this.selectedMapper.set(product.id, product);
    } else {
      let filteredData = this.savedData.filter((data) => product.id != data);
      this.savedData = filteredData;
      this.selectedMapper.delete(product.id);
    }
    console.log("selectedMapper", this.selectedMapper);
    console.log("this.savedData", this.savedData);
  }

  onSelectedPublish() {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `SAVE`,
      denyButtonText: `CANCEL`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        console.log("fd", this.savedData);
        // return;
        if (this.selectedMapper.size > 0) {
          this.productService
            .publishSelectedProduct(this.savedData)
            .subscribe((response: any) => {
              console.log("tttt", response);
              Swal.fire("Published!", "", "success");
              this.savedData = [];
              this.showSpinner();
              this.fetchAllProducts();
              this.selectRef.nativeElement.value = 0;
            });
        }
      } else if (result.isDenied) {
        this.fetchAllProducts();
        this.selectRef.nativeElement.value = 0;
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onSelectedUnPublish() {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `SAVE`,
      denyButtonText: `CANCEL`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        console.log("fd", this.savedData);
        // return;
        if (this.selectedMapper.size > 0) {
          this.productService
            .unpublishSelectedProduct(this.savedData)
            .subscribe((response: any) => {
              console.log("tttt", response);
              Swal.fire("UnPublished!", "", "success");
              this.savedData = [];
              this.showSpinner();
              this.fetchAllProducts();
              this.selectRef.nativeElement.value = 0;
            });
        }
      } else if (result.isDenied) {
        this.fetchAllProducts();
        this.selectRef.nativeElement.value = 0;
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  selectProducts(event: any) {
    console.log("event", event.target.value);
    let value = event.target.value;
    if (value == 1) {
      this.fillterActive = true;
      this.showSpinner();
      this.product_list = this.allProductList.products.filter((item: any) => {
        return item.status == "published";
      });
      this.totalCount = this.product_list?.length;
      // this.product_list = this.product_list.slice(
      //   (this.page - 1) * this.pageSize,
      //   (this.page - 1) * this.pageSize + this.pageSize
      // );
    } else if (value == 2) {
      this.fillterActive = true;
      this.showSpinner();

      this.product_list = this.allProductList.products.filter((item: any) => {
        return item.status == "unpublished";
      });
      this.totalCount = this.product_list?.length;
      // this.product_list = this.product_list.slice(
      //   (this.page - 1) * this.pageSize,
      //   (this.page - 1) * this.pageSize + this.pageSize
      // );
    } else {
      this.fetchAllProducts();
    }
  }

  onFilterClick(type: string, keyword: any) {
    if (!keyword.length) {
      this.fillterActive = false;
      this.product_list = this.allProductList?.products;
      // this.fetchAllProducts();
      this.selectRef.nativeElement.value = 0;

      return;
    }
    let obj: any = {
      search: () => {
        this.fillterActive = true;
        this.filterList = [];
        this.allProductList?.products?.forEach((td: any) => {
          if (td?.name?.toLowerCase()?.includes(keyword?.toLowerCase())) {
            this.filterList.push(td);
          }
          this.product_list = this.filterList;
        });
        console.log(keyword, this.filterList);
      },
    };
    obj[type]();
  }

  onClearText() {
    this.searchTerm = "";
    this.product_list = this.allProductList?.products;
    this.selectRef.nativeElement.value = 0;
    this.fillterActive = false;
  }
}
