<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header row">
          <div class="col-md-6">
            <h5>Section Details</h5>
          </div>
          <div class="col-md-6">
            <button
              type="button"
              class="btn btn-primary me-1"
              style="float: right"
              (click)="onClick()"
            >
              Add Section
            </button>
          </div>
        </div>
        <div class="card-body">
          <!-- <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Create User</a>
            </div> -->
          <div
            id="batchDelete"
            class="category-table custom-datatable user-image"
          >
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="text-left">
                    <!-- <th scope="col">Actions</th> -->
                    <th scope="col">#</th>
                    <th scope="col">Section Name</th>
                    <th scope="col">Section Type</th>
                    <!-- <th scope="col" sortable="lName">Last Name</th> -->
                    <th scope="col">Index</th>
                    <th scope="col">Action</th>

                    <!-- <th scope="col">Section Contains</th> -->
                    <!-- <th scope="col" sortable="role">Role</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let section of sectionList" class="text-left">
                    <!-- <td>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-edit f-12"></i></a
                  >&nbsp;
                  <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                </td> -->
                    <td scope="row">
                      <!-- <img
                    [src]="item.avatar"
                    class="imgTable"
                    style="width: 20px"
                  /> -->
                      {{ section?.sl_no }}
                    </td>
                    <td>
                      {{ section?.sectionName }}
                    </td>
                    <td *ngIf="section?.isCategory">
                      {{ section?.isCategory ? "Category" : "N/A" }}
                    </td>
                    <td *ngIf="section?.isOffer">
                      {{ section?.isOffer ? "Offer" : "N/A" }}
                    </td>
                    <td *ngIf="section?.isProduct">
                      {{ section?.isProduct ? "Product" : "N/A" }}
                    </td>
                    <td *ngIf="section?.isStoreList">
                      {{ section?.isStoreList ? "Store" : "N/A" }}
                    </td>
                    <td *ngIf="section?.isStoreOffer">
                      {{ section?.isStoreOffer ? "Shop offer" : "N/A" }}
                    </td>
                    <!-- <td>{{ item.lName }}</td> -->
                    <td>{{ section?.index }}</td>

                    <!-- <td>{{ section?.createdDate.split("[")[0] | date : "medium" }}</td> -->
                    <!-- <td>{{ item.role }}</td> -->
                    <td>
                      <a
                        href="javascript:void(0)"
                        (click)="onEditClick(section?.sectionId)"
                        ><i class="fa fa-edit f-12"></i></a
                      >&nbsp;
                      <a
                        href="javascript:void(0)"
                        (click)="onDeleteClick(section.sectionId)"
                        ><i class="fa fa-trash-o"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center p-2">
                <ngb-pagination
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [collectionSize]="sectionListLength"
                  (pageChange)="getAllSections()"
                >
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
    <div class="card-header">
      <h5>Add Homepage Section</h5>
    </div>
    <div class="card-body">
      <div class="row product-adding">
        <form
          class="needs-validation"
          [formGroup]="addSectionForm"
          (ngSubmit)="addSection()"
        >
          <div class="add-product-form">
            <div class="form-group row">
              <div class="col-xl-3 col-sm-4">
                <label for="validationCustom01" class="mb-0"
                  >Section Name :</label
                >
              </div>
              <div class="col-xl-8 col-sm-7">
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  placeholder="Please Enter Section Name"
                  formControlName="sectionName"
                />
                <div
                  *ngIf="
                    sectionName &&
                    sectionName.invalid &&
                    (sectionName.dirty || sectionName.touched)
                  "
                  class="text text-danger"
                >
                  Section Name is required.
                </div>
              </div>
            </div>
            <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Section identifier :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom06"
                      type="text"
                      placeholder="Please Enter Section Identifier here"
                      formControlName="identifier"
                    />
                    <div
                      *ngIf="
                        identifier &&
                        identifier.invalid &&
                        (identifier.dirty || identifier.touched)
                      "
                      class="text text-danger"
                    >
                      Identifier is required.
                    </div>
                  </div>
                </div> -->
            <div class="form-group row">
              <div class="col-xl-3 col-sm-4">
                <label for="validationCustom06" class="mb-0"
                  >Section Index :</label
                >
              </div>
              <div class="col-xl-8 col-sm-7">
                <input
                  class="form-control"
                  id="validationCustom06"
                  type="text"
                  placeholder="Please Enter Section Index here"
                  formControlName="index"
                />
                <div
                  *ngIf="
                    index && index.invalid && (index.dirty || index.touched)
                  "
                  class="text text-danger"
                >
                  Index is required.
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-xl-3 col-sm-4">
                <label for="validationCustom01" class="mb-0"
                  >Section Type :</label
                >
              </div>
              <div class="col-xl-8 col-sm-7">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="sectionType"
                  (change)="onSelectSection()"
                >
                  <option [ngValue]="0">Select Section Type</option>
                  <option
                    *ngFor="let section of sectionType"
                    [value]="section.value"
                  >
                    {{ section.value }}
                  </option>
                </select>
                <!-- <div
                      *ngIf="
                        lastName &&
                        lastName.invalid &&
                        (lastName.dirty || lastName.touched)
                      "
                      class="text text-danger"
                    >
                      Last Name is required.
                    </div> -->
              </div>
            </div>

            <div [ngClass]="{ hidden: !isStore }" class="form-group row">
              <div class="col-xl-3 col-sm-4">
                <label for="validationCustom03" class="mb-0"
                  >Select Stores :</label
                >
              </div>
              <div class="col-xl-8 col-sm-7">
                <ng-multiselect-dropdown
                  placeholder=""
                  [data]="shopList"
                  name="shopList"
                  formControlName="storeList"
                  [settings]="dropdownSettings"
                  (onSelect)="onItemSelect($event)"
                  (onDeSelect)="onItemDeSelect($event)"
                >
                </ng-multiselect-dropdown>
                <!-- <div
                      *ngIf="
                        email && email.invalid && (email.dirty || email.touched)
                      "
                      class="text text-danger"
                    >
                      Email is required.
                    </div> -->
              </div>
            </div>

            <div [ngClass]="{ hidden: !isStoreOffer }" class="form-group row">
              <div class="col-xl-3 col-sm-4">
                <label for="validationCustom03" class="mb-0"
                  >Select Stores :</label
                >
                <span class="text-primary">(Need to Select 2)</span>
              </div>
              <div class="col-xl-8 col-sm-7">
                <ng-multiselect-dropdown
                  placeholder=""
                  [data]="shopList"
                  name="shopList"
                  formControlName="storeList"
                  [settings]="dropdownSettings1"
                  (onSelect)="onItemSelect($event)"
                  (onDeSelect)="onItemDeSelect($event)"
                >
                </ng-multiselect-dropdown>
                <div class="mt-2">
                  <span class="text-danger"
                    >If you are creating an “Shop Offer", please upload the
                    equivalent Offer Images as well using
                    <b>Shop Offer</b></span
                  >
                </div>
              </div>
              <!-- <div
                      *ngIf="
                        email && email.invalid && (email.dirty || email.touched)
                      "
                      class="text text-danger"
                    >
                      Email is required.
                    </div> -->
            </div>
          </div>
          <div [ngClass]="{ hidden: !isCat }" class="form-group row">
            <div class="col-xl-3 col-sm-4">
              <label for="validationCustom04" class="mb-0"
                >Select Category :</label
              >
            </div>
            <div class="col-xl-8 col-sm-7">
              <ng-multiselect-dropdown
                placeholder=""
                [data]="categories"
                name="categories"
                formControlName="categories"
                [settings]="dropdownSettings2"
                (onSelect)="onCatSelect($event)"
                (onDeSelect)="onCatDeSelect($event)"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <div [ngClass]="{ hidden: !isOffer }" class="form-group row">
            <div class="col-xl-3 col-sm-4">
              <label for="validationCustom04" class="mb-0"
                >Select Offer Category :<br />
                <span class="text-primary">(Need to Select 2)</span>
              </label>
            </div>
            <div class="col-xl-8 col-sm-7">
              <ng-multiselect-dropdown
                placeholder=""
                [data]="categories"
                name="categories"
                formControlName="categories"
                [settings]="dropdownSettings4"
                (onSelect)="onCatSelect($event)"
                (onDeSelect)="onCatDeSelect($event)"
              >
              </ng-multiselect-dropdown>
            </div>

            <div class="mt-2">
              <span class="text-danger"
                >If you are creating an “Offer Section”, please upload the
                equivalent Offer Images as well using <b>Offer Upload</b></span
              >
            </div>
          </div>
          <!-- <div [ngClass]="{ hidden: !isProduct }" class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom05" class="mb-0"
                      >Serach Product :</label
                    >
                  </div>
                  <div id="scrollMe" class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="search"
                      (input)="onInput($event); searched.isDropdownOpen = true"
                      placeholder="Please Enter search Link"
                    />
                  </div>
                </div> -->

          <div [ngClass]="{ hidden: !isProduct }" class="form-group row">
            <div class="col-xl-3 col-sm-4">
              <label for="validationCustom05" class="mb-0"
                >Select Product :</label
              >
            </div>
            <div id="scrollMe" class="col-xl-8 col-sm-7">
              <input
                class="form-control"
                id="validationCustom01"
                type="search"
                (input)="onInput($event); dropdownSettings3.defaultOpen = true"
                placeholder="Please Enter search Link"
              />
              <ng-multiselect-dropdown
                placeholder=""
                [data]="productLists"
                class="index"
                name="productList"
                formControlName="productList"
                [settings]="dropdownSettings3"
                (onSelect)="onProdSelect($event)"
                (onDeSelect)="onProdDeSelect($event)"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <div *ngIf="productsSelected.length >= 2" class="example-container">
            <h5>Drag & Drop to Sort</h5>

            <div
              cdkDropList
              class="example-list"
              (cdkDropListDropped)="drop($event)"
            >
              <ng-container
                *ngFor="let item of productsSelected; trackBy: trackByFn"
              >
                <div class="example-box backBg" cdkDrag>
                  {{ item.name }}
                </div>
              </ng-container>
            </div>
          </div>

          <!-- <label class="toggle-button">
                  <input type="checkbox" [(ngModel)]="isChecked" />
                  <span class="slider round"></span>
                </label> -->

          <div class="form-group row mb-0">
            <div class="col-sm-8 offset-xl-3 offset-sm-4">
              <div class="product-buttons">
                <button
                  *ngIf="offerActivate"
                  type="submit"
                  class="btn btn-primary me-1"
                  [disabled]="
                    addSectionForm?.invalid || this.catSavedData?.length < 2
                  "
                >
                  Add Offer
                </button>

                <button
                  *ngIf="!offerActivate"
                  type="submit"
                  class="btn btn-primary me-1"
                  [disabled]="addSectionForm?.invalid"
                >
                  Add
                </button>
                <button
                  type="button"
                  class="btn btn-light"
                  (click)="onDiscard()"
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        </form>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
