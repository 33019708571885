import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CreateShop } from "src/app/_models/shop/createShop";
import { LocationService } from "src/app/_services/location/location.service";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { ShopService } from "src/app/_services/shop/shop.service";
import Swal from "sweetalert2";
@Component({
  selector: "app-create-shop",
  templateUrl: "./create-shop.component.html",
  styleUrls: ["./create-shop.component.scss"],
})
export class CreateShopComponent implements OnInit {
  createShopForm: FormGroup;
  longitude: string;
  lattitude: string;
  userInfo: string;
  newUser: any;
  sellerId: any;
  selectedItems = [];
  savedData: any = [];
  timeZoneList = [
    {
      id: 1,
      name: "Eastern Standard Time (EST)",
    },
    {
      id: 2,
      name: "Central Standard Time (CST)",
    },
    {
      id: 3,
      name: "Mountain Standard Time (MST)",
    },
    {
      id: 4,
      name: "Pacific Standard Time (PST)",
    },
    {
      id: 5,
      name: "Alaska Standard Time (AKST)",
    },
    {
      id: 6,
      name: "Hawaii-Aleutian Standard Time (HAST)",
    },
  ];
  feeTypeList = [
    {
      id: 1,
      name: "Flat",
    },
    {
      id: 2,
      name: "Percentage",
    },
  ];
  deliveryNeed = [
    {
      id: 1,
      name: "Yes",
    },
    {
      id: 2,
      name: "No",
    },
  ];
  daysOfWeek: any[] = [];
  dropdownSettingsDays = {};
  dropdownList = [];
  dropdownSettings = {};
  needToDeliver: any;

  constructor(
    private formBuilder: FormBuilder,
    private shopService: ShopService,
    private locationService: LocationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sessionManager: SessionManagerService,
    private toastr: ToastrService
  ) {
    this.createShopForm = this.formBuilder.group({
      shopName: ["", [Validators.required]],
      shopAddress: ["", [Validators.required, this.noSpecialCharsValidator]],
      // shopAddress2: ["", [Validators.required, this.noSpecialCharsValidator]],
      shopCity: ["", [Validators.required, this.noSpecialCharsValidator]],
      shopState: ["", [Validators.required, this.noSpecialCharsValidator]],
      shopCountry: [""],
      deliveryFeeType: [""],
      deliveryFeeValue: ["", [Validators.pattern("^[0-9]*$")]],
      timeZone: ["", [Validators.required]],
      shopZipCode: ["", [Validators.required, this.noSpecialCharsValidator]],
      // brandname: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      openingTime: [""],
      closingTime: [""],
      deliveryNeed: [""],
      availablePickupTime: ["", [Validators.required]],
      availablePickupTimeTo: ["", [Validators.required]],
    });
    this.createShopForm.controls["timeZone"].setValue(0);
    this.createShopForm.controls["deliveryFeeType"].setValue(0);
    this.createShopForm.controls["deliveryNeed"].setValue(0);
  }

  ngOnInit(): void {
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    console.log("newUser", this.newUser);
    this.sellerId = this.newUser.userId;
    console.log("signedInUser", this.sellerId);
    this.getLocation();
    this.dropdownList = [
      { id: 1, name: "Monday" },
      { id: 2, name: "Tuesday" },
      { id: 3, name: "Wednesday" },
      { id: 4, name: "Thursday" },
      { id: 5, name: "Friday" },
      { id: 6, name: "Saturday" },
      { id: 7, name: "Sunday" },
    ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: "Pune" },
    //   { item_id: 4, item_text: "Navsari" },
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "name",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  onDeliveryChange() {
    let answer = this.createShopForm.value["deliveryNeed"];
    console.log("needToDeliver", this.needToDeliver);
    if (answer == "Yes") {
      this.needToDeliver = true;
    } else {
      this.needToDeliver = false;
    }
  }
  noSpecialCharsValidator(control) {
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const hasSpecialChars = specialChars.test(control.value);
    return hasSpecialChars ? { specialChars: true } : null;
  }

  getLocation() {
    this.locationService.getPosition().then((pos) => {
      console.log(`Positon: long-${pos.lng},lat- ${pos.lat}`);
      this.longitude = pos.lng;
      this.lattitude = pos.lat;
      console.log("long", this.longitude);
    });
  }
  onItemSelect(item: any) {
    this.savedData.push(item);
    this.savedData = Array.from(new Set(this.savedData));
    console.log("saved", this.savedData);
  }

  onItemDeSelect(item: any) {
    console.log("deselctitem", item);

    let filteredData = this.savedData.filter((data) => item.name != data.name);
    this.savedData = filteredData;
    console.log("deselct", this.savedData);
  }

  createShopEntity() {
    // let closingDays: any = "";
    // this.selectedItems?.forEach((day: any) => {
    //   closingDays = !closingDays ? day.name : closingDays + "," + day.name;
    // });
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd: CreateShop = {
          shopAddress:
            this.createShopForm.value["shopAddress"] +
            " " +
            this.createShopForm.value["shopCity"] +
            " " +
            this.createShopForm.value["shopState"] +
            " " +
            "US" +
            " " +
            this.createShopForm.value["shopZipCode"],
          shopName: this.createShopForm.value["shopName"],
          sellerId: this.sellerId,
          shopLongitude: this.longitude,
          // closingDays,
          shopLatitude: this.lattitude,
          deliveryFeeType: this.createShopForm.value["deliveryFeeType"],
          // brandname: this.createShopForm.value["brandname"],
          phoneNumber: this.createShopForm.value["phoneNumber"],
          availablePickupTime: this.createShopForm.value["availablePickupTime"],
          deliveryFeeValue: this.createShopForm.value["deliveryFeeValue"],
          availablePickupTimeTo:
            this.createShopForm.value["availablePickupTimeTo"],
          shopTimeZone: this.createShopForm.value["timeZone"],
          // openingTime:
          //   this.createShopForm.value["openingTime"] +
          //   " " +
          //   this.createShopForm.value["timeZone"],
          // closingTime:
          //   this.createShopForm.value["closingTime"] +
          //   " " +
          //   this.createShopForm.value["timeZone"],
        };

        console.log("fd", fd);
        this.shopService.createShop(fd).subscribe((response: any) => {
          console.log("shopRes", response);
          let shopId = response.shopId;
          this.showSpinner();

          if (response.response.status == "0") {
            Swal.fire("Shop Created Succesfully!", "", "success");
            this.router.navigate([
              "/shop/Add-Shop-Details",
              { id: shopId, path: "create" },
            ]);
          } else {
            this.toastr.error(response.response.statusDesc);
            // this.toastr.error("Something Went Wrong ! Please Try Again");
          }

          // this.onDiscard();
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  restrictComma(e: any) {
    if (e.keyCode === 188) {
      return false;
    } else {
      return true;
    }
  }

  onDiscard() {
    this.createShopForm.reset();
    this.createShopForm.controls["timeZone"].setValue(0);
    this.createShopForm.controls["deliveryFeeType"].setValue(0);
    this.createShopForm.controls["deliveryNeed"].setValue(0);
  }

  get shopName() {
    return this.createShopForm.get("shopName");
  }

  get shopAddress() {
    return this.createShopForm.get("shopAddress");
  }
  // get shopAddress2() {
  //   return this.createShopForm.get("shopAddress2");
  // }
  get shopCity() {
    return this.createShopForm.get("shopCity");
  }
  get shopState() {
    return this.createShopForm.get("shopState");
  }
  get shopCountry() {
    return this.createShopForm.get("shopCountry");
  }
  get shopZipCode() {
    return this.createShopForm.get("shopZipCode");
  }
  // get brandname() {
  //   return this.createShopForm.get("brandname");
  // }

  get phoneNumber() {
    return this.createShopForm.get("phoneNumber");
  }

  get timeZone() {
    return this.createShopForm.get("timeZone");
  }
  // get openingTime() {
  //   return this.createShopForm.get("openingTime");
  // }

  // get closingTime() {
  //   return this.createShopForm.get("closingTime");
  // }

  get availablePickupTime() {
    return this.createShopForm.get("availablePickupTime");
  }

  get availablePickupTimeTo() {
    return this.createShopForm.get("availablePickupTimeTo");
  }
}
