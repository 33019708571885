import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ProductListComponent } from "./physical/product-list/product-list.component";
import { AddProductComponent } from "./physical/add-product/add-product.component";
import { DigitalCategoryComponent } from "./digital/digital-category/digital-category.component";
import { DigitalSubCategoryComponent } from "./digital/digital-sub-category/digital-sub-category.component";
import { DigitalListComponent } from "./digital/digital-list/digital-list.component";
import { DigitalAddComponent } from "./digital/digital-add/digital-add.component";
import { ProductDetailComponent } from "./physical/product-detail/product-detail.component";
import { CreateVariantComponent } from "./physical/create-variant/create-variant.component";
import { CreateSizeComponent } from "./physical/create-size/create-size.component";
import { SaleComponent } from "./physical/sale/sale.component";
import { UploadPicturesComponent } from "./physical/upload-pictures/upload-pictures.component";
import { UploadbannersComponent } from "../category/uploadbanners/uploadbanners.component";
import { AdminProductListComponent } from "./physical/admin-product-list/admin-product-list.component";
import { ShopifyConnectComponent } from "./physical/shopify-connect/shopify-connect.component";
import { SquareConnectComponent } from "./physical/square-connect/square-connect.component";
import { ProductImportByCsvComponent } from "./physical/product-import-by-csv/product-import-by-csv.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "product-list",
        component: ProductListComponent,
        data: {
          title: "Product List",
          breadcrumb: "Product List",
        },
      },
      {
        path: "admin-product-list",
        component: AdminProductListComponent,
        data: {
          title: "All Product List",
          breadcrumb: "Product List",
        },
      },

      {
        path: "product-detail/:id",
        component: ProductDetailComponent,
        data: {
          title: "Product Detail",
          breadcrumb: "Product Detail",
        },
      },
      {
        path: "add-product",
        component: AddProductComponent,
        data: {
          title: "Add Products",
          breadcrumb: "Add Product",
        },
      },
      {
        path: "upload-pictures",
        component: UploadPicturesComponent,
        data: {
          title: "Upload Pictures",
          breadcrumb: "Upload Pictures",
        },
      },
      {
        path: "upload-pictures/:id",
        component: UploadPicturesComponent,
        data: {
          title: "Upload Pictures",
          breadcrumb: "Upload Pictures",
        },
      },
      {
        path: "create-variant",
        component: CreateVariantComponent,
        data: {
          title: "Create Variant",
          breadcrumb: "Create Variant",
        },
      },
      {
        path: "create-variant/:id",
        component: CreateVariantComponent,
        data: {
          title: "Create Variant",
          breadcrumb: "Create Variant",
        },
      },
      {
        path: "create-size",
        component: CreateSizeComponent,
        data: {
          title: "Create Size",
          breadcrumb: "Create Size",
        },
      },
      {
        path: "create-sale",
        component: SaleComponent,
        data: {
          title: "Create Sale",
          breadcrumb: "Create Sale",
        },
      },
      {
        path: "shopify-connect",
        component: ShopifyConnectComponent,
        data: {
          title: "Shopify Connect",
          breadcrumb: "Shopify Connect",
        },
      },
      {
        path: "square-connect",
        component: SquareConnectComponent,
        data: {
          title: "Square Connect",
          breadcrumb: "Square Connect",
        },
      },
      {
        path: "importByCSV",
        component: ProductImportByCsvComponent,
        data: {
          title: "Product Import",
          breadcrumb: "Product Import",
        },
      },
      {
        path: "create-sale/:id",
        component: SaleComponent,
        data: {
          title: "Create Sale",
          breadcrumb: "Create Sale",
        },
      },

      {
        path: "digital/digital-category",
        component: DigitalCategoryComponent,
        data: {
          title: "Category",
          breadcrumb: "Category",
        },
      },
      {
        path: "digital/digital-sub-category",
        component: DigitalSubCategoryComponent,
        data: {
          title: "Sub Category",
          breadcrumb: "Sub Category",
        },
      },
      {
        path: "digital/digital-product-list",
        component: DigitalListComponent,
        data: {
          title: "Product List",
          breadcrumb: "Product List",
        },
      },
      {
        path: "digital/digital-add-product",
        component: DigitalAddComponent,
        data: {
          title: "Add Products",
          breadcrumb: "Add Product",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {}
