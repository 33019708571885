import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, retry, throwError } from "rxjs";
import { CreateProduct } from "src/app/_models/product/createProduct";
import { CreateSize } from "src/app/_models/product/createSize";
import { CreateVariant } from "src/app/_models/product/createVariant";
import { CreateSale } from "src/app/_models/sale/createSale";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  api_url = environment.apiUrl;
  constructor(private http: HttpClient) {}

  //   POST
  // /v1/createProduct
  createProduct(productDetails: any) {
    return this.http
      .post(`${this.api_url}/v1/createProduct`, productDetails)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/taxCodeValidate/{taxCode}
  taxCodeValidate(taxCode: any) {
    return this.http
      .get(`${this.api_url}/v1/taxCodeValidate/` + taxCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/createInventory
  createInventory(productDetails: any) {
    return this.http
      .post(`${this.api_url}/v1/createInventory`, productDetails)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/getAllProduct

  getAllProducts() {
    return this.http
      .get(this.api_url + "/v1/getAllProduct")
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/successAdminToSellerTxn

  successAdminToSellerTxn(sessionId: any) {
    return this.http
      .post(`${this.api_url}/v1/successAdminToSellerTxn`, sessionId)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/storeList
  getStoreList(
    params: any,
    initial = "/v1/storeList",
    perPage: number
  ): Observable<any> {
    let temp = initial;
    if (!initial.includes("?")) {
      temp += "?";
    }

    for (let key in params) {
      temp += key + "=" + params[key] + "&";
    }

    if (!params.page) {
      temp += "page=0";
    }

    if (!params.perPage) {
      temp += "&perPage=" + perPage;
    }

    return this.http.get(this.api_url + `${temp}`);
  }

  // getStoreList(page: any, perPage: any) {
  //   return this.http
  //     .get(`${this.api_url}/v1/createProduct?page=${page}&perPage=${perPage}`)
  //     .pipe(retry(1), catchError(this.handleError));
  // }

  getProductList(
    // params: any,
    temp: any
    // initial = '/v1/storeList',
    // perPage: number
  ): Observable<any> {
    // let temp = initial;
    // if (!initial.includes('?')) {
    //   temp += '?';
    // }

    // for (let key in params) {
    //   temp += key + '=' + params[key] + '&';
    // }

    // if (!params.page) {
    //   temp += 'page=0';
    // }

    // if (!params.perPage) {
    //   temp += '&perPage=' + perPage;
    // }

    return this.http.get(this.api_url + `${temp}`);
  }

  //   GET
  // /v1/fetchAllProductsNew
  fetchAllProducts() {
    return this.http
      .get(this.api_url + "/v1/fetchAllProductsNew")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/createVariant

  createVariant(variantDetails: any) {
    return this.http
      .post(`${this.api_url}/v1/createVariant`, variantDetails)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/getVariantByProductId/{productId}
  getVariantByProductId(productId: any) {
    return this.http.get(
      this.api_url + "/v1/getVariantByProductId/" + productId
    );
  }

  //   GET
  // /v1/getProductById/{productId}
  // getProductById?productId=1&userId=21
  getProductById(productId: any, userId: any) {
    return this.http.get(
      this.api_url +
        `/v1/getProductById?productId=${productId}&userId=${userId}`
    );
  }

  //   POST
  // /v1/createSize
  createSize(sizeDetails: CreateSize) {
    return this.http
      .post(`${this.api_url}/v1/createSize`, sizeDetails)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/uploadFile
  postPicture(data: any) {
    return this.http
      .post(`${this.api_url}/v1/uploadFile`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // "https://brain.predis.ai/tools/remove_bg/";

  convertPicture(data: any) {
    return this.http
      .post(`https://brain.predis.ai/tools/remove_bg/`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/createSaleEntity

  createSale(saleDetails: CreateSale) {
    return this.http
      .post(`${this.api_url}/v1/createSale`, saleDetails)
      .pipe(retry(1), catchError(this.handleError));
  }

  getPictureByProductId(productId: any) {
    return this.http
      .get(this.api_url + "/v1/getPicturesByProductId/" + productId)
      .pipe(retry(1), catchError(this.handleError));
  }

  getComponentByProductId(productId: any) {
    return this.http
      .get(this.api_url + "/v1/getComponentProductId/" + productId)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/orderList

  // public getOrderList() {
  // let api_key = sessionStorage.getItem("u-token");
  // const headers = new HttpHeaders({
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ${api_key}`,
  // });
  // let requestOption = { headers };
  //   return this.http
  //     .get<any>(`${environment.apiUrl}/v1/orderList`)
  //     .pipe(retry(1), catchError(this.handleError));
  // }

  // GET
  // /v1/getOrderProductListByToken

  //  GET
  // /v1/fetchAllActiveProductsForHomePage
  fetchAllProductsForHomePage() {
    return this.http
      .get(this.api_url + "/v1/fetchAllActiveProductsForHomePage")
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/home/section

  addHomeSection(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/home/section`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/fetchCategoryImagebySectionId

  fetchCategoryImagebySectionId(sectionId: any) {
    return (
      this.http
        .get(
          `${this.api_url}/v1/fetchCategoryImagebySectionId?offerId=${sectionId}`
        )
        // fetchCategoryImagebySectionId?offerId=36'
        .pipe(retry(1), catchError(this.handleError))
    );
  }

  // GET
  // /v1/fetchImagebySectionId
  fetchImagebySectionId(sectionId: any) {
    return (
      this.http
        .get(`${this.api_url}/v1/fetchImagebySectionId?sectionId=${sectionId}`)
        // fetchCategoryImagebySectionId?offerId=36'
        .pipe(retry(1), catchError(this.handleError))
    );
  }

  // POST
  // /v1/uploadCategoryImage
  uploadCategoryImage(data: any) {
    return this.http
      .post(`${this.api_url}/v1/uploadCategoryImage`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/fetchallsections
  fetchallsections() {
    return this.http
      .get(this.api_url + "/v1/fetchallsections")
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/fetchSectionsDataBySectionId/{sectionId}

  fetchSectionsDataBySectionId(sectionId: any) {
    return this.http
      .get(this.api_url + "/v1/fetchSectionsDataBySectionId1/" + sectionId)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/home/editsection

  editsection(data: any) {
    return this.http
      .post(this.api_url + "/v1/home/editsection", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  editsectionnew(data: any) {
    return this.http
      .post(this.api_url + "/v1/home/editsectionnew", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/publishSelectedProduct

  publishSelectedProduct(data: any) {
    return this.http
      .post(this.api_url + "/v1/publishSelectedProduct", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/unpublishSelectedProduct

  unpublishSelectedProduct(data: any) {
    return this.http
      .post(this.api_url + "/v1/unpublishSelectedProduct", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/fetchAllOfferSections
  fetchAllOfferSections() {
    return this.http
      .get(this.api_url + "/v1/fetchAllOfferSections")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/fetchCategoryByOfferId/{offerId}

  fetchCategoryByOfferId(offerId: any) {
    return this.http
      .get(this.api_url + "/v1/fetchCategoryByOfferId/" + offerId)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/fetchShopsByShopOfferId/{shopOfferId}

  fetchShopsByShopOfferId(offerId: any) {
    return this.http
      .get(this.api_url + "/v1/fetchShopsByShopOfferId/" + offerId)
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteSection(sectionId: any) {
    let data = {};
    return this.http
      .post(`${this.api_url}/v1/deleteSectionBySectionId/` + sectionId, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/fetchSectionsData

  fetchSectionsData() {
    return this.http
      .get(this.api_url + "/v1/fetchSectionsData")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/importProducts
  importProducts(data: any) {
    return this.http
      .post(this.api_url + "/v1/importProducts", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/fetchShopifyCredentials
  fetchShopifyCredentials(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/fetchShopifyCredentials`, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  // POST
  // /v1/saveShopifyCredentials
  saveShopifyCredentials(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/saveShopifyCredentials`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/shopifyConnectionTest
  shopifyConnectionTest(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/shopifyConnectionTest`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/shopifyProductsSync
  shopifyProductsSync(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/shopifyProductsSync`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/fetchSquareCredentials
  fetchSquareCredentials(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/fetchSquareCredentials`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/saveSquareCredentials
  saveSquareCredentials(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/saveSquareCredentials`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/squareConnectionTest
  squareConnectionTest(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/squareConnectionTest`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/squareProductsSync
  squareProductsSync(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/squareProductsSync`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  getOrderList() {
    return this.http
      .get<any>(`${environment.apiUrl}/v1/getOrderProductListByToken`)
      .pipe(retry(1), catchError(this.handleError));
  }
  // GET
  // /v1/GetOrderById/{orderId}
  getOrderDetailsByOrderId(orderId: any) {
    return this.http.get(this.api_url + "/v1/GetOrderById/" + orderId);
  }

  // GET
  // /v1/GetOrderProductById/{orderProductId}
  getOrderDetailsByorderProductId(orderProductId: any) {
    return this.http.get(
      this.api_url + "/v1/GetOrderProductById/" + orderProductId
    );
  }

  // GET
  // /v1/orderComplete/{orderProductId}
  orderCompleteByorderProductId(orderProductId: any) {
    return this.http.get(this.api_url + "/v1/orderComplete/" + orderProductId);
  }

  // GET
  // /v1/orderReadyForPickup/{orderProductId}
  orderReadyForPickupByorderProductId(orderProductId: any) {
    return this.http.get(
      this.api_url + "/v1/orderReadyForPickup/" + orderProductId
    );
  }

  //   POST
  // /v1/deleteProductByProductId/{productId}
  deleteProductByProductId(productId: any) {
    let data = {};
    return this.http
      .post(`${this.api_url}/v1/deleteProductByProductId/` + productId, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // DELETE
  // /v1/DeleteFile/{pictureId}

  deleteProductImg(pictureId: any) {
    return this.http
      .delete(`${this.api_url}/v1/DeleteFile/` + pictureId)
      .pipe(retry(1), catchError(this.handleError));
  }

  // /v1/getProductsBySellerId?sellerId=21
  getProductsBySellerId(sellerId: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/v1/getProductsBySellerId?sellerId=${sellerId}`
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  //   PUT
  // /v1/updateProduct
  updateProduct(data: any) {
    return this.http
      .put(this.api_url + "/v1/updateProduct", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   PUT
  // /v1/updateComponent

  updateComponent(data: any) {
    return this.http
      .put(this.api_url + "/v1/updateComponent", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // PUT
  // /v1/updateCSale

  updateCSale(data: any) {
    return this.http
      .put(this.api_url + "/v1/updateCSale", data)
      .pipe(retry(1), catchError(this.handleError));
  }

  //  PUT
  // /v1/publishProduct/{productId}

  publishProduct(produtId: any) {
    let data = {};
    // return this.http
    // .put(`${this.api_url}/v1/publishProduct?sellerId=${produtId}`, data)
    return this.http
      .put(this.api_url + "/v1/publishProduct/" + produtId, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
