import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { response } from "express";
import { NgxSpinnerService } from "ngx-spinner";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { ShopService } from "src/app/_services/shop/shop.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-shop-list",
  templateUrl: "./shop-list.component.html",
  styleUrls: ["./shop-list.component.scss"],
})
export class ShopListComponent implements OnInit {
  editShopForm: FormGroup;
  shopList: any;
  userInfo: any;
  newUser: any;
  signedInUser: any;
  userId: any;
  updateClick: boolean = false;
  @ViewChild("onEdit") onEdit!: ElementRef;
  shopId: any;
  daysOfWeek: any[] = [];
  dropdownSettingsDays = {};
  dropdownList = [];
  dropdownSettings = {};
  selectedItems = [];
  savedData: any = [];
  timeZoneList = [
    {
      id: 1,
      name: "Eastern Standard Time (EST)",
    },
    {
      id: 2,
      name: "Central Standard Time (CST)",
    },
    {
      id: 3,
      name: "Mountain Standard Time (MST)",
    },
    {
      id: 4,
      name: "Pacific Standard Time (PST)",
    },
    {
      id: 5,
      name: "Alaska Standard Time (AKST)",
    },
    {
      id: 6,
      name: "Hawaii-Aleutian Standard Time (HAST)",
    },
  ];
  feeTypeList = [
    {
      id: 1,
      name: "Flat",
    },
    {
      id: 2,
      name: "Percentage",
    },
  ];
  constructor(
    private formBuilder: FormBuilder,
    private shopService: ShopService,
    private sessionManager: SessionManagerService,
    private spinner: NgxSpinnerService
  ) {
    this.editShopForm = this.formBuilder.group({
      shopName: [""],
      shopAddress: ["", [this.noSpecialCharsValidator]],
      phoneNumber: [""],
      openingTime: [""],
      closingTime: [""],
      timeZone: [""],
      availablePickupTime: [""],
      availablePickupTimeTo: [""],
      deliveryFeeType: [""],
      deliveryFeeValue: [""],
    });
    this.editShopForm.controls["timeZone"].setValue(0);
    this.editShopForm.controls["deliveryFeeType"].setValue(0);
  }

  ngOnInit(): void {
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    // console.log("newUser", this.newUser);
    this.signedInUser = this.newUser.groups[0];
    this.userId = this.newUser.userId;
    console.log("signedInUser", this.signedInUser);
    console.log("userId", this.userId);
    this.getShopList();
    this.dropdownList = [
      { id: 1, name: "Monday" },
      { id: 2, name: "Tuesday" },
      { id: 3, name: "Wednesday" },
      { id: 4, name: "Thursday" },
      { id: 5, name: "Friday" },
      { id: 6, name: "Saturday" },
      { id: 7, name: "Sunday" },
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "name",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    this.showSpinner();
  }
  noSpecialCharsValidator(control) {
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const hasSpecialChars = specialChars.test(control.value);
    return hasSpecialChars ? { specialChars: true } : null;
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  getShopList() {
    this.shopService
      .getShopSBySellerId(this.userId)
      .subscribe((response: any) => {
        this.shopList = response;
        console.log("shop", response);
      });
  }

  onEditClick(editedShop: any) {
    console.log("editedShop", editedShop);
    this.shopId = editedShop.shopId;
    window.scrollTo(0, 0);
    this.editShopForm.patchValue({
      shopName: editedShop.shopName,
      phoneNumber: editedShop.phoneNumber ? editedShop.phoneNumber : "N/A",
      shopAddress: editedShop.shopAddress,
      // openingTime: editedShop.openingTime,
      // closingTime: editedShop.closingTime,
      deliveryFeeType: editedShop.deliveryFeeType,
      deliveryFeeValue: editedShop.deliveryFeeValue || "N/A",
    });
  }
  onItemSelect(item: any) {
    this.savedData.push(item);
    this.savedData = Array.from(new Set(this.savedData));
    console.log("saved", this.savedData);
  }

  onItemDeSelect(item: any) {
    console.log("deselctitem", item);

    let filteredData = this.savedData.filter((data) => item.name != data.name);
    this.savedData = filteredData;
    console.log("deselct", this.savedData);
  }
  onSubmit() {
    // let closingDays: any = "";
    // this.selectedItems?.forEach((day: any) => {
    //   closingDays = !closingDays ? day.name : closingDays + "," + day.name;
    // });
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.updateClick = true;
        let fd = {
          shopId: this.shopId,
          shopName: this.editShopForm.value["shopName"],
          phoneNumber: this.editShopForm.value["phoneNumber"],
          shopAddress: this.editShopForm.value["shopAddress"],
          // openingTime: this.editShopForm.value["openingTime"],
          // closingTime: this.editShopForm.value["closingTime"],
          availablePickupTime: this.editShopForm.value["availablePickupTime"],
          deliveryFeeType: this.editShopForm.value["deliveryFeeType"],
          deliveryFeeValue: this.editShopForm.value["deliveryFeeValue"],

          availablePickupTimeTo:
            this.editShopForm.value["availablePickupTimeTo"],
          // openingTime: this.editShopForm.value["timeZone"]
          //   ? this.editShopForm.value["openingTime"].split(" ")[0] +
          //     " " +
          //     this.editShopForm.value["timeZone"]
          //   : this.editShopForm.value["openingTime"],
          // closingTime: this.editShopForm.value["timeZone"]
          //   ? this.editShopForm.value["closingTime"].split(" ")[0] +
          //     " " +
          //     this.editShopForm.value["timeZone"]
          //   : this.editShopForm.value["closingTime"],
          // closingDays,
        };
        console.log("fd", fd);
        // return;
        this.shopService.editShop(fd).subscribe((resp: any) => {
          console.log("updatesale", resp);
          this.getShopList();
          this.savedData = [];
          this.showSpinner();
          // this.updateClick = false;

          // this.onEditClick.nativeElement.click();
          this.onCancel();
          // this.editProductForm.reset();
          Swal.fire("Shop Updated Succesfully!", "", "success");
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onCancel() {
    this.updateClick = false;
    this.editShopForm.reset();
    this.savedData = [];
    this.selectedItems = [];
    this.onEdit.nativeElement.click();
    this.editShopForm.controls["timeZone"].setValue(0);
  }
}
