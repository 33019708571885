import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, retry, throwError } from "rxjs";
import { Category, CategoryList } from "src/app/_models/category/category";
import { Size, SizeList } from "src/app/_models/category/size";
import { SubCategoryList } from "src/app/_models/subCategory/subCategory";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CategoryServiceService {
  api_url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  //   POST
  // /v1/createCategory
  createCategory(Category: CategoryList) {
    return this.http
      .post(`${this.api_url}/v1/createCategory`, Category)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/deleteCategory/{categoryId}

  deleteCategory(id: any) {
    let data = {};
    return this.http
      .post(`${this.api_url}/v1/deleteCategory/${id}`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   POST
  // /v1/createSize
  createSize(Size: SizeList) {
    return this.http
      .post(`${this.api_url}/v1/createSize`, Size)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/addGeocheck/{geocheck}
  addGeocheck(geocheck: any) {
    return this.http
      .get(`${this.api_url}/v1/addGeocheck/${geocheck}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/getGeocheck

  getGeocheck() {
    return this.http
      .get<Category[]>(this.api_url + "/v1/getGeocheck")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/getAdminInfo

  getAdminDeliveryInfo() {
    return this.http
      .get(this.api_url + "/v1/getAdminInfo")
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/addAdminDeliveryDetails
  addAdminDeliveryDetails(data: any) {
    return this.http
      .post(`${this.api_url}/v1/addAdminDeliveryDetails`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/createSubCategory
  createSubCategory(SubCategory: SubCategoryList) {
    return this.http
      .post(`${this.api_url}/v1/createSubCategory`, SubCategory)
      .pipe(retry(1), catchError(this.handleError));
  }

  // POST
  // /v1/deleteSubCategory/{subcategoryId}
  deleteSubCategory(id: any) {
    let data = {};
    return this.http
      .post(`${this.api_url}/v1/deleteSubCategory/${id}`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/getAllCategory

  // getAllCategory() {
  //   return this.http
  //     .get(this.api_url + "/v1/getAllCategory")
  //     .pipe(retry(1), catchError(this.handleError));
  // }
  getAllCategory() {
    return this.http
      .get<Category[]>(this.api_url + "/v1/getAllCategory")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/getAllActiveCategory

  public getAllActiveCategory(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/v1/getAllActiveCategory`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllSize() {
    return this.http
      .get<Size[]>(this.api_url + "/v1/getAllSize")
      .pipe(retry(1), catchError(this.handleError));
  }

  //   DELETE
  // /v1/deleteSize/{sizeId}

  deleteSize(sizeId: any) {
    return this.http
      .delete(`${this.api_url}/v1/deleteSize/${sizeId}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/getAllSubCategory

  getAllSubCategory() {
    return this.http
      .get(this.api_url + "/v1/getAllSubCategory")
      .pipe(retry(1), catchError(this.handleError));
  }

  // GET
  // /v1/getCategoryByCategoryId/{categoryid}
  getCategoryById(categoryid: any) {
    return this.http.get(
      this.api_url + "/v1/getCategoryByCategoryId/" + categoryid
    );
  }

  //   POST
  // /v1/uploadBanner
  uploadBanner(data: any) {
    return this.http
      .post(`${this.api_url}/v1/uploadBanner`, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  // POST
  // /v1/deleteBanners/{bannerId}
  // deleteBanner(id: any) {
  //   let data = {};
  //   return this.http
  //     .post(`${this.api_url}/v1/deleteBanners/${id}`, data)
  //     .pipe(retry(1), catchError(this.handleError));
  // }

  // DELETE
  // /v1/deleteBanners/{bannerId}
  deleteBanner(bannerId: any) {
    return this.http
      .delete(`${this.api_url}/v1/deleteBanners/${bannerId}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  // PUT;
  // /v1/softDeleteBanners/{bannerId}
  softDeleteBanners(bannerId: any) {
    let data = {};
    return this.http
      .put(`${this.api_url}/v1/softDeleteBanners/${bannerId}`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   PUT
  // /v1/softDeleteCategory/{categoryId}
  softDeleteCategory(categoryId: any) {
    let data = {};
    return this.http
      .put(`${this.api_url}/v1/softDeleteCategory/${categoryId}`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // PUT
  // /v1/softDeleteSubCategory/{subcategoryId}

  softDeleteSubcategory(subCategoryId: any) {
    let data = {};
    return this.http
      .put(`${this.api_url}/v1/softDeleteSubCategory/${subCategoryId}`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   GET
  // /v1/getAllBanners
  getAllBanners() {
    return this.http.get(this.api_url + "/v1/getAllBanners");
  }
  //   GET
  // /v1/getSubcategoryById/{categoryId}

  getSubCategoryByCategoryId(categoryid: any) {
    return this.http.get(this.api_url + "/v1/getSubcategoryById/" + categoryid);
  }

  //   GET
  // /v1/getBannerById/{bannerId}
  getBannerByBannerId(bannerId: any) {
    return this.http.get(this.api_url + "/v1/getBannerById/" + bannerId);
  }

  // PUT
  // /v1/editBanner/{bannerId}
  updateBanner(data: any) {
    return this.http.put(this.api_url + "/v1/editBanner", data);
  }

  // PUT
  // /v1/updateCategory/{categoryId}
  updateCategory(categoryid: any, data) {
    return this.http.put(
      this.api_url + "/v1/updateCategory/" + categoryid,
      data
    );
  }
  // PUT
  // /v1/updateSubCategory/{subcategoryId}

  updateSubCategory(subCategoryid: any, data) {
    return this.http.put(
      this.api_url + "/v1/updateSubCategory/" + subCategoryid,
      data
    );
  }

  updateSize(sizeId: any, data) {
    return this.http.put(this.api_url + "/v1/updateSize/" + sizeId, data);
  }
  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
