import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// import { Ng2SmartTableModule } from 'ng2-smart-table';
// import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { ProductsRoutingModule } from "./products-routing.module";

import { ProductListComponent } from "./physical/product-list/product-list.component";
import { AddProductComponent } from "./physical/add-product/add-product.component";
import { DigitalCategoryComponent } from "./digital/digital-category/digital-category.component";
import { DigitalSubCategoryComponent } from "./digital/digital-sub-category/digital-sub-category.component";
import { DigitalListComponent } from "./digital/digital-list/digital-list.component";
import { DigitalAddComponent } from "./digital/digital-add/digital-add.component";
import { ProductDetailComponent } from "./physical/product-detail/product-detail.component";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import "hammerjs";
import "mousetrap";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { TagInputModule } from "ngx-chips";
import { NgxDropzoneModule } from "ngx-dropzone";
// search module
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SharedModule } from "src/app/shared/shared.module";
import { CreateVariantComponent } from "./physical/create-variant/create-variant.component";
import { CreateSizeComponent } from "./physical/create-size/create-size.component";
import { ToastrModule } from "ngx-toastr";
import { SaleComponent } from "./physical/sale/sale.component";
import { UploadPicturesComponent } from "./physical/upload-pictures/upload-pictures.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UploadbannersComponent } from "../category/uploadbanners/uploadbanners.component";
import { AdminProductListComponent } from "./physical/admin-product-list/admin-product-list.component";
import { QuillModule } from "ngx-quill";
import { ShopifyConnectComponent } from "./physical/shopify-connect/shopify-connect.component";
import { SquareConnectComponent } from "./physical/square-connect/square-connect.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ProductImportByCsvComponent } from './physical/product-import-by-csv/product-import-by-csv.component';
// import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
// import {  } from '../../directives/shorting.directive/';

@NgModule({
  declarations: [
    ProductListComponent,
    AddProductComponent,
    DigitalCategoryComponent,
    DigitalSubCategoryComponent,
    DigitalListComponent,
    DigitalAddComponent,
    ProductDetailComponent,
    CreateVariantComponent,
    CreateSizeComponent,
    SaleComponent,
    UploadPicturesComponent,
    UploadbannersComponent,
    AdminProductListComponent,
    ShopifyConnectComponent,
    SquareConnectComponent,
    ProductImportByCsvComponent,
  ],
  imports: [
    Ng2SearchPipeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProductsRoutingModule,
    NgbModule,
    GalleryModule,
    CKEditorModule,
    NgxDropzoneModule,
    TagInputModule,
    ImageCropperModule,
    SharedModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction

          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ["clean"], // remove formatting button

          // ["link", "image", "video"], // link and image, video
        ],
      },
    }),
  ],
  exports: [],
  bootstrap: [],
  providers: [NgbActiveModal],
})
export class ProductsModule {}
