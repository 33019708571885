import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MenusRoutingModule } from "./menus-routing.module";
import { ListMenuComponent } from "./list-menu/list-menu.component";
import { CreateMenuComponent } from "./create-menu/create-menu.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { OrderListComponent } from "./order-list/order-list.component";
import { WithdrawalReportComponent } from "./withdrawal-report/withdrawal-report.component";
import { StripeOnboardComponent } from "./stripe-onboard/stripe-onboard.component";
import { OrderDetailsComponent } from "./order-details/order-details.component";
import { AdminOrderDetailsComponent } from './admin-order-details/admin-order-details.component';

@NgModule({
  declarations: [
    ListMenuComponent,
    CreateMenuComponent,
    OrderListComponent,
    WithdrawalReportComponent,
    StripeOnboardComponent,
    OrderDetailsComponent,
    AdminOrderDetailsComponent,
  ],
  imports: [CommonModule, MenusRoutingModule, NgbModule, SharedModule],
})
export class MenusModule {}
