import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "src/app/_services/auth/authentication.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  public active = 1;
  SellerDetails: any;
  selleFirstName: any;
  selleLastName: any;
  updateProfileForm: any;
  @ViewChild("pro") pro!: ElementRef;
  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.updateProfileForm = this.formBuilder.group({
      fullName: [""],
      mobileNumber: [""],
    });
  }

  ngOnInit() {
    this.showSpinner();
    this.getSellerInfo();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  getSellerInfo() {
    this.authService.getSellerDetailsByToken().subscribe((res: any) => {
      this.SellerDetails = res;
      this.selleFirstName = this.SellerDetails?.fullName.split(" ")[0];
      this.selleLastName = this.SellerDetails?.fullName.split(" ")[1];
      console.log("this.SellerDetails", this.SellerDetails);

      setTimeout(() => {
        this.updateProfileForm.patchValue({
          fullName: this.SellerDetails?.fullName,
          mobileNumber: this.SellerDetails?.mobileNumber || "N/A",
        });
      }, 10);
    });
  }

  updateProfile() {
    let fd = {
      id: this.SellerDetails?.id,
      fullName: this.updateProfileForm.value["fullName"],
      mobileNumber: this.updateProfileForm.value["mobileNumber"],
    };
    console.log("fd", fd);

    this.authService.updateSellerProfile(fd).subscribe((res: any) => {
      console.log("res", res);
      if (res.status == 0) {
        this.toastr.success("Profile Update Successfully");
        this.showSpinner();
        this.pro.nativeElement.click();
        this.getSellerInfo();
      } else {
        this.toastr.error("Something Went Wrong! Please Try Again");
      }
    });
  }

  onDiscard() {
    this.getSellerInfo();
    this.pro.nativeElement.click();
  }

  get fullName() {
    return this.updateProfileForm.get("fullName");
  }

  get mobileNumber() {
    return this.updateProfileForm.get("mobileNumber");
  }
}
