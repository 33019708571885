import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AppService } from "src/app/_services/appService/app.service";

@Component({
  selector: "app-crm-list",
  templateUrl: "./crm-list.component.html",
  styleUrls: ["./crm-list.component.scss"],
})
export class CrmListComponent implements OnInit {
  page: number = 1;
  pageSize: number = 10;
  sellerCrmListLength: any;
  sellerCrmList: any = [];
  isResend: boolean = false;
  constructor(
    private appSrvice: AppService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.showSpinner();
    this.getAllSellersCrm();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  getAllSellersCrm() {
    this.appSrvice.getAllCrm().subscribe((res: any) => {
      this.sellerCrmListLength = res.length;
      this.sellerCrmList = res
        .map((result: any, index: any) => {
          return {
            ...result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );

      console.log("sellerCrmList", this.sellerCrmList);
    });
  }

  onResendClick(sellerDetails: any) {
    this.isResend = true;
    console.log("sellerDetails", sellerDetails);

    let fdResend = {
      crmId: sellerDetails.crmId,
      firstName: sellerDetails.firstName,
      lastName: sellerDetails.lastName,
      sellerEmail: sellerDetails.sellerEmail,
      sellerMobile: sellerDetails.sellerMobile,
      sellerInformation: sellerDetails.sellerInformation,
      sellerAddress: sellerDetails.sellerAddress,
    };
    this.appSrvice.sendRepeatEmail(fdResend).subscribe((response: any) => {
      if (response.status == "0") {
        this.isResend = false;
        this.showSpinner();
        this.getAllSellersCrm();
        this.toastr.success("Email Resend Succesfully");
      } else {
        this.toastr.error("Something Went Wrong !,Try Again");
        this.isResend = false;
      }
    });
  }
}
