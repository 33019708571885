import { Token } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ShopCategory } from "src/app/_models/shop/shopCategory";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { LocationService } from "src/app/_services/location/location.service";
import { ShopService } from "src/app/_services/shop/shop.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-shop-category",
  templateUrl: "./shop-category.component.html",
  styleUrls: ["./shop-category.component.scss"],
})
export class ShopCategoryComponent implements OnInit {
  createShopCategoryForm!: FormGroup;
  categoryList: any[] = [];
  shopList: any[] = [];
  category_Id: number;
  shop_id: number;
  savedData: any = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "category",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  token: any;
  constructor(
    private formBuilder: FormBuilder,
    private categoryService: CategoryServiceService,
    private shopService: ShopService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public activeRoute: ActivatedRoute
  ) {
    this.createShopCategoryForm = this.formBuilder.group({
      categoryId: ["", [Validators.required]],
      shopId: ["", [Validators.required]],
    });
    // this.createShopCategoryForm.controls["categoryId"].setValue(0);
    this.createShopCategoryForm.controls["shopId"].setValue(0);
  }

  ngOnInit(): void {
    this.showSpinner();
    this.getAllCategories();
    // this.getAllShops();
    this.activeRoute.paramMap.subscribe((param: any) => {
      let shopId = param.get("id") || 0;
      this.getAllShops(shopId);
    });
    //   this.token = sessionStorage.getItem("user");
    //   console.log("this.token", this.token);
    //   if (this.token) {
    //     this.getAllShops();
    //   }
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  onItemSelect(item: any) {
    this.savedData.push(item);
    console.log("saved", this.savedData);
  }

  onItemDeSelect(item: any) {
    console.log("deselct", item);

    let filteredData = this.savedData.filter((data) => item.id != data.id);
    this.savedData = filteredData;
    console.log("deselct", this.savedData);
  }

  // onSelectCategory() {
  //   this.category_Id = this.createShopCategoryForm.value["categoryId"];
  // }
  onSelectShop() {
    this.shop_id = this.createShopCategoryForm.value["shopId"];
  }

  getAllCategories() {
    this.categoryService.getAllCategory().subscribe((res: any) => {
      this.categoryList = res.categoryList;
      console.log("this.categories", this.categoryList);
    });
  }

  getAllShops(shopId?) {
    this.shopService.getAllShops().subscribe((res: any) => {
      this.shopList = res;
      this.createShopCategoryForm.get("shopId").setValue(shopId);
      console.log("this.shopList", this.shopList);
    });
  }

  createShopCategory() {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd: ShopCategory = {
          shopId: this.createShopCategoryForm.value["shopId"],
          shopName: this.createShopCategoryForm.value["shopName"],
          categories: this.savedData,
        };
        this.shopService
          .createShopCategoryEntity(fd)
          .subscribe((response: any) => {
            console.log("shopCatRes", response);
            this.onDiscard();
            this.showSpinner();
            Swal.fire("Shop Category Created Succesfully!", "", "success");
            this.router.navigate(["/products/add-product"]);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onDiscard() {
    this.selectedItems = [];
    this.createShopCategoryForm.reset();
    this.createShopCategoryForm.controls["shopId"].setValue(0);
    // this.createShopCategoryForm.controls["categoryId"].setValue(0);
  }

  get categoryId() {
    return this.createShopCategoryForm.get("categoryId");
  }

  get shopId() {
    return this.createShopCategoryForm.get("shopId");
  }
}
