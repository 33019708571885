import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AddSectionsComponent } from "./add-sections/add-sections.component";
import { OfferImageUploadComponent } from "./offer-image-upload/offer-image-upload.component";
import { SectionListComponent } from "./section-list/section-list.component";
import { CategoryImgUploadComponent } from "./category-img-upload/category-img-upload.component";
import { EditSectionComponent } from "./edit-section/edit-section.component";
import { ShopOfferUploadComponent } from "./shop-offer-upload/shop-offer-upload.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "add-sections",
        component: AddSectionsComponent,
        data: {
          title: "Add Sections",
          breadcrumb: "Add Sections",
        },
      },
      {
        path: "offer-upload",
        component: OfferImageUploadComponent,
        data: {
          title: "Offer Upload",
          breadcrumb: "Offer Upload",
        },
      },
      {
        path: "shop-offer",
        component: ShopOfferUploadComponent,
        data: {
          title: "Shop Offer",
          breadcrumb: "Shop Offer",
        },
      },
      {
        path: "categoryImg-upload",
        component: CategoryImgUploadComponent,
        data: {
          title: "Category Upload",
          breadcrumb: "Category Upload",
        },
      },
      {
        path: "section-list",
        component: SectionListComponent,
        data: {
          title: "Section List",
          breadcrumb: "Section List",
        },
      },

      {
        path: "add-sections/edit-section/:id",
        component: EditSectionComponent,
        data: {
          title: "Edit Section",
          breadcrumb: "Edit Section",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}
