import { Component, OnInit } from "@angular/core";
import * as chartData from "../../shared/data/chart";
import { doughnutData, pieData } from "../../shared/data/chart";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { ChartData, ChartOptions, ChartType } from "chart.js";
import { AppService } from "src/app/_services/appService/app.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  userInfo: any;
  newUser: any;
  signedInUser: any;
  userId: any;
  sellerDashboardInfo: any = {};
  yearRevenue: any;
  constructor(
    private sessionManager: SessionManagerService,
    private AppService: AppService
  ) {
    Object.assign(this, { doughnutData, pieData });
  }

  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;

  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;

  // events
  public chartClicked(e: any): void {}
  public chartHovered(e: any): void {}

  ngOnInit() {
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    // console.log("newUser", this.newUser);
    this.signedInUser = this.newUser.groups[0];
    this.userId = this.newUser.userId;
    console.log("signedInUser", this.signedInUser);
    console.log("userId", this.userId);

    this.getSellerDashBoardDetails();
  }

  // chartType: ChartType = "line";

  // data: ChartData<"line"> = {
  //   labels: ["one", "two", "three"],
  //   datasets: [
  //     {
  //       label: "data 1",
  //       data: this.sellerDashboardInfo.revenueThisYear,
  //     },
  //     // {
  //     //   label: "data 2",
  //     //   data: [100, 250, 50],
  //     // },
  //   ],
  // };

  // options: ChartOptions<"line"> = {
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //     },
  //   },
  // };

  getSellerDashBoardDetails() {
    this.AppService.getSellerDashboardDetailsBySellerId(this.userId).subscribe(
      (res: any) => {
        this.sellerDashboardInfo = res;
        console.log(this.datas, "line");
        // this.data = this.sellerDashboardInfo.revenueThisYear;
        console.log("this.sellerDashboardInfo", this.sellerDashboardInfo);
        this.data = {
          labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          datasets: [
            {
              label: "Revenue Per Day",
              data: this.sellerDashboardInfo?.weeklyRevenue,
            },
            // {
            //   label: "data 2",
            //   data: [100, 250, 50],
            // },
          ],
        };
        // this.data.datasets[0].data = this.sellerDashboardInfo?.revenueThisYear;

        this.datas = {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Revenue Per Month",
              data: this.sellerDashboardInfo?.revenueThisYear,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        };
      }
    );
  }
  chartType: ChartType = "line";

  data: ChartData<"line"> = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "data 1",
        data: this.sellerDashboardInfo?.revenueThisYear,
      },
      // {
      //   label: "data 2",
      //   data: [100, 250, 50],
      // },
    ],
  };

  options: ChartOptions<"line"> = {
    scales: {
      y: {
        beginAtZero: false,
      },
    },
  };

  // labels = Utils.months({ count: 7 });
  datas = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Revenue Per Month",
        data: [],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
}
