import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthRoutingModule } from "./auth-routing.module";
import { LoginComponent } from "./login/login.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SharedModule } from "../../shared/shared.module";
import { VerificationPageComponent } from "./verification-page/verification-page.component";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";

@NgModule({
  declarations: [
    LoginComponent,
    VerificationPageComponent,
    ForgetPasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    SharedModule,
    NgbModule,
  ],
})
export class AuthModule {}
