import { Routes } from "@angular/router";
import { AuthGuard } from "src/app/_helpers/auth.guard";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "category",
    loadChildren: () =>
      import("../../components/category/category.module").then(
        (m) => m.CategoryModule
      ),
    data: {
      breadcrumb: "Category",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "shop",
    loadChildren: () =>
      import("../../components/shop/shop.module").then((m) => m.ShopModule),
    data: {
      breadcrumb: "Shop",
    },
    canActivate: [AuthGuard],
  },

  {
    path: "orderListAdmin",
    loadChildren: () =>
      import("../../components/order-list-admin/order-list-admin.module").then(
        (m) => m.OrderListAdminModule
      ),
    data: {
      breadcrumb: "orderListAdmin",
    },
    canActivate: [AuthGuard],
  },

  {
    path: "products",
    loadChildren: () =>
      import("../../components/products/products.module").then(
        (m) => m.ProductsModule
      ),
    data: {
      breadcrumb: "Products",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "sales",
    loadChildren: () =>
      import("../../components/sales/sales.module").then((m) => m.SalesModule),
    data: {
      breadcrumb: "Sales",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "coupons",
    loadChildren: () =>
      import("../../components/coupons/coupons.module").then(
        (m) => m.CouponsModule
      ),
    data: {
      breadcrumb: "Coupons",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "pages",
    loadChildren: () =>
      import("../../components/pages/pages.module").then((m) => m.PagesModule),
    data: {
      breadcrumb: "Pages",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "media",
    loadChildren: () =>
      import("../../components/media/media.module").then((m) => m.MediaModule),
  },
  {
    path: "orders",
    loadChildren: () =>
      import("../../components/menus/menus.module").then((m) => m.MenusModule),
    data: {
      breadcrumb: "Orders",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "seller-crm",
    loadChildren: () =>
      import("../../components/crm/crm.module").then((m) => m.CrmModule),
    data: {
      breadcrumb: "Seller-CRM",
    },
    canActivate: [AuthGuard],
  },

  {
    path: "home-page",
    loadChildren: () =>
      import("../../components/home-page/home-page.module").then(
        (m) => m.HomePageModule
      ),
    data: {
      breadcrumb: "Home-Page",
    },
    canActivate: [AuthGuard],
  },

  {
    path: "users",
    loadChildren: () =>
      import("../../components/users/users.module").then((m) => m.UsersModule),
    data: {
      breadcrumb: "Users",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "vendors",
    loadChildren: () =>
      import("../../components/vendors/vendors.module").then(
        (m) => m.VendorsModule
      ),
    data: {
      breadcrumb: "Vendors",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "wallet",
    loadChildren: () =>
      import("../../components/menus/menus.module").then((m) => m.MenusModule),
    data: {
      breadcrumb: "Wallet",
    },
    canActivate: [AuthGuard],
  },

  {
    path: "transactions",
    loadChildren: () =>
      import("../../components/transactions/transactions.module").then(
        (m) => m.TransactionsModule
      ),
    data: {
      breadcrumb: "Seller Transactions",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "localization",
    loadChildren: () =>
      import("../../components/localization/localization.module").then(
        (m) => m.LocalizationModule
      ),
    data: {
      breadcrumb: "Localization",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "reports",
    loadChildren: () =>
      import("../../components/reports/reports.module").then(
        (m) => m.ReportsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "settings",
    loadChildren: () =>
      import("../../components/setting/setting.module").then(
        (m) => m.SettingModule
      ),
    data: {
      breadcrumb: "Settings",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "invoice",
    loadChildren: () =>
      import("../../components/invoice/invoice.module").then(
        (m) => m.InvoiceModule
      ),
    data: {
      breadcrumb: "Invoice",
    },
    canActivate: [AuthGuard],
  },
];
