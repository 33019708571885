<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Products Category</h5>
                </div>
                <div class="card-body">
                    <div class="custom-datatable">
                        <button class="btn btn-primary me-1 mb-3" style="float: right;">Delete</button>
                        <div class="clearfix"></div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="title" (sort)="onSort($event)">Title</th>
                                    <th scope="col" sortable="code" (sort)="onSort($event)">Code</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">Discoount</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of tableItem$ | async ">
                                    
                                <th scope="row">
                                    <input type="checkbox" name="title_select" onclick="onSelect(item.id)">
                                    {{ item.title }}
                                </th>
                                <td>
                                    {{item.code}}
                                </td>
                                <td>{{item.discount}}</td>
                                <td><i class="fa fa-circle {{item.status}} f-12"></i></td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination
                              [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                            </ngb-pagination>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->