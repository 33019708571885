<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Add Seller CRM</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <form
              class="needs-validation"
              [formGroup]="addSellerCrmForm"
              (ngSubmit)="addCrm()"
            >
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Seller's FirstName :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      placeholder="Please Enter Seller's First Name"
                      formControlName="firstName"
                    />
                    <div
                      *ngIf="
                        firstName &&
                        firstName.invalid &&
                        (firstName.dirty || firstName.touched)
                      "
                      class="text text-danger"
                    >
                      First Name is required.
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Seller's LastName :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      placeholder="Please Enter Seller's Last Name"
                      formControlName="lastName"
                    />
                    <div
                      *ngIf="
                        lastName &&
                        lastName.invalid &&
                        (lastName.dirty || lastName.touched)
                      "
                      class="text text-danger"
                    >
                      Last Name is required.
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom03" class="mb-0"
                      >Seller's Email :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom03"
                      type="email"
                      formControlName="email"
                      placeholder="Please Enter Seller Email"
                    />
                    <div
                      *ngIf="
                        email && email.invalid && (email.dirty || email.touched)
                      "
                      class="text text-danger"
                    >
                      Email is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom04" class="mb-0"
                      >Seller's Contact No. :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom04"
                      type="number"
                      placeholder="Add Contact Number here"
                      formControlName="phone"
                    />
                    <!-- <div
                      *ngIf="
                        phone &&
                        phone.invalid &&
                        (phone.dirty || phone.touched)
                      "
                      class="text text-danger"
                    >
                      phone is required.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom05" class="mb-0"
                      >Seller's Information :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom05"
                      type="text"
                      placeholder="Please Enter Seller Information Here"
                      formControlName="info"
                    />
                    <!-- <div
                      *ngIf="
                        info &&
                        info.invalid &&
                        (info.dirty || info.touched)
                      "
                      class="text text-danger"
                    >
                      Info is required.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Seller's Address :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom06"
                      type="text"
                      placeholder="Please Enter Seller Address Here"
                      formControlName="address"
                    />
                    <!-- <div
                      *ngIf="
                        address && address.invalid && (address.dirty || address.touched)
                      "
                      class="text text-danger"
                    >
                      Seller Address is required.
                    </div> -->
                  </div>
                </div>
                <!-- <label class="toggle-button">
                  <input type="checkbox" [(ngModel)]="isChecked" />
                  <span class="slider round"></span>
                </label> -->
              </div>
              <div class="form-group row mb-0">
                <div class="col-sm-8 offset-xl-3 offset-sm-4">
                  <div class="product-buttons">
                    <button
                      type="submit"
                      class="btn btn-primary me-1"
                      [disabled]="addSellerCrmForm.invalid"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      (click)="onDiscard()"
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
