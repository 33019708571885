import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CropperPosition,
  CropperSettings,
  ImageCroppedEvent,
  LoadedImage,
} from "ngx-image-cropper";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { ProductService } from "src/app/_services/product/product.service";

@Component({
  selector: "app-shop-offer-upload",
  templateUrl: "./shop-offer-upload.component.html",
  styleUrls: ["./shop-offer-upload.component.scss"],
})
export class ShopOfferUploadComponent implements OnInit {
  uploadOfferFrom: any;
  categories: any;
  pictureUrl: any;
  pictureSrc: any;
  public closeResult: string;
  isupload: boolean = false;
  offerSections: any;
  selectedOfferId: any;
  offerShops: any;
  forIndex = [
    { id: "1", value: "1 - Left" },
    { id: "2", value: "2 - Right" },
  ];
  uploadedOfferImgs: any;
  croppedImageToUpload: any;
  imageChangedEvent: any;
  croppedImage: any;

  @Input() cropper: CropperPosition = {
    x1: -100,
    y1: -100,
    x2: 10000,
    y2: 10000,
  };
  private settings = new CropperSettings();
  browseImg: boolean = false;
  shopOfferSection: any;

  constructor(
    private productService: ProductService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toastr: ToastrService,
    private categoryService: CategoryServiceService,
    private sanitizer: DomSanitizer
  ) {
    this.uploadOfferFrom = this.formBuilder.group({
      shopId: ["", [Validators.required]],
      offerId: ["", [Validators.required]],
      index: ["", [Validators.required]],
    });
    this.uploadOfferFrom.controls["shopId"].setValue(0);
    this.uploadOfferFrom.controls["index"].setValue(0);
    this.uploadOfferFrom.controls["offerId"].setValue(0);
  }

  ngOnInit(): void {
    // this.getAllCategories();
    this.getAllSectionData();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  // getAllCategories() {
  //   this.categoryService.getAllCategory().subscribe((res: any) => {
  //     console.log("res", res);
  //     this.categories = res?.categoryList;
  //   });
  // }

  getAllSectionData() {
    this.productService.fetchSectionsData().subscribe((res: any) => {
      console.log("section", res.homePageResponseList);
      // this.offerSections = res;
      this.shopOfferSection = res?.homePageResponseList?.filter((obj: any) => {
        return obj.isStoreOffer == true;
      });
      console.log("this.shopOfferSection", this.shopOfferSection);
    });
  }

  onSelectOffer() {
    this.selectedOfferId = this.uploadOfferFrom.value["offerId"];
    this.fetchCategoryImagebySectionId(this.selectedOfferId);
    this.productService
      .fetchShopsByShopOfferId(this.selectedOfferId)
      .subscribe((res: any) => {
        console.log("offerCat", res);
        this.offerShops = res;
      });
  }

  onPictureUpload(picture: any) {
    console.log("picture", picture.target.files);

    if (picture.target.files && picture.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pictureUrl = e.target.result;
      };
      reader.readAsDataURL(picture.target.files[0]);
      this.pictureSrc = picture.target.files;
    }
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onSave() {
    if (
      this.uploadOfferFrom.invalid ||
      this.uploadOfferFrom.value["shopId"] == 0 ||
      this.uploadOfferFrom.value["index"] == 0 ||
      this.uploadOfferFrom.value["offerId"] == 0
    ) {
      this.toastr.error("Please fill up the fields");
      // this.formSubmitted = false;
      return;
    }

    this.isupload = true;
    this.showSpinner();
    let data: any = this.uploadOfferFrom.value;
    const save_inputs = new FormData();
    save_inputs.append("storeId", this.uploadOfferFrom.value["shopId"]);
    save_inputs.append("catId", "");
    save_inputs.append("offerId", this.uploadOfferFrom.value["offerId"]);
    save_inputs.append("index", this.uploadOfferFrom.value["index"]);
    save_inputs.append("optimiseFlag", "");
    save_inputs.append("productId", "");
    save_inputs.append("file", this.croppedImageToUpload);

    // save_inputs.append("optimiseFlag", this.checkedAgree.toString());
    // save_inputs.append("file", this.downLoadfile);
    console.log("save_inputs", save_inputs);

    // Object.keys(this.downLoadfile).forEach((key) => {
    //   save_inputs.append("file", this.downLoadfile[key]);
    // });

    // Object.keys(this.pictureSrc).forEach((key) => {
    //   save_inputs.append("file", this.pictureSrc[key]);
    // });
    // return;
    this.productService
      .uploadCategoryImage(save_inputs)
      .subscribe((res: any) => {
        console.log("res", res);
        this.isupload = false;
        if (res?.status == 0) {
          this.showSpinner();
          this.toastr.success("Offer Image Uploaded Successfully");
          this.croppedImageToUpload = "";
          this.uploadOfferFrom.reset();
          this.croppedImage = "";
          this.browseImg = false;
          this.offerShops = "";
          this.uploadOfferFrom.controls["shopId"].setValue(0);
          this.uploadOfferFrom.controls["index"].setValue(0);
          this.uploadOfferFrom.controls["offerId"].setValue(0);
          this.uploadedOfferImgs = "";
          this.imageChangedEvent = "";
          // this.router.navigate(["/home-page/section-list"]);
        } else {
          this.toastr.error("Something Went Wrong ! Try Again.");
        }
      });
  }

  fetchCategoryImagebySectionId(selectedOfferId?: any) {
    this.productService
      .fetchImagebySectionId(selectedOfferId)
      .subscribe((res: any) => {
        console.log("res", res);
        this.uploadedOfferImgs = res;
      });
  }

  fileChangeEvent(event: any): void {
    this.croppedImage = "";
    this.browseImg = true;
    this.imageChangedEvent = event;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pictureUrl = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.croppedImageToUpload = event.target.files;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    console.log("this.croppedImage", this.croppedImage);
    const blob = event.blob;
    console.log("blob", blob);

    this.croppedImageToUpload = blob;
  }

  base64toBlob(base64Data: string) {
    if (!base64Data) {
      console.error("Base64 data is undefined or null.");
      return;
    }

    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpg" });

    this.croppedImageToUpload = blob;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onCancel() {
    this.browseImg = false;
    this.uploadedOfferImgs = "";
    this.imageChangedEvent = "";
    this.croppedImageToUpload = "";
    this.uploadOfferFrom.reset();
    this.croppedImage = "";
    this.offerShops = "";
    this.uploadOfferFrom.controls["catId"].setValue(0);
    this.uploadOfferFrom.controls["index"].setValue(0);
    this.uploadOfferFrom.controls["offerId"].setValue(0);
  }
}
