import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CreateVariant } from "src/app/_models/product/createVariant";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { ProductService } from "src/app/_services/product/product.service";
import Swal from "sweetalert2";
@Component({
  selector: "app-geo-track",
  templateUrl: "./geo-track.component.html",
  styleUrls: ["./geo-track.component.scss"],
})
export class GeoTrackComponent implements OnInit {
  addGeoCheckForm!: FormGroup;
  product_list: any[] = [];
  sizeList: any[] = [];
  loaded = false;
  // productId:any;
  savedData: any = [];
  selectedItems = [];
  selectedColorName: any = "White";
  dropdownSettings = {
    singleSelection: false,
    idField: "sizeId",
    textField: "sizeName",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  selectColorId: any;
  selectedColorCode: string = "#E5E7EB";
  producId: any;
  formSubmitted: boolean = false;
  geoDistance: any;
  constructor(
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private categoryService: CategoryServiceService,
    private spinner: NgxSpinnerService
  ) {
    this.addGeoCheckForm = this.formBuilder.group({
      geocheck: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getGeocheck();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  onAddGeo() {
    // this.formSubmitted = true;
    // if (
    //   this.addGeoCheckForm.invalid ||
    //   this.addGeoCheckForm.value["productId"] == 0
    // ) {
    //   this.toastr.error("Please fill up the mandatory fields (marked in *)");
    //   // this.formSubmitted = false;
    //   return;
    // }
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let geocheck = this.addGeoCheckForm.value["geocheck"];

        // console.log("fd", fd);
        this.categoryService
          .addGeocheck(geocheck)
          .subscribe((response: any) => {
            console.log("res", response);
            this.getGeocheck();
            this.showSpinner();
            this.onDiscard();
            Swal.fire("Geo Check Added Succesfully!", "", "success");
            // this.router.navigate(["/products/create-sale", this.producId]);
            // this.onDiscard();
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  getGeocheck() {
    this.categoryService.getGeocheck().subscribe((res: any) => {
      console.log("res", res);
      this.geoDistance = res.statusDesc;
    });
  }

  onDiscard() {
    this.addGeoCheckForm.reset();
  }

  get geocheck() {
    return this.addGeoCheckForm.get("geocheck");
  }
}
