export interface ListPagesDB {
    name: string;
    status: string;
    created_on: string;
}

export const LISTPAGEDB: ListPagesDB[] = [
    {
        "name": "Product List",
        "status": "<i class=\"fa fa-circle font-success f-12\"></i>",
        "created_on": "Sep 5,18",
    },
    {
        "name": "Digital Product",
        "status": "<i class=\"fa fa-circle font-danger f-12\"></i>",
        "created_on": "Mar 10,18",
    },
    {
        "name": "User Media",
        "status": "<i class=\"fa fa-circle font-success f-12\"></i>",
        "created_on": "Aug 24,18",
    },
    {
        "name": "About Product",
        "status": "<i class=\"fa fa-circle font-success f-12\"></i>",
        "created_on": "Jun 12,18",
    },
    {
        "name": "User Profile",
        "status": "<i class=\"fa fa-circle font-warning f-12\"></i>",
        "created_on": "May 26,18",
    },
    {
        "name": "Discount Product",
        "status": "<i class=\"fa fa-circle font-danger f-12\"></i>",
        "created_on": "Sep 13,18",
    },
    {
        "name": "About Invoice",
        "status": "<i class=\"fa fa-circle font-success f-12\"></i>",
        "created_on": "Dec 30,18",
    },
]