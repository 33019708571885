import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services/auth/authentication.service";

@Component({
  selector: "app-verification-page",
  templateUrl: "./verification-page.component.html",
  styleUrls: ["./verification-page.component.scss"],
})
export class VerificationPageComponent implements OnInit {
  sellerId: any;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.sellerId = params["id"];
      console.log("this.sellerId", this.sellerId);
    });
    this.authService.verifySeller(this.sellerId).subscribe((res: any) => {
      console.log("res", res);
      if (res.status == 0) {
        setTimeout(() => {
          this.router.navigate(["/auth/login"]);
        }, 2000);
      }
    });
  }
}
