<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Add Variant</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-7">
              <form
                class="needs-validation"
                [formGroup]="createVariantForm"
                (ngSubmit)="onCreateVariant()"
              >
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Choose Product<sup class="star">*</sup>:</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="productId"
                        (change)="onSelect()"
                      >
                        <option [value]="0">Select Product</option>
                        <option
                          *ngFor="let product of product_list"
                          [value]="product.productId"
                        >
                          {{ product.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="f && formSubmitted && f.productId.value == 0"
                        class="text text-danger"
                      >
                        Please Select a Product.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Choose Size :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <!-- [ngModelOptions]="{ standalone: true }" -->
                      <ng-multiselect-dropdown
                        placeholder=""
                        [data]="sizeList"
                        name="sizeList"
                        formControlName="size"
                        [settings]="dropdownSettings"
                        (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onItemDeSelect($event)"
                      >
                      </ng-multiselect-dropdown>
                      <!-- [(ngModel)]="savedData" -->
                    </div>
                  </div>
                  <!-- <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom01" class="mb-0"
                        >Color Code :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom01"
                        placeholder="Please Enter Color Code"
                        formControlName="colorCode"
                      />
                      <div
                        *ngIf="
                          colorCode &&
                          colorCode.invalid &&
                          (colorCode.dirty || colorCode.touched)
                        "
                        class="text text-danger"
                      >
                        Color Code is required.
                      </div>
                    </div>
                  </div> -->
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Color Name :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <!-- <input
                        class="form-control"
                        id="validationCustom02"
                        type="text"
                        placeholder="Enter Color Name Here"
                        formControlName="colorName"
                      /> -->
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="colorName"
                        (change)="onSelectColor()"
                      >
                        <option [ngValue]="0">White(Default)</option>
                        <option
                          *ngFor="let color of colorArray"
                          [value]="color.id"
                        >
                          {{ color.color_name }}
                        </option>
                      </select>
                      <!-- <div
                        *ngIf="
                          colorName &&
                          colorName.invalid &&
                          (colorName.dirty || colorName.touched)
                        "
                        class="text text-danger"
                      >
                        Color Name is required.
                      </div> -->
                    </div>
                  </div>

                  <!-- <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom03" class="mb-0"
                        >Stock :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom03"
                        type="number"
                        placeholder="Enter Stock Here"
                        formControlName="stock"
                      />
                      <div
                        *ngIf="
                          stock &&
                          stock.invalid &&
                          (stock.dirty || stock.touched)
                        "
                        class="text text-danger"
                      >
                        Stock is required.
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom03" class="mb-0"
                        >Price :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom03"
                        type="number"
                        placeholder="Enter Price Here"
                        formControlName="price"
                        min="1"
                      />
                      <div
                        *ngIf="
                          price &&
                          price.invalid &&
                          (price.dirty || price.touched)
                        "
                        class="text text-danger"
                      >
                        Price is required.
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="form-group row mb-0">
                  <div class="col-sm-8 offset-xl-3 offset-sm-4">
                    <div class="product-buttons">
                      <button
                        type="submit"
                        value="submit"
                        class="btn btn-primary me-1"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        (click)="onDiscard()"
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
