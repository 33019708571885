import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CreateVariant } from "src/app/_models/product/createVariant";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { ProductService } from "src/app/_services/product/product.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-create-variant",
  templateUrl: "./create-variant.component.html",
  styleUrls: ["./create-variant.component.scss"],
})
export class CreateVariantComponent implements OnInit {
  createVariantForm!: FormGroup;
  product_list: any[] = [];
  sizeList: any[] = [];
  loaded = false;
  // productId:any;
  savedData: any = [];
  selectedItems = [];
  selectedColorName: any = "White";
  dropdownSettings = {
    singleSelection: false,
    idField: "sizeId",
    textField: "sizeName",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  selectColorId: any;
  selectedColorCode: string = "#E5E7EB";
  producId: any;
  formSubmitted: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private productService: ProductService,
    public activeRoute: ActivatedRoute,
    private categoryService: CategoryServiceService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.createVariantForm = this.formBuilder.group({
      // colorCode: ["", [Validators.required]],
      colorName: [""],
      size: [""],
      productId: ["", [Validators.required]],
      // price: ["", [Validators.required]],
      // stock: ["", [Validators.required]],
    });
    this.createVariantForm.controls["productId"].setValue(0);
    this.createVariantForm.controls["colorName"].setValue(0);
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((param: any) => {
      this.producId = param.get("id") || 0;
      this.getAllProduct(this.producId);
      this.getAllSizes();
    });
  }
  colorArray = [
    { id: 1, color: "#964B00", color_name: "Brown" },
    { id: 2, color: "#00FF00", color_name: "Green" },
    { id: 3, color: "#faf0be", color_name: "Hex Blond" },
    { id: 4, color: "#FF033E", color_name: "American Rose" },
    { id: 5, color: "#B06500", color_name: "ginger color" },
    { id: 6, color: "#d5db21", color_name: "Yellowish Green" },
    { id: 7, color: "#C4A484", color_name: "Light Brown" },
    { id: 8, color: "#FFFF00", color_name: "yellow" },
    { id: 9, color: "#E5E7EB", color_name: "White" },
    { id: 10, color: "#F0EDE5", color_name: "milk color" },
    { id: 11, color: "#8B0000", color_name: "Dark Red" },
    { id: 12, color: "#FFD700", color_name: "golden Color" },
    { id: 13, color: "#EAC117", color_name: "Golden Brown" },
    { id: 14, color: "#6F4E37", color_name: "coffe color" },
    { id: 15, color: "#808000", color_name: "Olive" },
    { id: 16, color: "#FF0000", color_name: "RED" },
    { id: 17, color: "#B7A6A6", color_name: "Brown" },
    { id: 18, color: "#000000", color_name: "Black" },
  ];

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  onItemSelect(item: any) {
    this.savedData.push(item);
    this.savedData = Array.from(new Set(this.savedData));
    console.log("saved", this.savedData);
  }

  onItemDeSelect(item: any) {
    console.log("deselctitem", item);

    let filteredData = this.savedData.filter(
      (data) => item.sizeId != data.sizeId
    );
    this.savedData = filteredData;
    console.log("deselct", this.savedData);
  }
  getAllProduct(productId?) {
    this.productService.getAllProducts().subscribe((response: any) => {
      this.product_list = response;
      console.log("productId", productId);

      this.createVariantForm.get("productId").setValue(productId);

      console.log("product_list", this.product_list);
    });
  }
  getAllSizes() {
    this.categoryService.getAllSize().subscribe((res: any) => {
      this.sizeList = res.sizeList;
      console.log("this.sizeList", this.sizeList);
      let obj = {
        sizeId: 55,
        sizeName: "Default",
      };
      setTimeout(() => {
        this.onItemSelect(obj);
        this.createVariantForm.controls["size"].setValue([obj]);
      }, 10);
    });
  }
  // getAllProduct() {
  //   this.activeRoute.params.subscribe((param) => {
  //     this.loaded = false;
  //     this.productService
  //       .getAllProducts(param, "/v1/getAllProduct", 5)
  //       .subscribe((response: any) => {
  //         this.product_list = response;
  //         console.log("product_list", this.product_list);
  //       });
  //   });
  // }
  onSelectColor() {
    this.selectColorId = this.createVariantForm.value["colorName"];
    // console.log("this.selectShopId", this.selectShopId);
    let color = this.colorArray.filter((res: any) => {
      // console.log("res", res);

      return res.id == this.selectColorId;
    });
    // console.log("color", color);
    this.selectedColorName = color[0].color_name;
    this.selectedColorCode = color[0].color;
    console.log(
      "selectedColor",
      this.selectedColorName,
      this.selectedColorCode
    );
  }
  onSelect() {
    this.producId = this.createVariantForm.value["productId"];
  }
  onCreateVariant() {
    this.formSubmitted = true;
    if (
      this.createVariantForm.invalid ||
      this.createVariantForm.value["productId"] == 0
    ) {
      this.toastr.error("Please fill up the mandatory fields (marked in *)");
      // this.formSubmitted = false;
      return;
    }
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd: CreateVariant = {
          productId: this.createVariantForm.value["productId"],
          colorCode: this.selectedColorCode,
          colorName: this.selectedColorName,
          sizeId: this.savedData,
          price: 0,
          stock: 100,
          // status: "active",

          // colorCode varchar
          // colorName varchar
          // productId int
          // slug varchar
          // stock int
          // created_date timestamp
          // updated_date timestamp
          // status varchar
        };
        console.log("fd", fd);
        this.productService.createVariant(fd).subscribe((response: any) => {
          console.log("Variant", response);
          this.showSpinner();
          this.formSubmitted = false;
          Swal.fire("Variant Created Succesfully!", "", "success");
          this.router.navigate(["/products/create-sale", this.producId]);
          // this.onDiscard();
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onDiscard() {
    this.selectedItems = [];
    this.createVariantForm.reset();
    this.createVariantForm.controls["productId"].setValue(0);
    this.createVariantForm.controls["colorName"].setValue(0);
    let obj = {
      sizeId: 55,
      sizeName: "Default",
    };
    setTimeout(() => {
      this.createVariantForm.controls["size"].setValue([obj]);
      this.savedData = [obj];
    }, 10);
  }
  get f() {
    return this.createVariantForm.controls;
  }
  get colorCode() {
    return this.createVariantForm.get("colorCode");
  }

  get colorName() {
    return this.createVariantForm.get("colorName");
  }

  get productId() {
    return this.createVariantForm.get("productId");
  }
  get price() {
    return this.createVariantForm.get("price");
  }
  get stock() {
    return this.createVariantForm.get("stock");
  }
}
