<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h5>Vendor CRM Details</h5>
    </div>
    <div class="card-body vendor-table text-center">
      <div id="batchDelete" class="custom-datatable vendor-list">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Full Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact</th>
                <th scope="col">Seller Info</th>
                <th scope="col">Address</th>
                <th>First Send Date</th>
                <th>Last Send Date</th>
                <th scope="col">Action</th>
                <!-- <th scope="col"  >Wallet Balance</th>
                                    <th scope="col" >Revenue</th>
                                    <th scope="col" >Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sellerCrms of sellerCrmList">
                <td scope="row">
                  <!-- <img [src]="item.vendor" class="imgTable" style="width: 20px">
                                       <span>{{item.name}}</span>  -->
                  {{ sellerCrms?.sl_no }}
                </td>
                <td>
                  {{ sellerCrms?.firstName + " " + sellerCrms?.lastName }}
                </td>
                <td>{{ sellerCrms?.sellerEmail }}</td>
                <td>{{ sellerCrms?.sellerMobile }}</td>
                <td>
                  {{ sellerCrms?.sellerInformation || "N/A" }}
                </td>
                <td>
                  {{ sellerCrms?.sellerAddress }}
                </td>
                <td>
                  {{ sellerCrms?.createdDate.split("[")[0] | date : "medium" }}
                </td>
                <td>
                  {{ sellerCrms?.updatedDate.split("[")[0] | date : "medium" }}
                </td>
                <td>
                  <a
                    *ngIf="!isResend"
                    type="button"
                    (click)="onResendClick(sellerCrms)"
                    class="btn btn-primary me-1"
                    >Resend</a
                  >

                  <button
                    *ngIf="isResend"
                    class="btn btn-primary"
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Sending...
                  </button>
                </td>
                <!-- <td>{{item.balance}}</td>
                                    <td>{{item.revenue}}</td> -->
                <!-- <td>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-edit f-12"></i></a
                  >&nbsp;
                  <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                </td> -->

                <!-- <td>
                  <label
                    class="switch"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="{{ vendor?.active == true ? 'Active' : 'Inactive' }}"
                  >
                    <input
                      type="checkbox"
                      [checked]="vendor?.active == true"
                      (click)="onActiveClick($event, vendor.id, vendor.active)"
                    />
                    <span class="slider round"></span>
                  </label>
                </td> -->
                <!-- <td>
                  <span
                    [class]="
                      vendor?.active == true
                        ? 'badge bg-success text-dark'
                        : 'badge bg-secondary text-dark'
                    "
                  >
                    {{ vendor?.active == true ? "Active" : "Inactive" }}</span
                  >
                </td> -->
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center p-2">
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="sellerCrmListLength"
              (pageChange)="getAllSellersCrm()"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
