import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { CATEGORY } from "../../../shared/tables/category";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { DecimalPipe } from "@angular/common";
import { TableService } from "src/app/shared/service/table.service";
import { SortEvent } from "src/app/shared/directives/shorting.directive";
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { Category, CategoryList } from "src/app/_models/category/category";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
  providers: [TableService, DecimalPipe],
})
export class CategoryComponent implements OnInit {
  public closeResult: string;
  categories: any;
  searchText;
  tableItem$: Observable<Category[]>;
  total$: Observable<number>;
  items: any;
  page: number = 1;
  editItem: boolean = false;
  pageSize: number = 10;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild("addCategory") addCategory!: ElementRef;
  is_checked: any;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  category: Category[] = [];
  categoriesLength: any;
  categoryId: any;
  changedValue: any = "";
  constructor(
    public service: TableService,
    private modalService: NgbModal,
    private categoryService: CategoryServiceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(CATEGORY);
  }

  onSort({ column, direction }) {
    // resetting other headers
    console.log("dsafsa");

    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.showSpinner();
    this.getAllCategories();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  //Get All Categories

  getAllCategories() {
    this.categoryService.getAllCategory().subscribe((res: any) => {
      console.log("res", res);
      this.categoriesLength = res.categoryList.length;
      this.categories = res.categoryList
        .map((result: any, index: any) => {
          console.log("this.categories", result);
          return {
            item: result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
      console.log("this.categories", this.categories);
    });
  }

  //Adding Value in MatChip

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    let payload = { category: value, status: "active" };
    // Add our cat
    if (value) {
      this.category.push(payload);
    }

    // Clear the input value
    event.chipInput!.clear();
    console.log(this.category);
  }

  //Removing Value From MatChip

  remove(cat: any): void {
    const index = this.category.indexOf(cat);

    if (index >= 0) {
      this.category.splice(index, 1);
    }
    console.log(this.category);
  }

  //Save Categories(on Save Button Click of Modal)
  onCategoriesSave() {
    if (!this.categoryId) {
      let categoryList: CategoryList = { categoryList: this.category };
      this.categoryService
        .createCategory(categoryList)
        .subscribe((response: any) => {
          console.log("response", response);

          if (response.status == 0) {
            this.showSpinner();
            this.toastr.success("Category Created Successfully");
            this.getAllCategories();
          }
        });
    } else {
      let upateData = {
        name: this.changedValue,
        status: "active",
      };
      this.categoryService
        .updateCategory(this.categoryId, upateData)
        .subscribe((response: any) => {
          console.log("response", response);
          this.categoryId = 0;
          this.changedValue = "";
          if (response.status == 0) {
            this.showSpinner();
            this.toastr.success("Category Updated Successfully");
            this.getAllCategories();
          }
        });
    }

    return this.onDismiss();
  }
  onCatagorieChange(value) {
    this.changedValue = value;
    console.log(value);
  }
  //on Category Edit Click
  onCategoryEditClick(category: any) {
    this.changedValue = category.category;
    this.categoryId = category.id;
    // console.log("edit", category.id);
    // this.category.push(category);
    this.addCategory.nativeElement.click();
  }
  //on Category Delete Click
  onCategoryDeleteClick(id: any) {
    console.log("Catid", id);

    this.categoryService.deleteCategory(id).subscribe((res: any) => {
      console.log("delete", res);
      if (res.status == 0) {
        this.toastr.success("Category Deleted Successfully");
        this.getAllCategories();
      }
    });
  }
  onActiveClick(event: any, id: any, active: any) {
    console.log(id);
    this.is_checked = active;
    console.log("is_Active", active);
    console.log("checked", this.is_checked);

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `SAVE`,
      denyButtonText: `CANCEL`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        if (event.target.checked) {
          this.is_checked = 1;
          console.log("is", this.is_checked);
        } else {
          this.is_checked = 0;
          // console.log('ddd', this.is_checked);
        }
        const save_inputs = {
          is_active: this.is_checked,
        };
        console.log(save_inputs);

        if (id !== 0) {
          //add
          // save_inputs.append('id', this.driverId);
          this.categoryService
            .softDeleteCategory(id)
            .subscribe((response: any) => {
              console.log("tttt", response);
              Swal.fire("Saved!", "", "success");
              this.showSpinner();
              this.getAllCategories();
            });
        }
      } else if (result.isDenied) {
        this.getAllCategories();
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  //On Close Button Click of Modal
  onDismiss() {
    return (this.category = []);
  }
}
