<!-- Container-fluid starts-->
<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Orders</h5>
        </div>
        <div class="card-body">
          <p *ngIf="!orderList?.length">No order has been made yet.</p>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive">
              <table
                *ngIf="orderList?.length > 0"
                class="table table-striped text-center"
              >
                <thead>
                  <tr>
                    <!-- <th scope="col">#</th> -->
                    <th scope="col">OrderProductId</th>
                    <th scope="col">OrderId</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">OrderType</th>
                    <th scope="col">StorePickupTime</th>
                    <th scope="col">Action</th>
                    <th scope="col">status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let orders of orderList; let i = index">
                    <!-- <td>{{ i + 1 }}</td> -->
                    <td>{{ orders?.orderProductId }}</td>
                    <td>{{ orders?.orderId }}</td>
                    <!-- <td>
                      {{ orders?.city }},{{ orders?.country }},{{
                        orders?.zipCode
                      }}
                    </td> -->
                    <td>
                      {{ orders?.addressLine2 ? orders?.number : "N/A" }} ,
                    </td>
                    <td>
                      {{ orders?.createdDate.split("[")[0] | date : "short" }}
                    </td>
                    <td>{{ orders?.orderType }}</td>
                    <td>{{ orders?.storePickupTime | date : "medium" }}</td>
                    <td>
                      <a
                        type="button"
                        [routerLink]="[
                          '/orders/order-detail/',
                          orders?.orderProductId
                        ]"
                        class="btn btn-primary me-1"
                        >View</a
                      >
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        (click)="onOrderClick(orders?.orderProductId)"
                        [ngClass]="{
                          'btn-outline-success':
                            orders?.orderProductStatus === 'COMPLETE',
                          'btn-outline-warning':
                            orders?.orderProductStatus === 'READY_FOR_PICKUP',
                          'btn-outline-info':
                            orders?.orderProductStatus !== 'COMPLETE' &&
                            orders?.orderProductStatus !== 'READY_FOR_PICKUP'
                        }"
                        (click)="
                          status = StatusEnum[orders?.orderProductStatus]
                        "
                      >
                        {{ orders?.orderProductStatus }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  data-bs-backdrop="static"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Change Status</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>

      <div class="modal-body">
        <select
          #statusRef
          class="form-select"
          aria-label=".form-select-sm example"
          (change)="status = statusRef.value"
        >
          <!-- [(ngModel)]="status" -->
          <option [value]="0" selected>Change Order Status</option>
          <!-- <option value="1">PAID</option> -->
          <option [value]="1">Ready For Pickup</option>
          <option [value]="2">Completed</option>
        </select>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          (click)="onStatusSave()"
          class="btn btn-primary"
          data-bs-dismiss="modal"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
