import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

import { CreateSize } from "src/app/_models/product/createSize";
import { ProductService } from "src/app/_services/product/product.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-create-size",
  templateUrl: "./create-size.component.html",
  styleUrls: ["./create-size.component.scss"],
})
export class CreateSizeComponent implements OnInit {
  createSizeForm: FormGroup;
  product_list: any[] = [];
  Variant_list: any[] = [];
  prod_Id: any;
  loaded = false;

  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private router: Router,
    public activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.createSizeForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      variant_id: ["", [Validators.required]],
      product_id: ["", [Validators.required]],
      // slug: ["", [Validators.required]],
    });
    this.createSizeForm.controls["product_id"].setValue(0);
    this.createSizeForm.controls["variant_id"].setValue(0);
  }

  ngOnInit(): void {
    this.getAllProduct();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  getAllProduct() {
    this.productService.getAllProducts().subscribe((response: any) => {
      this.product_list = response;
      console.log("product_list", this.product_list);
    });
  }
  // getAllProduct() {
  //   this.activeRoute.params.subscribe((param) => {
  //     this.loaded = false;
  //     this.productService
  //       .getAllProducts(param, "/v1/getAllProduct", 5)
  //       .subscribe((response: any) => {
  //         this.product_list = response;
  //         console.log("product_list", this.product_list);
  //       });
  //   });
  // }
  onSelectProduct() {
    (this.prod_Id = this.createSizeForm.value["product_id"]),
      this.productService
        .getVariantByProductId(this.prod_Id)
        .subscribe((res: any) => {
          this.Variant_list = res;
        });
  }

  onCreateSize() {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd: CreateSize = {
          name: this.createSizeForm.value["name"],
          product_id: this.createSizeForm.value["product_id"],
          variant_id: this.createSizeForm.value["variant_id"],
          // slug: this.createSizeForm.value["slug"],
          status: "active",

          // variant_id int
          // name varchar
          // slug varchar
          // product_id int
          // status varchar
        };
        this.productService.createSize(fd).subscribe((response: any) => {
          console.log("Size", response);
          this.showSpinner();
          Swal.fire("Size Created Succesfully!", "", "success");
          // this.router.navigate(["/products/create-sale"]);
          // this.onDiscard();
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onDiscard() {
    this.createSizeForm.reset();
    this.createSizeForm.controls["product_id"].setValue(0);
    this.createSizeForm.controls["variant_id"].setValue(0);
  }
  get name() {
    return this.createSizeForm.get("name");
  }

  get variant_id() {
    return this.createSizeForm.get("variant_id");
  }

  get product_id() {
    return this.createSizeForm.get("product_id");
  }
  // get slug() {
  //   return this.createSizeForm.get("slug");
  // }
}
