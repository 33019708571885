<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Add Shop Category</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-7">
              <form
                class="needs-validation"
                [formGroup]="createShopCategoryForm"
                (ngSubmit)="createShopCategory()"
              >
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Select Shop :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="shopId"
                        (change)="onSelectShop()"
                      >
                        <option [ngValue]="0">Select Shop</option>
                        <option
                          *ngFor="let shop of shopList"
                          [value]="shop.shopId"
                        >
                          {{ shop.shopName }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          shopId &&
                          !shopId.value &&
                          (shopId.dirty || shopId.touched)
                        "
                        class="text text-danger"
                      >
                        Please Select a Shop.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom01" class="mb-0"
                        >Select Category:</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <!-- <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="categoryId"
                        (change)="onSelectCategory()"
                      >
                        <option [ngValue]="0">Select Category</option>
                        <option
                          *ngFor="let cat of categoryList"
                          [value]="cat.id"
                        >
                          {{ cat.category }}
                        </option>
                      </select> -->
                      <ng-multiselect-dropdown
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Select Category"
                        [data]="categoryList"
                        name="categoryList"
                        [(ngModel)]="selectedItems"
                        [settings]="dropdownSettings"
                        (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onItemDeSelect($event)"
                      >
                      </ng-multiselect-dropdown>

                      <!-- <div
                        *ngIf="
                          categoryId &&
                          !categoryId.value &&
                          (categoryId.dirty || categoryId.touched)
                        "
                        class="text text-danger"
                      >
                        Please Select a Category.
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-sm-8 offset-xl-3 offset-sm-4">
                    <div class="product-buttons">
                      <button
                        type="submit"
                        class="btn btn-primary me-1"
                        [disabled]="!shopId.value"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        (click)="onDiscard()"
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
