import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AgGridModule } from "@ag-grid-community/angular";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { DashboardModule } from "./components/dashboard/dashboard.module";
import { SharedModule } from "./shared/shared.module";
import { ProductsModule } from "./components/products/products.module";
import { SalesModule } from "./components/sales/sales.module";
import { CouponsModule } from "./components/coupons/coupons.module";
import { PagesModule } from "./components/pages/pages.module";
import { MediaModule } from "./components/media/media.module";
import { MenusModule } from "./components/menus/menus.module";
import { VendorsModule } from "./components/vendors/vendors.module";
import { UsersModule } from "./components/users/users.module";
import { LocalizationModule } from "./components/localization/localization.module";
import { InvoiceModule } from "./components/invoice/invoice.module";
import { SettingModule } from "./components/setting/setting.module";
import { ReportsModule } from "./components/reports/reports.module";
import { AuthModule } from "./components/auth/auth.module";

import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { TagInputModule } from "ngx-chips";
//Enviroment
import { environment } from "src/environments/environment";
import { ToastrModule } from "ngx-toastr";
//JWT
import { JwtModule } from "@auth0/angular-jwt";
import { ShopModule } from "./components/shop/shop.module";
import { CategoryModule } from "./components/category/category.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CrmModule } from "./components/crm/crm.module";
import { HomePageModule } from "./components/home-page/home-page.module";
export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    DashboardModule,
    InvoiceModule,
    SettingModule,
    ReportsModule,
    AuthModule,
    CategoryModule,
    SharedModule,
    LocalizationModule,
    ProductsModule,
    SalesModule,
    VendorsModule,
    CouponsModule,
    PagesModule,
    MediaModule,
    ShopModule,
    TagInputModule,
    MenusModule,
    UsersModule,
    AgGridModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    CrmModule,
    HomePageModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.apiUrl],
        disallowedRoutes: [environment.apiUrl + "/auth/login"],
        authScheme: "",
        throwNoTokenError: true,
        skipWhenExpired: true,
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
