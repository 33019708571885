import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CategoryRoutingModule } from "./category-routing.module";
import { CategoryComponent } from "./category/category.component";
import { SubCategoryComponent } from "./sub-category/sub-category.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgxDropzoneModule } from "ngx-dropzone";
import { TagInputModule } from "ngx-chips";
import { SharedModule } from "src/app/shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { CreateSizeComponent } from "./create-size/create-size.component";
import { GeoTrackComponent } from "./geo_Track/geo-track/geo-track.component";
import { DeliveryByAdminComponent } from './delivery-by-admin/delivery-by-admin.component';

@NgModule({
  declarations: [
    CategoryComponent,
    SubCategoryComponent,
    CreateSizeComponent,
    GeoTrackComponent,
    DeliveryByAdminComponent,
  ],
  imports: [
    CommonModule,
    CategoryRoutingModule,
    Ng2SearchPipeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    GalleryModule,
    CKEditorModule,
    NgxDropzoneModule,
    TagInputModule,
    SharedModule,
    ToastrModule.forRoot(),
  ],
  bootstrap: [SubCategoryComponent],
  providers: [NgbActiveModal],
})
export class CategoryModule {}
