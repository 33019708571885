<div class="container">
  <div class="container mb-2 myclass">
    <h2 class="text-danger">Congratulations !</h2>
    <img
      class="myImg"
      src="../../../../assets/images/ok-1976099_960_720.png"
      alt=""
    />
  </div>
  <h2 class="text-center text-success">
    You are succesfully onboard to Stripe !
  </h2>
  <!-- <marquee class="text-danger" behavior="" direction=""
    >This page will redirect to LOGIN in 10 sec</marquee
  > -->
</div>
