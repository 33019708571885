import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";
import { PagesRoutingModule } from "./pages-routing.module";
import { ListPageComponent } from "./list-page/list-page.component";
import { CreatePageComponent } from "./create-page/create-page.component";
import { SharedModule } from "src/app/shared/shared.module";
import { SuccessOnboardingComponent } from "./success-onboarding/success-onboarding.component";
import { UnsuccessOnboardingComponent } from './unsuccess-onboarding/unsuccess-onboarding.component';

@NgModule({
  declarations: [
    ListPageComponent,
    CreatePageComponent,
    SuccessOnboardingComponent,
    UnsuccessOnboardingComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class PagesModule {}
