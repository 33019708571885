import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SellerCrmComponent } from "./seller-crm/seller-crm.component";
import { CrmListComponent } from "./crm-list/crm-list.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "seller-crm",
        component: SellerCrmComponent,
        data: {
          title: "Seller CRM",
          breadcrumb: "Seller CRM",
        },
      },
      {
        path: "crm-list",
        component: CrmListComponent,
        data: {
          title: "CRM List",
          breadcrumb: "CRM List",
        },
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrmRoutingModule {}
