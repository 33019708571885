<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Add Product</h5>
          <span>OR</span>
          <span>
            <a class="shopify" [routerLink]="['/products/shopify-connect']"
              >Click here to add or update your Shopify Products to TE13</a
            ></span
          >
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <form
              class="needs-validation"
              [formGroup]="addProductForm"
              (ngSubmit)="createProduct()"
            >
              <div class="add-product-form">
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Name <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      placeholder="Please Enter Product Name"
                      formControlName="name"
                    />
                    <div
                      *ngIf="
                        name && name.invalid && (name.dirty || name.touched)
                      "
                      class="text text-danger"
                    >
                      Name is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Select Shop <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="shopId"
                      (change)="onSelectShop()"
                    >
                      <option [ngValue]="0">Select Shop</option>
                      <option
                        *ngFor="let shop of shopList"
                        [value]="shop.shopId"
                      >
                        {{ shop.shopName }}
                      </option>
                    </select>
                    <div
                      *ngIf="f && formSubmitted && f.shopId.value == 0"
                      class="text text-danger"
                    >
                      Please Select a Shop.
                    </div>
                    <!-- <div
                      *ngIf="
                        shopId &&
                        !shopId.value &&
                        (shopId.dirty || shopId.touched)
                      "
                      class="text text-danger"
                    >
                      Please Select a Shop.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom03" class="mb-0">Brand :</label>
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom03"
                      type="text"
                      formControlName="brand"
                      placeholder="Please Enter Product Brand"
                    />
                    <!-- <div
                      *ngIf="
                        brand && brand.invalid && (brand.dirty || brand.touched)
                      "
                      class="text text-danger"
                    >
                      Brand is required.
                    </div> -->
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Select Shop Category :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="shopCategoryId"
                    >
                      <option [ngValue]="0">Select Shop Category</option>
                      <option
                        *ngFor="let shopCat of shopCategoryList"
                        [value]="shopCat.id"
                      >
                        {{ shopCat.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        shopCategoryId &&
                        !shopCategoryId.value &&
                        (shopCategoryId.dirty || shopCategoryId.touched)
                      "
                      class="text text-danger"
                    >
                      Please Select a Shop Category.
                    </div>
                  </div>
                </div> -->
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Select Category <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="categoryId"
                      (change)="onSelectCategory()"
                    >
                      <option [ngValue]="0">Select Category</option>
                      <option *ngFor="let cat of categoryList" [value]="cat.id">
                        {{ cat.category }}
                      </option>
                    </select>
                    <!-- *ngIf="
                      categoryId &&
                      !categoryId.value &&
                      (categoryId.dirty || categoryId.touched)
                    " -->
                    <div
                      *ngIf="f && formSubmitted && f.categoryId.value == 0"
                      class="text text-danger"
                    >
                      Please Select a Category.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom01" class="mb-0"
                      >Select Sub Category <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      formControlName="subCategoryId"
                    >
                      <option [ngValue]="0">Select Sub Category</option>
                      <option
                        *ngFor="let subCat of subCategoryList"
                        [value]="subCat.id"
                      >
                        {{ subCat.subcategory }}
                      </option>
                    </select>
                    <!-- *ngIf="
                      subCategoryId &&
                      !subCategoryId.value &&
                      (subCategoryId.dirty || subCategoryId.touched)
                    " -->
                    <div
                      *ngIf="f && formSubmitted && f.subCategoryId.value == 0"
                      class="text text-danger"
                    >
                      Please Select a Sub Category.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom04" class="mb-0"
                      >ShortDesc <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom04"
                      type="text"
                      placeholder="Add Short Description Here"
                      formControlName="shortDesc"
                    />
                    <div
                      *ngIf="
                        shortDesc &&
                        shortDesc.invalid &&
                        (shortDesc.dirty || shortDesc.touched)
                      "
                      class="text text-danger"
                    >
                      ShortDesc is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom04" class="mb-0"
                      >Tax Code <sup class="star">*</sup> :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom04"
                      type="text"
                      placeholder="Add tax code Here"
                      formControlName="taxCode"
                    />
                    <div class="text-success">
                      Get tax code from
                      <a
                        href="https://stripe.com/docs/tax/tax-codes"
                        target="_blank"
                        >https://stripe.com/docs/tax/tax-codes</a
                      >
                    </div>
                    <div
                      *ngIf="
                        taxCode &&
                        taxCode.invalid &&
                        (taxCode.dirty || taxCode.touched)
                      "
                      class="text text-danger"
                    >
                      Tax Code is required.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom03" class="mb-0"
                      >Product SKU :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom03"
                      type="text"
                      formControlName="sku"
                      placeholder="Please Enter Product SKU"
                    />
                    <!-- <div
                      *ngIf="
                        brand && brand.invalid && (brand.dirty || brand.touched)
                      "
                      class="text text-danger"
                    >
                      Brand is required.
                    </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom03" class="mb-0"
                      >Product UPC :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom03"
                      type="text"
                      formControlName="upc"
                      placeholder="Please Enter Product UPC"
                    />
                    <!-- <div
                      *ngIf="
                        brand && brand.invalid && (brand.dirty || brand.touched)
                      "
                      class="text text-danger"
                    >
                      Brand is required.
                    </div> -->
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom05" class="mb-0">Stock :</label>
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom05"
                      type="number"
                      placeholder="Please Enter Stock Here"
                      formControlName="stockTotal"
                    />
                    <div
                      *ngIf="
                        stockTotal &&
                        stockTotal.invalid &&
                        (stockTotal.dirty || stockTotal.touched)
                      "
                      class="text text-danger"
                    >
                      Stock is required.
                    </div>
                  </div>
                </div> -->
                <!-- <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0">Price :</label>
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <input
                      class="form-control"
                      id="validationCustom06"
                      type="text"
                      placeholder="Please Enter Price Here"
                      formControlName="price"
                    />
                    <div
                      *ngIf="
                        price && price.invalid && (price.dirty || price.touched)
                      "
                      class="text text-danger"
                    >
                      Price is required.
                    </div>
                  </div>
                </div> -->
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Add Description :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <!-- <ckeditor
                      [editor]="Editor"
                      formControlName="description"
                    ></ckeditor> -->
                    <quill-editor
                      formControlName="description"
                      style="height: 100px"
                    ></quill-editor>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Additional Information :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <!-- <ckeditor
                      [editor]="Editor"
                      formControlName="additionalInfo"
                    ></ckeditor> -->
                    <quill-editor
                      style="height: 100px"
                      formControlName="additionalInfo"
                    >
                    </quill-editor>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-3 col-sm-4">
                    <label for="validationCustom06" class="mb-0"
                      >Shipping & Returns :</label
                    >
                  </div>
                  <div class="col-xl-8 col-sm-7">
                    <!-- <ckeditor
                      [editor]="Editor"
                      formControlName="shippingReturn"
                    ></ckeditor> -->
                    <quill-editor
                      style="height: 100px"
                      formControlName="shippingReturn"
                    ></quill-editor>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="col-sm-8 offset-xl-3 offset-sm-4">
                  <div class="product-buttons">
                    <button
                      type="submit"
                      class="btn btn-primary me-1"
                      [disabled]="addProductForm.invalid"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      (click)="onDiscard()"
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
