import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CrmRoutingModule } from "./crm-routing.module";
import { SellerCrmComponent } from "./seller-crm/seller-crm.component";
import { CrmListComponent } from "./crm-list/crm-list.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  declarations: [SellerCrmComponent, CrmListComponent],
  imports: [
    CommonModule,
    CrmRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
  ],
})
export class CrmModule {}
