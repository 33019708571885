<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Add Geo Check</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-7">
              <form
                class="needs-validation"
                [formGroup]="addGeoCheckForm"
                (ngSubmit)="onAddGeo()"
              >
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-4 col-sm-4">
                      <label for="validationCustom01" class="mb-0"
                        >Geo Distance :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <b>{{ geoDistance }} Miles</b>
                    </div>
                  </div>
                </div>
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-4 col-sm-4">
                      <label for="validationCustom01" class="mb-0"
                        >Update Geo Check <sup class="star">*</sup> :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom01"
                        type="text"
                        placeholder="Please Enter Geo Check"
                        formControlName="geocheck"
                      />
                      <div
                        *ngIf="
                          geocheck &&
                          geocheck.invalid &&
                          (geocheck.dirty || geocheck.touched)
                        "
                        class="text text-danger"
                      >
                        Geo Check is required.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <div class="col-sm-8 offset-xl-3 offset-sm-4">
                    <div class="product-buttons">
                      <button
                        type="submit"
                        value="submit"
                        [disabled]="addGeoCheckForm.invalid"
                        class="btn btn-primary me-1"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        (click)="onDiscard()"
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
