import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "src/app/_services/auth/authentication.service";
import { PasswordValidation } from "src/app/_services/validators/password-validators";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordForm: any;
  sellerId: any;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.forgetPasswordForm = this.formBuilder.group(
      {
        password: ["", [Validators.required]],
        confirmPassword: ["", [Validators.required]],
      },
      { validator: PasswordValidation.MatchPassword }
    );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.sellerId = params["id"];
      console.log("this.sellerId", this.sellerId);
    });
  }

  onPasswordReset() {
    let fd = {
      password: this.forgetPasswordForm.value["password"],
      id: this.sellerId,
    };
    this.authService.changePassword(fd).subscribe((response: any) => {
      console.log("res", response);
      if (response.status == 0) {
        this.toastr.success("Password Changed Successfully");
        setTimeout(() => {
          this.router.navigate(["/auth/login"]);
        }, 5000);
      } else {
        this.toastr.error("Something went wrong, Please Try Again");
      }
    });
  }

  get confirmPassword() {
    return this.forgetPasswordForm.get("confirmPassword");
  }

  get password() {
    return this.forgetPasswordForm.get("password");
  }
}
