import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services/auth/authentication.service";
import { NavService } from "../../service/nav.service";
import { AppService } from "src/app/_services/appService/app.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  walletBalance: any;

  constructor(
    public navServices: NavService,
    private router: Router,
    private authService: AuthenticationService,
    private appService: AppService
  ) {}

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  ngOnInit() {
    this.getSellerWalletBalance();
  }
  onLogOut() {
    this.authService.logout();
    this.router.navigate(["/auth/login"]);
  }

  getSellerWalletBalance() {
    this.appService.getSellerWalletBalance().subscribe((res: any) => {
      this.walletBalance = res;
    });
  }
}
