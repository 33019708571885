<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>
            Upload Shop Offer
            <!-- <span class="text-dark"
              >(Please Upload 800 X 800 resolution photo/Images)</span
            > -->
          </h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <form class="needs-validation" [formGroup]="uploadOfferFrom">
              <div class="col-xl-7">
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Offer :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="offerId"
                        (change)="onSelectOffer()"
                      >
                        <option [ngValue]="0">Select Shop Offer</option>
                        <option
                          *ngFor="let offer of shopOfferSection"
                          [value]="offer.sectionId"
                        >
                          {{ offer?.sectionName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="offerShops" class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Index :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="index"
                      >
                        <option [ngValue]="0">Select Index</option>
                        <option *ngFor="let i of forIndex" [value]="i.id">
                          {{ i.value }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div *ngIf="offerShops" class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Shop :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="shopId"
                      >
                        <option [ngValue]="0">Select Shop</option>
                        <option
                          *ngFor="let shop of offerShops"
                          [value]="shop.shopId"
                        >
                          {{ shop.shopName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="offerShops" class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Upload Image :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        type="file"
                        class="form-control"
                        accept=".jpg,.jpeg"
                        (change)="fileChangeEvent($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div *ngIf="uploadedOfferImgs" class="row mb-5">
            <div
              class="col-sm-3 box front"
              *ngFor="let picture of uploadedOfferImgs"
            >
              <img
                class="responsive img-fluid blur-up lazyload bg-img mb-1"
                src="{{ picture?.catImageURL }}"
              />
              <!-- <div *ngIf="showUpload" class="product-hover text-center">
                <ul>
                  <li>
                    <button
                      class="btn myBtn border-danger"
                      type="button"
                      data-original-title=""
                      title=""
                      (click)="onDeleteClick(picture?.id)"
                    >
                      <i class="icon-trash text-danger"></i>
                    </button>
                  </li>
                  
                </ul>
              </div> -->
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="true"
                [aspectRatio]="3 / 2"
                format="jpg"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
              ></image-cropper>
            </div>
            <div class="col-md-4">
              <div
                *ngIf="!croppedImage && browseImg"
                id="container"
                class=".div"
              >
                <svg viewBox="0 0 100 100">
                  <defs>
                    <filter id="shadow">
                      <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="1.5"
                        flood-color="#fc6767"
                      />
                    </filter>
                  </defs>
                  <circle
                    id="spinner"
                    style="
                      fill: transparent;
                      stroke: #dd2476;
                      stroke-width: 7px;
                      stroke-linecap: round;
                      filter: url(#shadow);
                    "
                    cx="50"
                    cy="50"
                    r="45"
                  />
                </svg>
              </div>
              <img class="upload" [src]="croppedImage" />
            </div>
          </div>
          <!-- <div class="row mb-3">
            <div class="col-sm-3 box front" *ngFor="let picture of pictureList">
              <img
                class="responsive img-fluid blur-up lazyload bg-img mb-1"
                src="{{ picture.url }}"
              />
              <div class="product-hover text-center">
                <ul>
                  <li>
                    <button
                      class="btn myBtn border-danger"
                      type="button"
                      data-original-title=""
                      title=""
                      (click)="onDeleteClick(picture?.id)"
                    >
                      <i class="icon-trash text-danger"></i>
                    </button>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div> -->
          <div class="row mt-3" *ngIf="this.croppedImageToUpload">
            <div class="product-buttons">
              <button
                *ngIf="!isupload"
                #modal
                type="submit"
                class="btn btn-primary me-1"
                type="button"
                (click)="onSave()"
              >
                Upload
              </button>
              <button
                *ngIf="isupload"
                class="btn btn-primary me-1"
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Uploading...
              </button>
              <button
                *ngIf="!isupload"
                #modal
                type="submit"
                class="btn btn-danger me-1"
                type="button"
                (click)="onCancel()"
              >
                Cancel
              </button>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <h5 class="modal-title f-w-600" id="exampleModalLabel">
                    Choose All Images For Product
                  </h5>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form class="needs-validation" [formGroup]="uploadOfferFrom">
                    <div class="form">
                      <div class="form-group">
                        <label for="validationCustom01" class="mb-1"
                          >Image :</label
                        >
                        <input
                          class="form-control"
                          id="validationCustom01"
                          type="file"
                          multiple
                          accept=".png,.jpg,.jpeg"
                          (change)="onPictureUpload($event)"
                        />
                      </div>
                      <!-- <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="register-policy-2"
                          required
                          (click)="onChecked()"
                        />
                        <label
                          class="custom-control-label"
                          for="register-policy-2"
                        >
                          Optimize image & remove background</label
                        >
                      </div> -->
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    (click)="onSave(); modal.dismiss()"
                  >
                    Save
                  </button>
                  <button
                    class="btn btn-secondary"
                    type="button"
                    data-dismiss="modal"
                    (click)="modal.dismiss('Cross click')"
                  >
                    Close
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <!-- <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Edit Picture</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="onCloseModal()"
          ></button>
        </div>
        <div class="modal-body">
          <form action="" [formGroup]="editImageForm">
            <label for="validationCustom01" class="mb-1">Image:</label>
            <input
              type="file"
              class="form-control mb-2"
              formControlName="image"
              (change)="onEditPictureUpload($event)"
            />
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            (click)="onCloseModal()"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" (click)="onUpdate()">
            Update
          </button>
        </div>
      </div>
    </div>
  </div> -->
</div>
