import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CreateShopComponent } from "./create-shop/create-shop.component";
import { ShopCategoryComponent } from "./shop-category/shop-category.component";
import { ShopListComponent } from "./shop-list/shop-list.component";
import { AddShopDetailsComponent } from "./add-shop-details/add-shop-details.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "create-shop",
        component: CreateShopComponent,
        data: {
          title: "Create Shop",
          breadcrumb: "Create Shop",
        },
      },
      {
        path: "Add-Shop-Details",
        component: AddShopDetailsComponent,
        data: {
          title: "Add Shop Details",
          breadcrumb: "Add Shop Details",
        },
      },
      {
        path: "Add-Shop-Details/:id",
        component: AddShopDetailsComponent,
        data: {
          title: "Add Shop Details",
          breadcrumb: "Add Shop Details",
        },
      },
      {
        path: "shop-category",
        component: ShopCategoryComponent,
        data: {
          title: "Shop Category",
          breadcrumb: "Shop Category",
        },
      },
      {
        path: "shop-category/:id",
        component: ShopCategoryComponent,
        data: {
          title: "Shop Category",
          breadcrumb: "Shop Category",
        },
      },
      {
        path: "shop-list",
        component: ShopListComponent,
        data: {
          title: "Shop List",
          breadcrumb: "Shop List",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShopRoutingModule {}
