import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services/auth/authentication.service";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { log } from "console";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;
  checkedAgree: boolean = false;
  @ViewChild("login") login!: ElementRef;
  @ViewChild("modal") modal!: ElementRef;
  loginMessage: any;
  message: any;
  loginClick: boolean = true;
  regdClick: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private sessionService: SessionManagerService,
    private authService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.createLoginForm();
    this.createRegisterForm();
  }
  ngOnDestroy(): void {
    this.loginClick = true;
    this.regdClick = false;
  }

  owlcarousel = [
    {
      title: "Welcome to TE13, Inc",
      desc: "Your Community-first E-market",
      login: "Please Login To TE13",
      redg: "Please Register With Us",
    },
    // {
    //   title: "Welcome to Te13",
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    // },
    // {
    //   title: "Welcome to Te13",
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    // },
  ];
  owlcarouselOptions = {
    loop: false,
    items: 1,
    dots: false,
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: [""],
      password: [""],
    });
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: ["", Validators.required],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
          ),
        ],
      ],
      register_firstName: ["", Validators.required],
      register_lastName: ["", Validators.required],
      // confirmPassword: [""],
    });
  }

  ngOnInit() {}
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  onSubmitLogin() {
    console.log("loginForm", this.loginForm.value);

    let userName: string = this.loginForm.value["userName"];
    let password: string = this.loginForm.value["password"];
    this.authService.login(userName, password).subscribe(
      (s) => {
        console.log("login", s);
        console.log("status", s.statusDesc, s.status);
        this.loginMessage = s.statusDesc;

        if (s.status == "0") {
          this.toastr.success(this.loginMessage);
          this.router.navigate(["/dashboard/default"]);
        } else {
          this.toastr.error(s.statusDesc);
        }
      },
      (error) => {
        console.log("error", error);
        this.router.navigate(["/auth/login"]);
        this.toastr.error("Email does not exist!,Try Again");
      }
    );
  }
  onSubmitRegister() {
    console.log("registerForm", this.registerForm.value);
    this.showSpinner();
    // this.login.nativeElement.click();
    // this.toastr.success("Seller Profile Created Successfully!, Please Login");
    let register_firstName: string =
      this.registerForm.value["register_firstName"];
    let register_lastName: string =
      this.registerForm.value["register_lastName"];
    let userName: string = this.registerForm.value["userName"];
    let password: string = this.registerForm.value["password"];
    let fullName: string = register_firstName.concat(" ", register_lastName);
    let email: string = userName;
    console.log("log", userName, password, fullName, email);
    // return;
    this.authService
      .register(userName, password, fullName, email)
      .subscribe((r) => {
        this.showSpinner();
        console.log("register", r);
        this.message = r.statusDesc;
        if (r.status == 0) {
          this.login.nativeElement.click();
          this.toastr.success(
            "Seller Profile Created Successfully!, Please Login"
          );
          this.modal.nativeElement.click();
          this.registerForm.reset();
          this.checkedAgree = false;
        } else {
          this.toastr.error(this.message);
          this.router.navigate(["/auth/login"]);
        }
      });
  }
  onClick() {
    this.onSubmitLogin();
  }

  onChecked() {
    this.checkedAgree = !this.checkedAgree;
    console.log("checkedAgree", this.checkedAgree);
  }

  onClickForgetPassword() {
    console.log("forgetPassword");

    let fd = {
      email: this.loginForm.value["userName"],
      userType: "SELLER",
    };
    console.log("email", this.loginForm.value["userName"]);

    if (fd.email == "") {
      this.toastr.error("Please Provide Your Email");
    } else {
      this.authService.forgetPassword(fd).subscribe((response: any) => {
        console.log("response", response);
        this.toastr.info("Please Check Your Email");
      });
    }
  }

  onLoginClick() {
    this.loginClick = true;
    this.regdClick = false;
  }

  onRegdClick() {
    this.loginClick = false;
    this.regdClick = true;
  }

  get Password() {
    return this.registerForm.get("Password");
  }
}
