import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CategoryComponent } from "./category/category.component";
import { CreateSizeComponent } from "./create-size/create-size.component";
import { SubCategoryComponent } from "./sub-category/sub-category.component";
import { UploadbannersComponent } from "./uploadbanners/uploadbanners.component";
import { GeoTrackComponent } from "./geo_Track/geo-track/geo-track.component";
import { DeliveryByAdminComponent } from "./delivery-by-admin/delivery-by-admin.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "create-category",
        component: CategoryComponent,
        data: {
          title: "Create Category",
          breadcrumb: "Create Category",
        },
      },
      {
        path: "create-sub-category",
        component: SubCategoryComponent,
        data: {
          title: "Create sub-category",
          breadcrumb: "Create sub-category",
        },
      },
      {
        path: "create-size",
        component: CreateSizeComponent,
        data: {
          title: "Create Size",
          breadcrumb: "Create Size",
        },
      },
      {
        path: "upload-banners",
        component: UploadbannersComponent,
        data: {
          title: "Upload Banners",
          breadcrumb: "Upload Banners",
        },
      },
      {
        path: "geo_check",
        component: GeoTrackComponent,
        data: {
          title: "Geo Check",
          breadcrumb: "Geo Check",
        },
      },
      {
        path: "delivery",
        component: DeliveryByAdminComponent,
        data: {
          title: "Delivery",
          breadcrumb: "Delivery",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoryRoutingModule {}
