import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ProductService } from "src/app/_services/product/product.service";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit {
  orderProductId: any;
  orderDetails: any;
  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((param: any) => {
      this.orderProductId = param.get("id");
      console.log("orderProductId", this.orderProductId);
    });
    this.getOrderDeatailsById();
  }

  getOrderDeatailsById() {
    this.productService
      .getOrderDetailsByorderProductId(this.orderProductId)
      .subscribe((res: any) => {
        this.orderDetails = res;
        console.log("this.orderDetails", this.orderDetails);
      });
  }

  orderStatusChange(event: any) {
    console.log("event", event.target.value);
    let value = event.target.value;
    if (value == 1) {
      this.productService
        .orderReadyForPickupByorderProductId(this.orderProductId)
        .subscribe((res: any) => {
          console.log("res", res);
          if (res.status == 0) {
            this.getOrderDeatailsById();
            this.toastr.success("Order Status Updated Successfully");
          } else {
            this.toastr.error("Something Went Wrong ! Please Try Again");
          }
        });
    } else if (value == 2) {
      this.productService
        .orderCompleteByorderProductId(this.orderProductId)
        .subscribe((res: any) => {
          console.log("res", res);
          if (res.status == 0) {
            this.getOrderDeatailsById();
            this.toastr.success("Order Status Updated Successfully");
          } else {
            this.toastr.error("Something Went Wrong ! Please Try Again");
          }
        });
    } else {
      return;
    }
  }
}
