import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { DecimalPipe } from "@angular/common";
import { TableService } from "src/app/shared/service/table.service";
import { SortEvent } from "src/app/shared/directives/shorting.directive";
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { Size, SizeList } from "src/app/_models/category/size";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-create-size",
  templateUrl: "./create-size.component.html",
  styleUrls: ["./create-size.component.scss"],
  providers: [TableService, DecimalPipe],
})
export class CreateSizeComponent implements OnInit {
  public closeResult: string;
  allSizes: any;
  searchText;

  total$: Observable<number>;
  items: any;
  page: number = 1;
  editItem: boolean = false;
  pageSize: number = 10;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild("addSize") addSize!: ElementRef;

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  sizes: Size[] = [];
  // sizes: any;
  allSizesLength: any;
  sizeId: any;
  changedValue: any = "";
  constructor(
    public service: TableService,
    private modalService: NgbModal,
    private categoryService: CategoryServiceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.total$ = service.total$;
  }

  onSort({ column, direction }) {
    // resetting other headers
    console.log("dsafsa");

    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.showSpinner();
    this.getAllSizes();
  }

  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  //Get All Sizes

  getAllSizes() {
    this.categoryService.getAllSize().subscribe((res: any) => {
      console.log("res", res);
      this.allSizesLength = res.sizeList.length;
      this.allSizes = res.sizeList
        .map((result: any, index: any) => {
          // console.log("this.allSizes", result);
          return {
            item: result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
      console.log("this.allSizes", this.allSizes);
    });
  }

  //Adding Value in MatChip

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    let payload = { sizeName: value, status: "active" };
    // Add our size
    if (value) {
      this.sizes.push(payload);
    }

    // Clear the input value
    event.chipInput!.clear();
    console.log(this.sizes);
  }

  //Removing Value From MatChip

  remove(size: any): void {
    const index = this.sizes.indexOf(size);

    if (index >= 0) {
      this.sizes.splice(index, 1);
    }
    console.log(this.sizes);
  }

  //Save Sizess(on Save Button Click of Modal)
  onSizeSave() {
    if (!this.sizeId) {
      let sizeList: SizeList = { sizeList: this.sizes };
      this.categoryService.createSize(sizeList).subscribe((response: any) => {
        console.log("response", response);

        if (response.status == 0) {
          this.showSpinner();
          this.toastr.success("Size Created Successfully");
          this.getAllSizes();
        }
      });
    } else {
      let updateData = {
        sizeId: this.sizeId,
        sizeName: this.changedValue,
        status: "active",
      };
      this.categoryService
        .updateSize(this.sizeId, updateData)
        .subscribe((response: any) => {
          console.log("response", response);
          this.sizeId = 0;
          this.changedValue = "";
          if (response.status == 0) {
            this.showSpinner();
            this.toastr.success("Size Updated Successfully");
            this.getAllSizes();
          }
        });
    }

    return this.onDismiss();
  }
  onSizeChange(value) {
    this.changedValue = value;
    console.log(value);
  }
  //on Size Edit Click
  onSizeEditClick(size: any) {
    this.changedValue = size.sizeName;
    this.sizeId = size.sizeId;
    this.addSize.nativeElement.click();
  }
  //on Size Delete Click
  onSizeDeleteClick(size: any) {
    console.log("sizeDelete", size);
    let id = size.sizeId;
    this.categoryService.deleteSize(id).subscribe((res: any) => {
      console.log("res", res);
      if (res.status == 0) {
        this.showSpinner();
        this.getAllSizes();
        this.toastr.success("Size Delete Successfully");
      }
    });
  }
  //On Close Button Click of Modal
  onDismiss() {
    return (this.sizes = []);
  }
}
