import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CropperPosition,
  CropperSettings,
  ImageCroppedEvent,
  LoadedImage,
} from "ngx-image-cropper";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";
import { ProductService } from "src/app/_services/product/product.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-uploadbanners",
  templateUrl: "./uploadbanners.component.html",
  styleUrls: ["./uploadbanners.component.scss"],
})
export class UploadbannersComponent implements OnInit {
  category_list: any[] = [];
  pictureList: any[] = [];
  noPicture: boolean = true;
  showUpload: boolean = false;
  public closeResult: string;
  pictureUrl: any;
  pictureSrc: any;
  bannerUploadForm: FormGroup;
  productId: any;
  loaded = false;
  banners: any;
  is_checked: any;
  url: any;
  imageMessage: any = "";
  saveImageform: FormGroup;
  isupload: boolean = false;
  private settings = new CropperSettings();
  croppedImage: any;
  @Input() cropper: CropperPosition = {
    x1: -100,
    y1: -100,
    x2: 10000,
    y2: 10000,
  };
  browseImg: boolean = false;
  colorArray = [
    { id: 1, color: "#964B00", color_name: "brown" },
    { id: 2, color: "#C0C0C0", color_name: "silver" },
    { id: 3, color: "#808080", color_name: "gray" },
    { id: 4, color: "#800000", color_name: "maroon" },
    { id: 5, color: "#FFFFFF", color_name: "white" },
    { id: 6, color: "#800080", color_name: "purple" },
    { id: 7, color: "#FF0000", color_name: "red" },
    { id: 9, color: "#000000", color_name: "black" },
    { id: 10, color: "#FF00FF", color_name: "fuchsia" },
    { id: 11, color: "#008000", color_name: "green" },
    { id: 12, color: "#00FF00", color_name: "lime" },
    { id: 13, color: "#808000", color_name: "olive" },
    { id: 14, color: "#FFFF00", color_name: "yellow" },
    { id: 15, color: "#000080", color_name: "navy" },
    { id: 16, color: "#0000FF", color_name: "blue" },
    { id: 17, color: "#008080", color_name: "teal" },
  ];
  selectColorId: any;
  selectedColorName: string = "white";
  selectedColorCode: string = "#FFFFFF";
  bannerDetails: any;
  bannerEditClick: boolean = false;
  bannerId: any;
  imageChangedEvent: any;
  croppedImageToUpload: any;
  constructor(
    private productService: ProductService,
    private categoryService: CategoryServiceService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer
  ) {
    this.bannerUploadForm = this.formBuilder.group({
      // bannerText: [""],
      // bannerTitle: [""],
      // bannerPrice: [""],
      // uploadType: ["", [Validators.required]],
      categoryId: ["", [Validators.required]],
      linkUrl: ["", [Validators.required]],
      image: ["", [Validators.required]],
      // colorName: ["", [Validators.required]],
    });
    // this.bannerUploadForm.controls["uploadType"].setValue(0);
    this.bannerUploadForm.controls["categoryId"].setValue(0);
    // this.bannerUploadForm.controls["colorName"].setValue(0);
    this.saveImageform = this.formBuilder.group({
      image: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.showSpinner();
    this.getAllCategory();
    this.getAllBanners();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  getAllCategory() {
    this.categoryService.getAllCategory().subscribe((response: any) => {
      this.category_list = response.categoryList;
      console.log("category_list", this.category_list);
    });
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // onPictureUpload(picture: any) {
  //   console.log("picture", picture.target.files);

  //   if (picture.target.files && picture.target.files[0]) {
  //     const reader = new FileReader();
  //     let image: any = picture.target.files[0];
  //     // let size = image.size;
  //     //  let fr = new FileReader();
  //     reader.onload = () => {
  //       console.log("blank");

  //       // when file has loaded

  //       //  img.src = reader.result;
  //     };

  //     let img = new Image();
  //     img.onload = () => {
  //       console.log("onload");

  //       let width = img.width;
  //       let height = img.height;
  //       console.log("width", width, height);
  //     };
  //     // reader.onload = (e: any) => {
  //     //   this.pictureUrl = e.target.result;
  //     // };
  //     // reader.readAsDataURL(picture.target.files[0]);
  //     // this.pictureSrc = picture.target.files[0];
  //   }
  // }

  // onSelectFile(event: any) {
  //   if (event.target.files && event.target.files[0]) {
  //     var reader = new FileReader();

  //     reader.readAsDataURL(event.target.files[0]);
  //     reader.onload = (event) => {
  //       let img: any = new Image();
  //       this.url = event.target.result;
  //       img.src = event.target.result;
  //       console.log("img.width", img.width, img.height);
  //     };
  //   }
  // }

  // readImageFile(file) {
  //   var reader = new FileReader(); // CREATE AN NEW INSTANCE.

  //   reader.onload = function (e) {
  //     let img: any = new Image();
  //     img.src = e.target.result;
  //     console.log("img", img);

  //     img.onload = function () {
  //       var w = this.width;
  //       var h = this.height;
  //       console.log("w", w, h);
  //     };
  //   };
  // }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    const img = new Image();
    const reader = new FileReader();
    console.log(
      'this.bannerUploadForm.value["uploadType"]',
      this.bannerUploadForm.value["uploadType"]
    );

    reader.onloadend = () => {
      img.src = reader.result as string;

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        console.log("w,h", width, height);
        if (this.bannerUploadForm.value["uploadType"] == "Banner") {
          if (width < 1920 || height < 400) {
            this.saveImageform.controls["image"].setValue("");
            return this.toastr.error(
              "Please Upload 1920 X 500 resolution photo"
            );
          }
        } else if (this.bannerUploadForm.value["uploadType"] == "ExtraLarge") {
          if (width < 470 || height < 510) {
            this.saveImageform.controls["image"].setValue("");
            return this.toastr.error(
              "Please Upload 470 X 510 resolution photo"
            );
          }
        } else if (this.bannerUploadForm.value["uploadType"] == "DoubleHight") {
          if (width < 290 || height < 510) {
            this.saveImageform.controls["image"].setValue("");
            return this.toastr.error(
              "Please Upload 290 X 510 resolution photo"
            );
          }
        } else if (this.bannerUploadForm.value["uploadType"] == "Small") {
          if (width < 370 || height < 545) {
            this.saveImageform.controls["image"].setValue("");
            return this.toastr.error(
              "Please Upload 370 X 545 resolution photo"
            );
          }
        }
        this.pictureSrc = file;
      };
    };

    reader.readAsDataURL(file);
  }
  onClose() {
    // this.saveImageform.reset();
    this.bannerUploadForm.reset();
    this.bannerUploadForm.reset();
    this.selectedColorName = "";
    this.selectedColorCode = "";
    this.croppedImageToUpload = "";
    this.croppedImage = "";
    this.imageChangedEvent = "";
    this.browseImg = false;
    // this.bannerUploadForm.controls["uploadType"].setValue(0);
    this.bannerUploadForm.controls["categoryId"].setValue(0);
  }
  // onPictureUpload(picture: any) {
  //   console.log("picture", picture.target.files);
  //   let file: any;
  //   if (picture.target.files && picture.target.files[0]) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const img = new Image();
  //       img.src = reader.result as string;

  //       img.onload = () => {
  //         const width = img.width;
  //         const height = img.height;

  //         console.log("Image resolution:", width, "x", height);

  //         // Perform your validation logic here
  //         if (width > 800 || height > 600) {
  //           console.log("Invalid image resolution");
  //           // Display an error message or take appropriate action
  //         } else {
  //           console.log("Valid image resolution");
  //           // Proceed with further actions or upload the image
  //         }
  //       };
  //     };

  //     reader.readAsDataURL(file);

  //     reader.onload = (e: any) => {
  //       this.pictureUrl = e.target.result;
  //     };
  //     reader.readAsDataURL(picture.target.files[0]);
  //     this.pictureSrc = picture.target.files[0];
  //   }
  // }

  onSelectColor() {
    this.selectColorId = this.bannerUploadForm.value["colorName"];
    // console.log("this.selectShopId", this.selectShopId);
    let color = this.colorArray.filter((res: any) => {
      // console.log("res", res);

      return res.id == this.selectColorId;
    });
    // console.log("color", color);
    this.selectedColorName = color[0].color_name;
    this.selectedColorCode = color[0].color;
    console.log(
      "selectedColor",
      this.selectedColorName,
      this.selectedColorCode
    );
  }

  onSave() {
    this.isupload = true;
    let data: any = this.bannerUploadForm.value;
    const save_inputs = new FormData();
    // save_inputs.append("bannerText", this.bannerUploadForm.value["bannerText"]);
    save_inputs.append("uploadType", "Banner");
    save_inputs.append("categoryId", this.bannerUploadForm.value["categoryId"]);
    save_inputs.append("linkUrl", this.bannerUploadForm.value["linkUrl"]);
    // save_inputs.append(
    //   "bannerTitle",
    //   this.bannerUploadForm.value["bannerTitle"]
    // );
    // save_inputs.append(
    //   "bannerPrice",
    //   this.bannerUploadForm.value["bannerPrice"]
    // );
    // save_inputs.append("colorCode", this.selectedColorCode);
    // save_inputs.append("colorName", this.selectedColorName);
    save_inputs.append("file", this.croppedImageToUpload);
    // Object.keys(this.pictureSrc).forEach((key) => {
    //   save_inputs.append("file", this.pictureSrc[key]);
    // });
    console.log("res", this.bannerUploadForm.value);
    // return;
    this.categoryService.uploadBanner(save_inputs).subscribe((res: any) => {
      console.log("res", res);
      if (res.status == 0) {
        this.isupload = false;
        this.toastr.success("Banner Uploded Successfully");
        this.bannerUploadForm.reset();
        this.selectedColorName = "";
        this.selectedColorCode = "";
        this.croppedImageToUpload = "";
        this.croppedImage = "";
        this.imageChangedEvent = "";
        this.browseImg = false;
        // this.bannerUploadForm.controls["uploadType"].setValue(0);
        this.bannerUploadForm.controls["categoryId"].setValue(0);
        // this.bannerUploadForm.controls["colorName"].setValue(0);
        this.showSpinner();
        this.getAllBanners();
      } else {
        this.isupload = false;
        this.toastr.error("Something Went Wrong! Please Try Again");
      }
      // this.router.navigate(["/products/create-variant"]);
    });
  }
  onDismiss() {
    this.isupload = false;
    return 0;
  }
  getAllBanners() {
    this.categoryService.getAllBanners().subscribe((res: any) => {
      this.banners = res;
      console.log("banners", this.banners);
    });
  }
  onDeleteBannerClick(id: any) {
    console.log(id);
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `SAVE`,
      denyButtonText: `CANCEL`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (id !== 0) {
        this.categoryService.deleteBanner(id).subscribe(
          (response: any) => {
            console.log("tttt", response);
            if (response?.status == "0") {
              Swal.fire("Banner Deleted Successfully", "success");
              this.showSpinner();
              this.getAllBanners();
            } else {
              this.toastr.error(response?.statusDesc);
              this.getAllBanners();
            }
          },
          (error) => {
            console.log("error", error);
            this.toastr.error("Unable to delete banner,Please Try Again");
          }
        );
      } else if (result.isDenied) {
        this.getAllBanners();
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onActionClick(event: any, id: any, active: any) {
    console.log(id);
    this.is_checked = active;
    console.log("is_Active", active);
    console.log("checked", this.is_checked);

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `SAVE`,
      denyButtonText: `CANCEL`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        if (event.target.checked) {
          this.is_checked = 1;
          console.log("is", this.is_checked);
        } else {
          this.is_checked = 0;
          // console.log('ddd', this.is_checked);
        }
        const save_inputs = {
          is_active: this.is_checked,
        };
        console.log(save_inputs);

        if (id !== 0) {
          //add
          // save_inputs.append('id', this.driverId);
          this.categoryService
            .softDeleteBanners(id)
            .subscribe((response: any) => {
              console.log("tttt", response);
              Swal.fire("Saved!", "", "success");
              this.showSpinner();
              this.getAllBanners();
            });
        }
      } else if (result.isDenied) {
        this.getAllBanners();
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onEditBannerClick(id: any) {
    this.bannerEditClick = true;
    console.log("editedBanner", id);
    this.bannerId = id;
    this.categoryService.getBannerByBannerId(id).subscribe((res: any) => {
      this.bannerDetails = res;
      let color = this.colorArray.filter((result: any) => {
        // console.log("res", res);

        return result.color_name == this.bannerDetails.colorName;
      });
      console.log("bannerDetails", this.bannerDetails, color);
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.bannerUploadForm.patchValue({
          // bannerText: this.bannerDetails.banner_text,
          // bannerTitle: this.bannerDetails.bannerTitle,
          // bannerPrice: this.bannerDetails.bannerPrice,
          // uploadType: this.bannerDetails.uploadType,
          categoryId: this.bannerDetails.categoryId,
          linkUrl: this.bannerDetails.linkUrl,
          // colorName: color[0]?.id,
        });
      }, 10);
    });
  }
  onUpdateClick() {
    let fd = {
      id: this.bannerId,
      categoryId: this.bannerUploadForm.value["categoryId"],
      // banner_text: this.bannerUploadForm.value["bannerText"],
      linkUrl: this.bannerUploadForm.value["linkUrl"],
      // colorName: this.selectedColorName
      //   ? this.selectedColorName
      //   : this.bannerDetails.colorName,
      // bannerTitle: this.bannerUploadForm.value["bannerTitle"],
      // bannerPrice: this.bannerUploadForm.value["bannerPrice"],
    };
    console.log("fd", fd);
    this.categoryService.updateBanner(fd).subscribe((res: any) => {
      console.log("res", res);
      if (res.status == 0) {
        this.bannerEditClick = false;
        this.toastr.success("Banner Update Successfully");
        this.bannerUploadForm.reset();
        // this.selectedColorName = "white";
        // this.selectedColorCode = "#FFFFFF";
        // this.bannerUploadForm.controls["uploadType"].setValue(0);
        this.bannerUploadForm.controls["categoryId"].setValue(0);
        // this.bannerUploadForm.controls["colorName"].setValue(0);
        this.showSpinner();
        this.getAllBanners();
      } else {
        this.toastr.error("Something Went Wrong! Please Try Again");
      }
    });
  }

  onDiscard() {
    this.bannerUploadForm.reset();
    this.bannerEditClick = false;
    this.selectedColorName = "white";
    this.selectedColorCode = "#FFFFFF";
    // this.bannerUploadForm.controls["uploadType"].setValue(0);
    this.bannerUploadForm.controls["categoryId"].setValue(0);
    // this.bannerUploadForm.controls["colorName"].setValue(0);
    this.getAllBanners();
  }

  onChangeBannerType(event: any) {
    let obj = {
      Banner: () => {
        this.imageMessage = "Please Upload 1920 X 500 resolution photo";
      },
      ExtraLarge: () => {
        this.imageMessage = "Please Upload 470 X 510 resolution photo";
      },
      DoubleHight: () => {
        this.imageMessage = "Please Upload 290 X 510 resolution photo";
      },
      Small: () => {
        this.imageMessage = "Please Upload 370 X 545 resolution photo";
      },
      0: () => {
        this.imageMessage = "";
      },
    };
    return obj[event.target.value]();
  }
  get bannerText() {
    return this.bannerUploadForm.get("bannerText");
  }
  get bannerTitle() {
    return this.bannerUploadForm.get("bannerTitle");
  }

  get bannerPrice() {
    return this.bannerUploadForm.get("bannerPrice");
  }
  get uploadType() {
    return this.bannerUploadForm.get("uploadType");
  }
  get categoryId() {
    return this.bannerUploadForm.get("categoryId");
  }
  get linkUrl() {
    return this.bannerUploadForm.get("linkUrl");
  }
  // get color() {
  //   return this.bannerUploadForm.get("color");
  // }

  fileChangeEvent(event: any): void {
    this.croppedImage = "";
    this.browseImg = true;
    this.imageChangedEvent = event;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pictureUrl = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.croppedImageToUpload = event.target.files;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    console.log("this.croppedImage", this.croppedImage);
    const blob = event.blob;
    console.log("blob", blob);

    this.croppedImageToUpload = blob;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
