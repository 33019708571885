import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CropperPosition,
  CropperSettings,
  ImageCroppedEvent,
  LoadedImage,
} from "ngx-image-cropper";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, ReplaySubject, map } from "rxjs";
import { ProductService } from "src/app/_services/product/product.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-upload-pictures",
  templateUrl: "./upload-pictures.component.html",
  styleUrls: ["./upload-pictures.component.scss"],
})
export class UploadPicturesComponent implements OnInit {
  product_list: any[] = [];
  pictureList: any[] = [];
  noPicture: boolean = true;
  showUpload: boolean = false;
  public closeResult: string;
  pictureUrl: any;
  pictureSrc: any;
  createUploadForm: FormGroup;
  editImageForm: FormGroup;
  selectProductFrom: FormGroup;
  productId: any;
  loaded = false;
  editPictureUrl: any;
  editPictureSrc: any;
  @ViewChild("mySelect") mySelect: ElementRef;
  pictureId: any;
  isupload: boolean = false;
  allowedExtension = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/bmp",
  ];
  base64Output: string;
  convertImg: any;
  base64String: string;
  downLoadfile: File;
  backRemovefile: File;
  base64Stringafter: string;
  checkedAgree: boolean = false;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  path: any;
  @Input() cropper: CropperPosition = {
    x1: -100,
    y1: -100,
    x2: 10000,
    y2: 10000,
  };
  private settings = new CropperSettings();

  croppedImage2: any;
  croppedImageToUpload: any;
  browseImg: boolean = false;
  constructor(
    private productService: ProductService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.createUploadForm = this.formBuilder.group({
      height: [""],
      width: [""],
    });
    this.selectProductFrom = this.formBuilder.group({
      productId: ["", [Validators.required]],
    });
    this.selectProductFrom.controls["productId"].setValue(0);
    this.editImageForm = this.formBuilder.group({
      image: [""],
    });
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((param: any) => {
      let productId = param.get("id") || 0;
      this.getAllProduct(productId);
      this.path = this.activeRoute.snapshot.paramMap.get("path");
      console.log("edit", this.path);
    });
    // this.getAllProduct();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  getAllProduct(productId?) {
    this.productService.getAllProducts().subscribe((response: any) => {
      this.product_list = response;
      this.productId = this.productId || productId;
      this.onSelected(this.productId);
      console.log("product_list", this.product_list);
    });
  }
  // getAllProduct() {
  //   this.activeRoute.params.subscribe((param) => {
  //     this.loaded = false;
  //     this.productService
  //       .getAllProducts(param, "/v1/getAllProduct", 5)
  //       .subscribe((response: any) => {
  //         this.product_list = response;
  //         console.log("product_list", this.product_list);
  //       });
  //   });
  // }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  onSelected(value?) {
    console.log("value", value);
    this.productId = value;
    if (!value) return;
    this.productService
      .getPictureByProductId(value)
      .subscribe((response: any) => {
        this.pictureList = response;
        this.showUpload = true;
        if (this.pictureList != null) {
          this.noPicture = false;
        } else {
          this.noPicture = true;
        }
        console.log("pictureList", this.pictureList);
      });
  }
  // onPictureUpload(picture: any) {
  //   console.log("picture", picture.target.files);
  //   // this.convertFile(picture.target.files[0]).subscribe((base64) => {
  //   //   this.base64Output = base64;
  //   //   let fd = {
  //   //     image_b64: this.base64Output,
  //   //   };
  //   //   this.productService.convertPicture(fd).subscribe((result: any) => {
  //   //     this.convertImg = result;
  //   //     console.log("this.convertImg", this.convertImg);
  //   //   });
  //   //   console.log("this.base64Output", this.base64Output);
  //   // });
  //   if (picture.target.files && picture.target.files[0]) {
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.pictureUrl = e.target.result;
  //       this.base64String = reader.result as string;
  //       console.log("this.base64String", this.base64String);
  //       console.log("this.pictureUrl", this.pictureUrl);
  //       const save_inputs = new FormData();
  //       save_inputs.append("image_b64", this.base64String);
  //       save_inputs.append("timezone", "Asia/Calcutta");
  //       save_inputs.append(
  //         "page_address",
  //         "https://predis.ai/free-ai-tools/background-remover/"
  //       );
  //       save_inputs.append("app", "desktop");
  //       this.productService
  //         .convertPicture(save_inputs)
  //         .subscribe((result: any) => {
  //           this.convertImg = result.data.bg_removed_image;
  //           console.log("this.convertImg", this.convertImg);
  //           this.downloadImage(this.convertImg);
  //         });
  //     };
  //     reader.readAsDataURL(picture.target.files[0]);
  //     this.pictureSrc = picture.target.files;
  //     console.log("this.pictureSrc", this.pictureSrc);
  //   }
  // }

  onPictureUpload(picture: any) {
    console.log("picture", picture.target.files);

    if (picture.target.files && picture.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pictureUrl = e.target.result;
      };
      reader.readAsDataURL(picture.target.files[0]);
      this.pictureSrc = picture.target.files;
    }
  }

  //converting imgUrl to Base64
  // downloadImageBase64(imgURL: any): void {
  //   this.http.get(imgURL, { responseType: "arraybuffer" }).subscribe(
  //     (data: ArrayBuffer) => {
  //       // Convert ArrayBuffer to Base64
  //       const bytes = new Uint8Array(data);
  //       let binary = "";
  //       for (let i = 0; i < bytes.length; i++) {
  //         binary += String.fromCharCode(bytes[i]);
  //       }
  //       this.base64Stringafter = btoa(binary);
  //       this.onConvert(this.base64Stringafter);
  //     },
  //     (error) => {
  //       console.error("Error downloading image:", error);
  //     }
  //   );
  // }

  //Convert imgUrl to File directly
  // downloadImage(imgURL: any): void {
  //   this.http.get(imgURL, { responseType: "blob" }).subscribe(
  //     (res) => {
  //       console.log("res", res);

  //       // Convert Blob to File
  //       let blob = new Blob([res], { type: "image/png" });
  //       this.downLoadfile = new File([blob], "downloaded-image.png", {
  //         type: blob.type,
  //       });
  //       console.log("this.downLoadfile", this.downLoadfile);
  //     },
  //     (error) => {
  //       console.error("Error downloading image:", error);
  //     }
  //   );
  // }
  //Code provided by Bishal Sir
  // this.signModalDataService.getPreviewSignature({'name': this.SignatureName, 'font_type': 0});
  //     this.signModalDataService.previewSignature
  //       .subscribe((res) => {
  //         console.log(res);
  //         let blob = new Blob([res['_body']], {type: 'image/png'});
  //         this.previewSignsrc = URL.createObjectURL(blob);
  //         this.showPreviewSign = true;
  //       });

  // getImage(imgURL: any) {
  //   return this.http
  //     .get(imgURL, {
  //       responseType: "arraybuffer",
  //     })
  //     .pipe(
  //       map((response) => {
  //         return new File([response], "myImage.png");
  //       })
  //     );
  // }

  //Hear we convert base64 to blob
  // convertToBlob(base64String: string): Blob {
  //   const byteCharacters = atob(base64String);
  //   const byteNumbers = new Array(byteCharacters.length);

  //   for (let i = 0; i < byteCharacters.length; i++) {
  //     byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }

  //Hear we convert blob to file
  //   convertToFile(blob: Blob): void {
  //     this.backRemovefile = new File([blob], "converted-file.png", {
  //       type: blob.type,
  //     }); // Adjust the filename and type accordingly
  //   }

  // onConvert(base64String: any): void {
  //   const blob = this.convertToBlob(base64String);
  //   this.convertToFile(blob);
  // }

  onEditPictureUpload(picture: any) {
    console.log("picture", picture.target.files);

    if (picture.target.files && picture.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.editPictureUrl = e.target.result;
      };
      reader.readAsDataURL(picture.target.files[0]);
      this.editPictureSrc = picture.target.files[0];
    }
  }

  onSave(croppedFile?: any) {
    this.isupload = true;
    this.showSpinner();
    let data: any = this.createUploadForm.value;
    const save_inputs = new FormData();
    save_inputs.append("productId", this.productId);
    save_inputs.append("optimiseFlag", this.checkedAgree.toString());
    // save_inputs.append("file", this.downLoadfile);
    save_inputs.append("file", this.croppedImageToUpload);
    console.log("save_inputs", save_inputs);

    // Object.keys(this.downLoadfile).forEach((key) => {
    //   save_inputs.append("file", this.downLoadfile[key]);
    // });

    // Object.keys(this.pictureSrc).forEach((key) => {
    //   save_inputs.append("file", this.pictureSrc[key]);
    // });
    this.productService.postPicture(save_inputs).subscribe((res) => {
      console.log("res", res);
      this.isupload = false;
      this.croppedImageToUpload = "";
      if (this.path == "edit") {
        this.router.navigate(["/products/product-detail", this.productId]);
      } else {
        this.router.navigate(["/products/create-variant", this.productId]);
      }
    });
  }
  onChecked() {
    this.checkedAgree = !this.checkedAgree;
    console.log("checkedAgree", this.checkedAgree);
  }
  onDeleteClick(picId: any) {
    console.log("picId", picId);
    this.pictureId = picId;
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService
          .deleteProductImg(picId)
          .subscribe((response: any) => {
            console.log("img", response);
            this.showSpinner();

            this.getAllProduct();
            // location.reload();
            Swal.fire("Image Deleted Succesfully!", "", "success");
            // this.onDiscard();
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onUpdate() {
    this.showSpinner();
    const fd = new FormData();
    fd.append("file", this.editPictureSrc);
    fd.append("imgId", this.pictureId);
  }
  onDismiss() {
    return 0;
  }
  onCloseModal() {
    this.editImageForm.reset();
  }

  // get productId() {
  //   return this.selectProductFrom.get("productId");
  // }

  fileChangeEvent(event: any): void {
    this.croppedImage = "";
    this.browseImg = true;
    this.imageChangedEvent = event;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pictureUrl = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.croppedImageToUpload = event.target.files;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    console.log("this.croppedImage", this.croppedImage);

    this.croppedImage2 = this.sanitizer.bypassSecurityTrustUrl(
      event.base64 as string
    );
    console.log("this.croppedImage2", this.croppedImage2);
    const blob = event.blob;
    console.log("blob", blob);

    this.croppedImageToUpload = blob;
    // event.blob can be used to upload the cropped image
    // this.base64toBlob(event.base64 as string);
    // fetch(event.base64 as string)
    //   .then((res) => res.blob())
    //   .then((blob) => {
    //     // You can use the blob here, for example, to upload it

    //     console.log("this.croppedImageToUpload", this.croppedImageToUpload);
    //   });
  }

  base64toBlob(base64Data: string) {
    if (!base64Data) {
      console.error("Base64 data is undefined or null.");
      return;
    }

    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpg" });

    this.croppedImageToUpload = blob;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
