import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import * as Papa from "papaparse";
import { ProductService } from "src/app/_services/product/product.service";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { ShopService } from "src/app/_services/shop/shop.service";
import Swal from "sweetalert2";
@Component({
  selector: "app-product-import-by-csv",
  templateUrl: "./product-import-by-csv.component.html",
  styleUrls: ["./product-import-by-csv.component.scss"],
})
export class ProductImportByCsvComponent implements OnInit {
  importCSV: any;
  shopList: any;
  shop_id: any;
  createImportForm: any;
  userInfo: any;
  newUser: any;
  signedInUser: any;
  userId: any;
  importData: any;
  @ViewChild("myCsv") myCsv!: ElementRef;
  constructor(
    private productService: ProductService,
    private shopService: ShopService,
    private formBuilder: FormBuilder,
    private sessionManager: SessionManagerService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.createImportForm = this.formBuilder.group({
      shopId: ["", [Validators.required]],
    });
    // this.createImportForm.controls["categoryId"].setValue(0);
    this.createImportForm.controls["shopId"].setValue(0);
  }

  ngOnInit(): void {
    this.getAllShops();
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    // console.log("newUser", this.newUser);
    this.signedInUser = this.newUser.groups[0];
    this.userId = this.newUser.userId;
    console.log("signedInUser", this.signedInUser);
    console.log("userId", this.userId);
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];

    if (file) {
      this.parseCSV(file);
    }
  }

  // parseCSV(file: File): void {
  //   Papa.parse(file, {
  //     complete: (result) => {
  //       // result.data will contain parsed CSV data as an array
  //       this.uploadProducts(result.data);
  //     },
  //   });
  // }

  // uploadProducts(data: any[]): void {
  //   // Here you can process the parsed CSV data
  //   // For example, send an HTTP request to your backend to save the products
  //   console.log(data); // Log to see the parsed data
  //   // You can now send `data` to your backend API to process the product upload
  //   // Example HTTP request to your backend service:
  //   // this.http.post('/api/upload/products', data).subscribe(response => {
  //   //   console.log('Upload successful!', response);
  //   // }, error => {
  //   //   console.error('Upload failed:', error);
  //   // });
  // }

  parseCSV(file) {
    Papa.parse(file, {
      complete: (result) => {
        const jsonData = this.convertToJSON(result.data);
        this.importData = jsonData;
        console.log("this.importData", this.importData); // Display JSON data in console or use as needed
      },
      header: true, // Automatically use the first row as header row
      skipEmptyLines: true, // Skip empty lines for cleaner data
    });
  }

  convertToJSON(data: any[]) {
    // return JSON.stringify(data, null, 2); // Convert array of objects to JSON string with indentation
    return data;
  }
  getAllShops() {
    this.shopService.getAllShops().subscribe((response: any) => {
      this.shopList = response;
      console.log("shop", this.shopList);
    });
  }

  onSelectShop() {
    this.shop_id = this.createImportForm.value["shopId"];
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  onAddClick() {
    if (this.createImportForm.value["shopId"] == 0) {
      this.toastr.error("Please fill up the mandatory fields (marked in *)");
      // this.formSubmitted = false;
      return;
    }
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          sellerId: this.userId,
          shopId: +this.shop_id,
          importCsv: this.importData,
        };
        console.log("fd", fd);
        this.spinner.show();
        this.productService.importProducts(fd).subscribe((response: any) => {
          console.log("product", response);
          // this.showSpinner();
          this.spinner.hide();
          Swal.fire("Products are  Created Succesfully!", "", "success");
          // this.router.navigate(["/products/upload-pictures", productId]);
          this.onDiscard();
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  get shopId() {
    return this.createImportForm.get("shopId");
  }

  onDiscard() {
    this.createImportForm.controls["shopId"].setValue(0);
    this.myCsv.nativeElement.value = "";
  }
}
