<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Products Size</h5>
        </div>
        <div class="card-body">
          <div class="btn-popup pull-right">
            <button
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-original-title="test"
              #addSize
              data-target="#exampleModal"
              (click)="open(content)"
            >
              Add Sizes
            </button>
            <ng-template #content let-modal>
              <div class="modal-header">
                <h5 class="modal-title f-w-600" id="exampleModalLabel">
                  Add Sizes
                </h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="onDismiss() && modal.dismiss('Cross click')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form class="needs-validation">
                  <div class="form">
                    <div class="form-group">
                      <label for="validationCustom01" class="mb-1"
                        >Size Name :</label
                      >
                      <input
                        #size
                        *ngIf="!!sizeId"
                        class="form-control"
                        id="validationCustom01"
                        type="text"
                        [value]="this.changedValue"
                        (input)="onSizeChange(size.value)"
                      />
                      <mat-form-field
                        *ngIf="!sizeId"
                        class="example-chip-list"
                        appearance="fill"
                      >
                        <mat-label>Add Size</mat-label>
                        <mat-chip-list #chipList aria-label="Size selection">
                          <mat-chip
                            *ngFor="let size of sizes"
                            (removed)="remove(size)"
                          >
                            {{ size.sizeName }}
                            <button matChipRemove>
                              <mat-icon>X</mat-icon>
                            </button>
                          </mat-chip>
                          <input
                            placeholder="New Sizes..."
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)"
                          />
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  (click)="onSizeSave() && modal.dismiss('Cross click')"
                >
                  Save
                </button>
                <button
                  class="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                  (click)="onDismiss() && modal.dismiss('Cross click')"
                >
                  Close
                </button>
              </div>
            </ng-template>
          </div>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive">
              <!-- <form>
                                <div class="mb-3 row">
                                    <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
                                    <div class="col-xs-3 col-sm-auto">
                                      <input id="table-complete-search" type="text" class="form-control" name="searchTerm"  [(ngModel)]="searchText" autocomplete="off">
                                    </div>
                                </div>
                              </form> -->
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>

                    <th scope="col" sortable="size" (sort)="onSort($event)">
                      Size Name
                    </th>
                    <th
                      scope="col"
                      sortable="population"
                      (sort)="onSort($event)"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let size of allSizes | filter : searchText;
                      let i = index
                    "
                  >
                    <th scope="row">{{ size.sl_no }}</th>
                    <td>{{ size.item?.sizeName }}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        (click)="onSizeEditClick(size.item)"
                        ><i class="fa fa-edit f-12"></i></a
                      >&nbsp;
                      <a
                        href="javascript:void(0)"
                        (click)="onSizeDeleteClick(size.item)"
                        ><i class="fa fa-trash-o"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center p-2">
                <ngb-pagination
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [collectionSize]="allSizesLength"
                  (pageChange)="getAllSizes()"
                >
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
