import { Component, OnInit } from "@angular/core";
import { vendorsDB } from "../../../shared/tables/vendor-list";
import { AppService } from "src/app/_services/appService/app.service";
import { map } from "rxjs";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-list-vendors",
  templateUrl: "./list-vendors.component.html",
  styleUrls: ["./list-vendors.component.scss"],
})
export class ListVendorsComponent implements OnInit {
  public vendors = [];
  vendorListLength: any;
  page: number = 1;
  pageSize: number = 25;
  is_checked: any;
  constructor(
    private appSrvice: AppService,
    private spinner: NgxSpinnerService
  ) {}

  // public settings = {
  //   actions: {
  //     position: 'right'
  //   },
  //   columns: {
  //     vendor: {
  //       title: 'Vendor',
  //       type: 'html',
  //     },
  //     products: {
  //       title: 'Products'
  //     },
  //     store_name: {
  //       title: 'Store Name'
  //     },
  //     date: {
  //       title: 'Date'
  //     },
  //     balance: {
  //       title: 'Wallet Balance',
  //     },
  //     revenue: {
  //       title: 'Revenue',
  //     }
  //   },
  // };

  ngOnInit() {
    this.showSpinner();
    this.getAllSellers();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }

  getAllSellers() {
    this.appSrvice.getAllSellers().subscribe((res: any) => {
      this.vendorListLength = res.length;
      this.vendors = res
        .map((result: any, index: any) => {
          return {
            ...result,
            sl_no: index + 1,
          };
        })
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );

      console.log("resVendor", this.vendors);
    });
  }

  onActiveClick(event: any, id: any, active: any) {
    console.log(id);
    this.is_checked = active;
    console.log("is_Active", active);
    console.log("checked", this.is_checked);

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `SAVE`,
      denyButtonText: `CANCEL`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        if (event.target.checked) {
          this.is_checked = 1;
          console.log("is", this.is_checked);
        } else {
          this.is_checked = 0;
          // console.log('ddd', this.is_checked);
        }
        const save_inputs = {
          is_active: this.is_checked,
        };
        console.log(save_inputs);

        if (id !== 0) {
          this.appSrvice.getSoftDeleteSeller(id).subscribe((response: any) => {
            console.log("tttt", response);
            Swal.fire("Saved!", "", "success");
            this.showSpinner();
            this.getAllSellers();
          });
        }
      } else if (result.isDenied) {
        this.getAllSellers();
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
}
