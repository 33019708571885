import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Image } from "@ks89/angular-modal-gallery";
import { NgbRatingConfig } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductService } from "src/app/_services/product/product.service";
import { NgxSpinnerService } from "ngx-spinner";
// import { until } from "protractor";
import { SessionManagerService } from "src/app/_services/session/session-manager.service";
import { FormBuilder } from "@angular/forms";
import Swal from "sweetalert2";
import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { CategoryServiceService } from "src/app/_services/category/category-service.service";

@Component({
  selector: "app-product-detail",
  templateUrl: "./product-detail.component.html",
  styleUrls: ["./product-detail.component.scss"],
  providers: [NgbRatingConfig],
})
export class ProductDetailComponent implements OnInit {
  public closeResult: string;
  public counter: number = 1;
  currentRate = 8;
  editProductForm: any;
  editStockForm: any;
  editProduct: boolean = false;
  sizeSelected: boolean = false;
  colorSelected: boolean = false;
  updateClick: boolean = false;
  updateStockClick: boolean = false;
  // imagesRect: any;
  public imagesRect: Image[] = [];
  //  [
  //   new Image(
  //     0,
  //     { img: "assets/images/pro3/2.jpg" },
  //     { img: "assets/images/pro3/1.jpg" }
  //   ),
  // new Image(
  //   1,
  //   { img: "assets/images/pro3/27.jpg" },
  //   { img: "assets/images/pro3/27.jpg" }
  // ),
  // new Image(
  //   2,
  //   { img: "assets/images/pro3/1.jpg" },
  //   { img: "assets/images/pro3/1.jpg" }
  // ),
  // new Image(
  //   3,
  //   { img: "assets/images/pro3/2.jpg" },
  //   { img: "assets/images/pro3/2.jpg" }
  // ),
  // ];

  colorArray = [
    { id: 1, color: "#964B00", color_name: "Brown" },
    { id: 2, color: "#00FF00", color_name: "Green" },
    { id: 3, color: "#faf0be", color_name: "Hex Blond" },
    { id: 4, color: "#FF033E", color_name: "American Rose" },
    { id: 5, color: "#B06500", color_name: "ginger color" },
    { id: 6, color: "#d5db21", color_name: "Yellowish Green" },
    { id: 7, color: "#C4A484", color_name: "Light Brown" },
    { id: 8, color: "#FFFF00", color_name: "yellow" },
    { id: 9, color: "#E5E7EB", color_name: "White" },
    { id: 10, color: "#F0EDE5", color_name: "milk color" },
    { id: 11, color: "#8B0000", color_name: "Dark Red" },
    { id: 12, color: "#FFD700", color_name: "golden Color" },
    { id: 13, color: "#EAC117", color_name: "Golden Brown" },
    { id: 14, color: "#6F4E37", color_name: "coffe color" },
    { id: 15, color: "#808000", color_name: "Olive" },
    { id: 16, color: "#FF0000", color_name: "RED" },
    { id: 17, color: "#B7A6A6", color_name: "Brown" },
    { id: 18, color: "#000000", color_name: "Black" },
  ];
  productId: any;
  productDetails: any;
  variant: any;
  size: string;
  day: any;
  selectedSize: any;
  selectedSizeIndex = -1;
  selectedColor: any;
  selectedColorIndex = -1;
  salePrice: any;
  price: any;
  userInfo: any;
  newUser: any;
  signedInUser: any;
  userId: any;
  @ViewChild("onEditClick") onEditClick!: ElementRef;
  @ViewChild("col") col!: ElementRef;
  @ViewChild("clickSize") clickSize!: ElementRef;
  untilDate: any;
  selectedColorCode: any;
  varientId: any;
  componentId: any;
  discount: any;
  offer: any;
  stock: any;
  selectedColorName: any = "White";
  // selectColorId: any;
  colorCodeSelected: string = "#E5E7EB";
  colorName: any;
  isStockEdit: boolean = false;
  current_stock: any;
  change_in_stock: any;
  categoryList: any;
  catId: any;
  subCategoryList: any;
  constructor(
    private modalService: NgbModal,
    config: NgbRatingConfig,
    private route: ActivatedRoute,
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    private sessionManager: SessionManagerService,
    private formBuilder: FormBuilder,
    private location: Location,
    private toastr: ToastrService,
    private categoryService: CategoryServiceService,
    private router: Router
  ) {
    config.max = 5;
    config.readonly = false;

    this.editProductForm = this.formBuilder.group({
      name: [""],
      // slug: ["", [Validators.required]],
      // brand: [""],
      // shopId: [""],
      // shopCategoryId: ["", [Validators.required]],
      // categoryId: ["", [Validators.required]],
      // subCategoryId: ["", [Validators.required]],
      shortDesc: [""],
      // sku: ["", [Validators.required]],
      // stockTotal: [""],
      price: [""],
      description: [""],
      additionalInfo: [""],
      shippingReturn: [""],
      // colorCode: [""],
      colorName: [""],
      // until: [""],
      discount: [""],
      categoryId: [""],
      subCategoryId: [""],
      // offer: [""],
    });

    this.editStockForm = this.formBuilder.group({
      newStock: [""],
      addStock: [""],
      subStock: [""],
    });
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((param: any) => {
      this.productId = param.get("id");
      console.log("productId", this.productId);
    });
    this.getProductDetails();
    this.getAllCategories();
  }
  getProductDetails() {
    this.userInfo = this.sessionManager.getUserInfo();
    // console.log("userInfo", JSON.parse(this.userInfo));
    this.newUser = JSON.parse(this.userInfo);
    console.log("newUser", this.newUser.userName);
    this.userId = this.newUser.userId;
    console.log("userId", this.userId);
    this.getProductDetailsById();
    this.showSpinner();
  }
  showSpinner() {
    /** spinner starts on init */
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000);
  }
  onSelectColor() {
    this.colorName = this.editProductForm.value["colorName"];
    // console.log("this.selectShopId", this.selectShopId);
    let color = this.colorArray.filter((res: any) => {
      // console.log("res", res);

      return res.color_name == this.colorName;
    });
    console.log("color", color);
    this.selectedColorName = color[0].color_name;
    this.colorCodeSelected = color[0].color;
    console.log(
      "selectedColor",
      this.selectedColorName,
      this.colorCodeSelected
    );
  }
  getProductDetailsById() {
    this.productService
      .getProductById(this.productId, this.userId)
      .subscribe((response: any) => {
        this.productDetails = null;
        this.imagesRect = [];
        let pictures: any = response?.product?.pictures;
        pictures = pictures?.forEach((pic, index) => {
          let imageObj: Image = new Image(
            index,
            { img: pic.url },
            { img: response?.product?.smPictures[index]?.url }
          );
          this.imagesRect.push(imageObj);
        });
        this.productDetails = response;
        console.log(">>>", this.imagesRect);

        this.variant = response?.product.variants;
        // if (this.variant.length == 1) {
        //   // this.onColorClick();
        // }
        setTimeout(() => {
          // this.col.nativeElement.click();
          this.onColorClick(this.variant[0], 0);
        }, 10);

        this.variant.forEach((res: any) => {
          this.size = res.size.map((res) => {
            let d1: any = new Date();
            console.log("d1", d1);
            let d2: any = new Date(res.until);
            console.log("d2", d2);
            let diffTime = Math.abs(d1 - d2);
            const diffDays = this.dhm(diffTime);
            console.log("timeLeft", diffDays);
            return {
              ...res,
              timeLeft: diffDays,
            };
          });
          // if (this.size.length == 1) {
          //  this.clickSize.nativeElement.click();

          // }

          if (this.selectedSize && this.selectedSizeIndex >= 0) {
            this.selectedSize = this.size[this.selectedSizeIndex];
            this.onSizeClick(this.selectedSize, this.selectedSizeIndex);
          } else this.selectedSize = null;
        });

        // this.imagesRect = response?.product?.pictures?.url;
        console.log("this.productDetails", this.productDetails);
        console.log("variants", response?.product.variants);
        console.log("size", this.size);
      });
  }

  onSizeClick(size: any, index: any) {
    this.sizeSelected = true;
    this.selectedSize = size;
    this.selectedSizeIndex = index;
    console.log("selectedSize", this.colorSelected, size);
    if (this.colorSelected && this.selectedSize) {
      this.salePrice = this.selectedSize?.salePrice;
      this.price = this.selectedSize?.price;
      this.componentId = this.selectedSize?.pivot?.componentId;
      this.discount = this.selectedSize?.discount;
      this.offer = this.selectedSize?.offer;
      this.untilDate = this.selectedSize?.until;
      this.stock = this.selectedSize?.pivot?.stock;
    }
  }
  onColorClick(variant?: any, index?: any) {
    this.colorSelected = true;
    this.selectedColor = variant?.colorName;
    this.varientId = variant?.id;
    this.selectedColorIndex = index;
    console.log("selectedColor", this.selectedColor);
    this.selectedColorCode = variant?.color;
    this.colorCodeSelected = variant?.color;
    this.selectedColorName = variant?.colorName;
    //  this.price = size.price;
    this.size = variant?.size;
    this.onSizeClick(this.size[0], 0);

    // this.onSizeClick(this.selectedSize, this.selectedSizeIndex);
  }

  dhm(t: any) {
    var cd = 24 * 60 * 60 * 1000,
      ch = 60 * 60 * 1000,
      d = Math.floor(t / cd),
      h = Math.floor((t - d * cd) / ch),
      m = Math.round((t - d * cd - h * ch) / 60000),
      pad = function (n) {
        return n < 10 ? "0" + n : n;
      };
    if (m === 60) {
      h++;
      m = 0;
    }
    if (h === 24) {
      d++;
      h = 0;
    }
    let date = { day: d, hour: pad(h), minute: pad(m) };
    return date;
  }
  onEditButtonClick() {
    if (this.productDetails?.product?.categoryId) {
      this.catId = this.productDetails?.product?.categoryId?.toString();
      this.categoryService
        .getSubCategoryByCategoryId(this.catId)
        .subscribe((res: any) => {
          this.subCategoryList = res;
          console.log("subCategoryList", this.subCategoryList);
          this.editProductForm.patchValue({
            subCategoryId: this.productDetails?.product?.subcategoryId,
          });
          this.editProductForm.controls["subCategoryId"].setValue(
            this.productDetails?.product?.subcategoryId?.toString()
          );
        });
    }

    console.log("edit", this.selectedColor);
    console.log(
      "this.productDetails?.product?.categoryId,",
      this.productDetails?.product?.categoryId?.toString()
    );

    this.onEditClick.nativeElement.click();
    //  this.editAddressButton = true;
    window.scrollTo(0, 0);
    this.editProduct = true;
    this.editProductForm.patchValue({
      name: this.productDetails?.product?.name,
      shortDesc: this.productDetails?.product?.shortdesc,
      // stockTotal: this.stock,
      price: this.price,
      description: this.productDetails?.product?.description,
      additionalInfo: this.productDetails?.product?.additionalInfo,
      shippingReturn: this.productDetails?.product?.shippingReturn
        ? this.productDetails?.product?.shippingReturn
        : "N/A",
      // colorCode: this.selectedColorCode,
      colorName: this.selectedColor,
      // until: this.untilDate,
      discount: this.discount,
      // offer: this.offer,
      // subcategoryId
      categoryId: this.productDetails?.product?.categoryId?.toString(),
      subCategoryId: this.productDetails?.product?.subcategoryId?.toString(),
    });
    // this.editProductForm.controls["categoryId"].setValue(
    //   this.productDetails?.product?.category[0]?.id.toString() || "0"
    // );
    // this.editProductForm.controls["subCategoryId"].setValue(
    //   this.productDetails?.product?.subcategoryId.toString() || "0"
    // );
  }
  updateProduct() {
    this.updateClick = true;

    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          productId: this.productId,
          name: this.editProductForm.value["name"],
          shortDesc: this.editProductForm.value["shortDesc"],
          // stockTotal: this.editProductForm.value["stockTotal"],
          description: this.editProductForm.value["description"],
          additionalInfo: this.editProductForm.value["additionalInfo"],
          shippingReturn: this.editProductForm.value["shippingReturn"],
          categoryId: this.editProductForm.value["categoryId"],
          subCategoryId:
            this.editProductForm.value["subCategoryId"] ||
            this.productDetails?.product?.subcategoryId.toString(),
          // slug: this.addProductForm.value["slug"],
        };

        let fdComp = {
          componentId: this.componentId,
          // variantName: this.editProductForm.value["colorName"],
          variantName: this.selectedColorName,
          variantCode: this.colorCodeSelected,
          // variantCode: this.editProductForm.value["colorCode"],
          variantId: this.varientId,
        };

        let fdsale = {
          componentId: this.componentId,
          // stock: this.editProductForm.value["stockTotal"],
          // until: this.editProductForm.value["until"],
          price: this.editProductForm.value["price"],
          discount: this.editProductForm.value["discount"],
          // offer: this.editProductForm.value["offer"],
        };
        // let updateProduct = { product: fd, component: {}, pictures: {} };
        this.productService.updateProduct(fd).subscribe((response: any) => {
          console.log("updateproduct", response);

          // this.onDiscard();
        });
        this.productService.updateComponent(fdComp).subscribe((res: any) => {
          console.log("updateComp", res);
        });
        this.productService.updateCSale(fdsale).subscribe((resp: any) => {
          console.log("updatesale", resp);
          this.getProductDetailsById();
          this.showSpinner();
          this.updateClick = false;
          this.location.historyGo();
          this.onEditClick.nativeElement.click();
          // this.onDiscard();
          this.editProductForm.reset();
          Swal.fire("Product Updated Succesfully!", "", "success");
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  onEditStockClick() {
    this.isStockEdit = true;
  }
  onClickNewStock(event: any) {
    console.log("event", event.target.value);

    if (event.target.value.length > 0) {
      this.editStockForm.get("addStock").disable();
      this.editStockForm.get("subStock").disable();
    } else {
      this.editStockForm.get("addStock").enable();
      this.editStockForm.get("subStock").enable();
    }
  }
  onClickAddStock(event: any) {
    if (event.target.value.length > 0) {
      this.editStockForm.get("newStock").disable();
      this.editStockForm.get("subStock").disable();
    } else {
      this.editStockForm.get("newStock").enable();
      this.editStockForm.get("subStock").enable();
    }
  }

  onClickSubStock(event: any) {
    if (event.target.value.length > 0) {
      this.editStockForm.get("newStock").disable();
      this.editStockForm.get("addStock").disable();
    } else {
      this.editStockForm.get("newStock").enable();
      this.editStockForm.get("addStock").enable();
    }
  }
  updateStock() {
    this.updateStockClick = true;
    if (this.editStockForm.value["newStock"]?.length > 0) {
      this.current_stock = this.editStockForm.value["newStock"];
      this.change_in_stock = +this.current_stock - +this.stock;
      console.log(" this.current_stock", this.current_stock);
      console.log(" this.change_in_stock", this.change_in_stock);
    } else if (this.editStockForm.value["addStock"]?.length > 0) {
      this.change_in_stock = this.editStockForm.value["addStock"];
      this.current_stock = +this.stock + +this.change_in_stock;
      console.log(" this.change_in_stock", this.change_in_stock);
    } else if (this.editStockForm.value["subStock"]?.length > 0) {
      this.change_in_stock = this.editStockForm.value["subStock"];
      this.current_stock = +this.stock - +this.change_in_stock;
      console.log(" this.change_in_stock", this.change_in_stock);
    }
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let fd = {
          productId: this.productId,
          componentId: this.componentId,
          variantId: this.varientId,
          previous_stock: this.stock,
          currentStock: this.current_stock.toString(),
          changeInStock: this.change_in_stock.toString(),

          //         {
          //   "productId": 0,
          //   "componentId": 0,
          //   "variantId": 0,
          //   "sizeId": 0,
          //   "productName": "string",
          //   "previous_stock": 0,
          //   "currentStock": 0,
          //   "changeInStock": 0,
          //   "remarks": "string"
          // }
        };
        console.log("fd", fd);

        // return;
        this.productService.createInventory(fd).subscribe(
          (response: any) => {
            console.log("stock", response);
            // this.getProductDetailsById();
            this.getProductDetails();
            setTimeout(() => {}, 0);
            this.showSpinner();
            this.updateStockClick = false;
            this.onCancel();
            // this.location.historyGo();
            Swal.fire("Stock Updated Succesfully!", "", "success");
            // this.router.navigate(["/products/create-sale"]);
            // this.onDiscard();
          },
          (error) => {
            console.log("error", error);
            this.toastr.error("Something Went Wrong,Please Try Again");
          }
        );
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  onDiscard() {
    this.sizeSelected = false;
    this.colorSelected = false;
    // this.selectedSizeIndex = -1;
    // this.selectedColorIndex = -1;
    this.onEditClick.nativeElement.click();
    this.editProduct = false;
    this.updateClick = false;
    this.editProductForm.reset();
    this.getProductDetailsById();
  }

  onCancel() {
    this.isStockEdit = false;
    this.updateStockClick = false;
    this.editStockForm.reset();
    this.change_in_stock = 0;
    this.current_stock = 0;
    this.editStockForm.get("newStock").enable();
    this.editStockForm.get("addStock").enable();
    this.editStockForm.get("subStock").enable();
    this.getProductDetailsById();
  }

  getAllCategories() {
    this.categoryService.getAllCategory().subscribe((res: any) => {
      this.categoryList = res.categoryList;
      console.log("this.categories", this.categoryList);
    });
  }

  onSelectCategory() {
    this.catId = this.editProductForm.value["categoryId"];
    this.categoryService
      .getSubCategoryByCategoryId(this.catId)
      .subscribe((res: any) => {
        this.subCategoryList = res;
        console.log("subCategoryList", this.subCategoryList);
      });
  }

  onClickAddImage() {
    this.router.navigate([
      "/products/upload-pictures",
      { id: this.productId, path: "edit" },
    ]);
  }
}
