<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <h5>Products Sub Category</h5>
        </div>
        <div class="card-body">
          <div class="btn-popup pull-right">
            <button
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-original-title="test"
              #addSubCategory
              data-target="#exampleModal"
              (click)="open(content)"
            >
              Add Sub Category
            </button>
            <ng-template #content let-modal>
              <div class="modal-header">
                <h5 class="modal-title f-w-600" id="exampleModalLabel">
                  Add Physical Product
                </h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="onDismiss() && modal.dismiss('Cross click')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form
                  class="needs-validation"
                  [formGroup]="createSubCategoryForm"
                >
                  <div class="form">
                    <div *ngIf="!subCategoryId" class="form-group">
                      <label for="validationCustom01" class="mb-1"
                        >Select Category :</label
                      >

                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="categoryId"
                        (change)="onSelectCategory()"
                      >
                        <option [ngValue]="0">Select Category</option>
                        <option *ngFor="let cat of categories" [value]="cat.id">
                          {{ cat.category }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group mb-0">
                      <label for="validationCustom01" class="mb-1"
                        >Sub Category Name :</label
                      >
                      <input
                        #subcat
                        *ngIf="!!subCategoryId"
                        class="form-control"
                        id="validationCustom01"
                        type="text"
                        [value]="this.changedValue"
                        (input)="onSubCatagorieChange(subcat.value)"
                      />
                      <mat-form-field
                        *ngIf="!subCategoryId"
                        class="example-chip-list"
                        appearance="fill"
                      >
                        <mat-label>Add category</mat-label>
                        <mat-chip-list
                          #chipList
                          aria-label="Sub_Category selection"
                        >
                          <mat-chip
                            *ngFor="let sub_cat of sub_category"
                            (removed)="remove(sub_cat)"
                          >
                            {{ sub_cat.name }}
                            <button matChipRemove>
                              <mat-icon>X</mat-icon>
                            </button>
                          </mat-chip>
                          <input
                            placeholder="New SubCategory..."
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)"
                          />
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                    <!-- <div class="form-group mb-0">
                      <label for="validationCustom02" class="mb-1"
                        >Sub Category Image :</label
                      >
                      <input
                        class="form-control"
                        id="validationCustom02"
                        type="file"
                      />
                    </div> -->
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="
                    onSubCategoriesSave() && modal.dismiss('Cross click')
                  "
                >
                  Save
                </button>
                <button
                  class="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                  (click)="onDismiss() && modal.dismiss('Cross click')"
                >
                  Close
                </button>
              </div>
            </ng-template>
          </div>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive">
              <!-- <form>
                                <div class="mb-3 row">
                                    <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
                                    <div class="col-xs-3 col-sm-auto">
                                      <input id="table-complete-search" type="text" class="form-control" name="searchTerm"  [(ngModel)]="searchText" autocomplete="off">
                                    </div>
                                </div>
                              </form> -->
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>

                    <th scope="col" sortable="category" (sort)="onSort($event)">
                      SubCategory Name
                    </th>
                    <th scope="col" sortable="category" (sort)="onSort($event)">
                      Category Name
                    </th>
                    <th
                      scope="col"
                      sortable="population"
                      (sort)="onSort($event)"
                    >
                      Actions
                    </th>
                    <th
                      scope="col"
                      sortable="population"
                      (sort)="onSort($event)"
                    >
                      Active/Inactive
                    </th>
                    <th
                      scope="col"
                      sortable="population"
                      (sort)="onSort($event)"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let subCategory of subCategories | filter : searchText;
                      let i = index
                    "
                  >
                    <th scope="row">{{ subCategory.sl_no }}</th>
                    <td>{{ subCategory.subcategory.name }}</td>
                    <td>{{ subCategory.category }}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        (click)="
                          onSubCategoryEditClick(subCategory.subcategory)
                        "
                        ><i class="fa fa-edit f-12"></i></a
                      >&nbsp;
                      <a
                        href="javascript:void(0)"
                        (click)="
                          onSubCategoryDeleteClick(subCategory.subcategory)
                        "
                        ><i class="fa fa-trash-o"></i
                      ></a>
                    </td>
                    <td>
                      <label
                        class="switch"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="{{
                          subCategory.subcategory?.active == true
                            ? 'Active'
                            : 'Inactive'
                        }}"
                      >
                        <input
                          type="checkbox"
                          [checked]="subCategory.subcategory?.active == true"
                          (click)="
                            onActiveClick(
                              $event,
                              subCategory.subcategory?.id,
                              subCategory.subcategory?.active
                            )
                          "
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td>
                      <span
                        [class]="
                          subCategory.subcategory?.active == true
                            ? 'badge bg-success text-dark'
                            : 'badge bg-secondary text-dark'
                        "
                      >
                        {{
                          subCategory.subcategory?.active == true
                            ? "Active"
                            : "Inactive"
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="d-flex justify-content-center p-2">
                <ngb-pagination
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [collectionSize]="subCategoriesLength"
                  (pageChange)="allSubCategories()"
                >
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
