<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <div class="card-header">
          <div class="row">
            <div class="col-md-6 col-xl-6">
              <h5>Shopify Connect</h5>
            </div>
            <div class="col-md-6 col-xl-6">
              <div class="btn-popup pull-right"></div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-7">
              <form
                class="needs-validation"
                [formGroup]="createShopifyConnectForm"
              >
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom02" class="mb-0"
                        >Select Shop <sup class="star">*</sup> :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="shopId"
                        (change)="onSelectShop()"
                      >
                        <option [ngValue]="0">Select Shop</option>
                        <option
                          *ngFor="let shop of shopList"
                          [value]="shop.shopId"
                        >
                          {{ shop.shopName }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          shopId &&
                          !shopId.value &&
                          (shopId.dirty || shopId.touched)
                        "
                        class="text text-danger"
                      >
                        Please Select a Shop.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom06" class="mb-0"
                        >URL <sup class="star">*</sup> :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom06"
                        type="text"
                        placeholder="Add url here"
                        formControlName="url"
                      />
                      <!-- <div
                      *ngIf="
                        price && price.invalid && (price.dirty || price.touched)
                      "
                      class="text text-danger"
                    >
                      Price is required.
                    </div> -->
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom06" class="mb-0"
                        >Access Token <sup class="star">*</sup> :</label
                      >
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input
                        class="form-control"
                        id="validationCustom06"
                        type="text"
                        placeholder="Add Access Token here"
                        formControlName="token"
                      />
                      <div class="text-success">
                        <a
                          href="https://docs.google.com/document/d/1kTivexG8rJwXVzmXpbm_IfiosZFI57Jiw0ugKA-YVzs/edit"
                          target="_blank"
                          >Getting the API Access Token from Your Shopify
                          Store</a
                        >
                      </div>
                      <!-- <div
                      *ngIf="
                        price && price.invalid && (price.dirty || price.touched)
                      "
                      class="text text-danger"
                    >
                      Price is required.
                    </div> -->
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-sm-8 offset-xl-3 offset-sm-4">
                    <div class="product-buttons">
                      <button
                        type="submit"
                        class="btn btn-primary me-1"
                        (click)="testConnection()"
                      >
                        Test Connection
                      </button>
                      <button
                        type="submit"
                        (click)="createShopConnect()"
                        class="btn btn-success me-1"
                        [disabled]="!shopId.value"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        (click)="onDiscard()"
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5>Shopify Connection Details</h5>
        </div>
        <div class="card-body connections-table text-center">
          <p *ngIf="!allConnections?.length">
            No Vendor has been registered yet.
          </p>
          <div id="batchDelete" class="custom-datatable connections-list">
            <div class="table-responsive">
              <table
                *ngIf="allConnections?.length > 0"
                class="table table-striped"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Shop Name</th>
                    <th scope="col">Shopify Url</th>
                    <th scope="col">Shopify AccessToken</th>
                    <!-- <th scope="col">Active/Inactive</th> -->
                    <th scope="col">Action</th>
                    <!-- <th scope="col"  >Wallet Balance</th>
                                    <th scope="col" >Revenue</th>
                                    <th scope="col" >Actions</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let connections of allConnections; let i = index">
                    <td scope="row">
                      <!-- <img [src]="item.connections" class="imgTable" style="width: 20px">
                                       <span>{{item.name}}</span>  -->
                      {{ i + 1 }}
                    </td>
                    <td>
                      {{ connections?.shopName || "N/A" }}
                    </td>
                    <td>{{ connections?.shopifyUrl || "N/A" }}</td>
                    <td>
                      {{ connections?.shopifyAccessToken || "N/A" }}
                    </td>

                    <td *ngIf="connections?.shopifyUrl">
                      <button
                        *ngIf="!connections?.isSyncing"
                        type="submit"
                        class="btn btn-primary me-1"
                        (click)="productSync(connections)"
                      >
                        Product Sync
                      </button>
                      <button
                        *ngIf="connections?.isSyncing"
                        class="btn btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Syncing...
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center p-2">
                <!-- <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="connectionsListLength"
              (pageChange)="getAllSellers()"
            >
            </ngb-pagination> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
