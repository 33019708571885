<div class="body">
  <div class="login_form">
    <div class="login-wrapper">
      <form
        id="login"
        method="post"
        action="#"
        [formGroup]="forgetPasswordForm"
        (ngSubmit)="onPasswordReset()"
      >
        <div class="logo">
          <a target="_blank" routerLink="/">
            <img src="assets/images/dashboard/TE13.svg" alt=""
          /></a>
        </div>

        <label for="username">New Password*</label>
        <input required type="text" formControlName="password" /><span
          class="text-danger"
          *ngIf="
            password && password.invalid && (password.dirty || password.touched)
          "
          ><p>This field is mandatory.</p></span
        >

        <label for="password">Confirm Password*</label>
        <input
          class="password"
          required
          type="password"
          formControlName="confirmPassword"
        /><span
          class="text-danger"
          *ngIf="
            confirmPassword &&
            confirmPassword.errors?.MatchPassword &&
            (confirmPassword.dirty || confirmPassword.touched)
          "
        >
          New and Confirm Passwords do not match</span
        >
        <!-- <div class="hide-show">
          <span>Show</span>
        </div> -->
        <button
          class="btn btn-primary"
          [disabled]="forgetPasswordForm.invalid"
          type="submit"
        >
          <span>RESET Password</span>
        </button>
      </form>
    </div>
  </div>
</div>
